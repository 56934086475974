import { FC, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";

import { AuthAPI } from "api";

import { ICONS } from "assets";
import avatar from "./avatar.png";

import { useOutsideClick, useUser } from "hooks";
import { toggleLoading } from "store/slices/app";
import { TokenService } from "utils";
import { AdminRole } from "enums";

const ProfileBlock: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, updateUser } = useUser();

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const divRef = useRef(null);
  useOutsideClick({
    ref: divRef,
    onClickOutside: () => setIsOpenModal(false)
  });

  const logout = async () => {
    try {
      dispatch(toggleLoading(true));
      await AuthAPI.logout();
      TokenService.clearTokens();
      updateUser(null);
      navigate("/login");
    } catch (e) {
      alert("Error");
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  return (
    <div
      ref={divRef}
      className={styles.profile}
      onClick={() => setIsOpenModal(!isOpenModal)}>
      <div className={styles.profile_avatar}>
        <img src={avatar} alt="Avatar" />
      </div>
      <div className={styles.profile_info}>
        <p className={styles.profile_info_name}>
          {user?.firstName} {user?.lastName}
        </p>
        <p className={styles.profile_info_role}>
          {user?.role === AdminRole.Admin ? "Admin" : "Marketing manager"}
        </p>
      </div>
      <div className={styles.profile_arrows}>
        <ICONS.Arrows />
      </div>

      {isOpenModal && (
        <div className={styles.modal}>
          <div className={styles.modal_wrapper}>
            <div className={styles.modal_wrapper_item} onClick={logout}>
              <div className={styles.modal_wrapper_item_icon}>
                <ICONS.Logout />
              </div>
              <p>Logout</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileBlock;
