import { FC, useMemo, useState } from "react";
import moment, { Moment } from "moment";
import cls from "classnames";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

type Props = {
  value: Date | null;
  disableDateBefore?: Date;
  onChange: (value: Date) => void;
};

const DaysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

const Calendar: FC<Props> = ({ value, disableDateBefore, onChange }) => {
  const [currentDate, setCurrentDate] = useState<Moment>(
    value ? moment(value) : moment()
  );

  const daysOfMonth = useMemo(() => {
    const daysInMonth = currentDate.daysInMonth();

    const daysArray = [];

    const firstDayOfMonth = currentDate.startOf("month");

    for (let i = 0; i < daysInMonth; i++) {
      const currentDay = firstDayOfMonth.clone().add(i, "days");
      daysArray.push(currentDay);
    }

    return daysArray;
  }, [currentDate]);

  return (
    <div className={styles.wrapper}>
      <header className={styles.wrapper_header}>
        <div
          className={styles.wrapper_header_arrow}
          onClick={() =>
            setCurrentDate(moment(currentDate).subtract(1, "month"))
          }
        >
          <ICONS.SmallLeftArrow />
        </div>
        <p className={styles.wrapper_header_title}>
          {moment(currentDate).format("MMMM YYYY")}
        </p>
        <div
          className={cls(
            styles.wrapper_header_arrow,
            styles.wrapper_header_arrow_rotate
          )}
          onClick={() => setCurrentDate(moment(currentDate).add(1, "month"))}
        >
          <ICONS.SmallLeftArrow />
        </div>
      </header>

      <div className={styles.wrapper_days}>
        {DaysOfWeek.map((d, index) => (
          <p key={index} className={styles.wrapper_days_item}>
            {d}
          </p>
        ))}
      </div>

      <div className={styles.wrapper_month}>
        {daysOfMonth.map((day, index) => {
          const isDisable =
            disableDateBefore && day.isBefore(disableDateBefore, "day");

          return (
            <div
              key={index}
              className={styles.wrapper_month_item}
              style={{
                marginLeft: index === 0 ? `${day.day() * 14.28}%` : 0
              }}
            >
              <div
                className={cls(styles.wrapper_month_item_wrapper, {
                  [styles.wrapper_month_item_wrapper_active]: day.isSame(value),
                  [styles.wrapper_month_item_wrapper_disable]: isDisable
                })}
                onClick={() => {
                  if (!isDisable) onChange(day.toDate());
                }}
              >
                {day.format("DD")}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Calendar;
