import { ReactComponent as Audio } from "./audio.svg";
import { ReactComponent as Articles } from "./articles.svg";
import { ReactComponent as Banners } from "./banners.svg";
import { ReactComponent as Users } from "./users.svg";
import { ReactComponent as Settings } from "./settings.svg";
import { ReactComponent as Staff } from "./staff.svg";

const nav = {
  Audio,
  Articles,
  Banners,
  Users,
  Settings,
  Staff
};

export default nav;
