import React, { ComponentType } from "react";
import { Navigate } from "react-router-dom";

import { useUser } from "hooks";

import { AdminRole } from "enums";

interface Props {
  role: string;
}

const withRoleAuthorization =
  (allowedRoles: Array<AdminRole>) =>
  (WrappedComponent: ComponentType<any>): React.FC<any> => {
    const WithRoleAuthorization: React.FC<Props> = (props) => {
      const { user } = useUser();

      if (!user) {
        return <Navigate to="/login" />;
      }

      if (allowedRoles.includes(user.role)) {
        return <WrappedComponent {...props} />;
      }

      if (user.role === AdminRole.Admin) {
        return <Navigate to="/audio" />;
      } else {
        return <Navigate to="/banners" />;
      }
    };

    return WithRoleAuthorization;
  };

export default withRoleAuthorization;
