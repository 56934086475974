import { FC } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

type Props = {
  status: boolean;
};

const Status: FC<Props> = ({ status }) => (
  <div
    className={cls(styles.wrapper, {
      [styles.wrapper_declined]: !status
    })}
  >
    {status ? <ICONS.Checked /> : <ICONS.Cross />}
  </div>
);

export default Status;
