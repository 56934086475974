import client from "./client";

class UserAPI {
  static getUsers(page: number, take: number, filter: any) {
    return client
      .post(`user/all?page=${page}&take=${take}`, filter)
      .then(({ data }) => data);
  }

  static getUser(id: number) {
    return client.get(`user/${id}`).then(({ data }) => data);
  }

  static createUser(firstName: string, email: string) {
    return client
      .post(`user/create`, { firstName, email })
      .then(({ data }) => data);
  }

  static deleteUser(id: number) {
    return client.delete(`user/${id}`).then(({ data }) => data);
  }

  static blockUnblockUser(id: number, block: boolean) {
    return client
      .patch(`user/${id}/${block ? "unblock" : "block"}`)
      .then(({ data }) => data);
  }

  static editUser(id: number, data: { email: string; firstName: string }) {
    return client.patch(`user/edit/${id}`, data).then(({ data }) => data);
  }

  static anonimyzeUser(id: number) {
    return client.patch(`user/anonymize/${id}`).then(({ data }) => data);
  }

  static addFreeAcces(userId: number, endDate: Date) {
    return client
      .put(`subscription/upsert/byAdmin`, {
        userId,
        endDate
      })
      .then(({ data }) => data);
  }

  static getUserExport() {
    return client
      .get(`user/export`, {
        responseType: "blob"
      })
      .then((resp) => {
        return resp.data;
      });
  }
}

export default UserAPI;
