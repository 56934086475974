import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import cls from "classnames";
import styles from "./styles.module.scss";

import { ArticleAPI, FileAPI } from "api";

import { ICONS } from "assets";

import {
  LogsHeader,
  Button,
  Switcher,
  EmptyListTitle,
  ConfirmModal,
  AddArticleModal,
  Tabs,
  LogsTable,
  Status,
  SearchInput
} from "components";
import Filt from "./Filter";

import { toggleLoading } from "store/slices/app";
import { ArticleService, AudioService } from "utils";
import { usePagination, useTab } from "hooks";
import { withRoleAuthorization } from "hoc";

import { AdminRole, OrderBy, VariantButton } from "enums";
import { IArticle, IFileSession } from "interfaces";
import { toast } from "react-toastify";

const LIMIT = 10;

const ArticleLogs: FC = () => {
  const { currentTab, onChange: onChangeTab } = useTab();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [article, setArticle] = useState<null | IArticle>(null);
  const [isOpenDeleteArticleModal, setIsOpenDeleteArticleModal] =
    useState<boolean>(false);
  const [isOpenAddArticleModal, setIsOpenAddArticleModal] = useState<
    any | false
  >(false);

  const fetchData = async (page: number, take: number, filter: any) => {
    const resp = await FileAPI.getFileSession(
      "article",
      Number(id),
      page,
      take,
      filter
    );
    return resp;
  };

  const {
    isLoading: articleLoading,
    data,
    page,
    filter,
    onChangeFilter,
    onChangeSearch,
    onChangePage
  } = usePagination<
    {
      data: Array<IFileSession>;
      meta: { itemsCount: number };
    },
    {
      sorting: {
        sortBy: any | null;
        order: OrderBy | null;
      } | null;
      search: string | null;
      dateRange: {
        from: Date | null;
        to: Date | null;
      } | null;
    }
  >(fetchData, LIMIT, ["tab"]);

  const fetchCount = async (page: number, take: number, filter: any) => {
    const resp = await FileAPI.getFileSession(
      "article",
      Number(id),
      page,
      take,
      filter
    );
    return resp;
  };

  const { isLoading: countLoading, data: countData } = usePagination<{
    data: Array<IFileSession>;
    meta: { itemsCount: number };
  }>(fetchCount, LIMIT, ["tab"]);

  useEffect(() => {
    fetchArticle();
  }, []);

  useEffect(() => {
    onChangeFilter({ sorting: null, dateRange: null, search: null });
  }, [currentTab]);

  const fetchArticle = async () => {
    try {
      setIsLoading(true);
      const { article } = await ArticleAPI.getArticle(Number(id));

      setArticle(article);
    } catch (e) {
      alert("Error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteArticle = async () => {
    try {
      if (!article) return;
      dispatch(toggleLoading(true));

      await ArticleAPI.deleteArticle(article.id);
      navigate("/articles");
    } catch (e) {
      alert("Error");
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const toggleDeactivated = async (newStatus: boolean) => {
    try {
      if (!article) return;
      ArticleAPI.editArticle({ status: newStatus }, article.id);
      setArticle({
        ...article,
        status: newStatus
      });
    } catch (e) {
      alert("Error");
    }
  };

  const handleUpdateArticle = async (values: any) => {
    try {
      dispatch(toggleLoading(true));

      const { id, ...other } = values;
      const { article: updatedArticle } = await ArticleAPI.editArticle(
        other,
        id
      );

      setArticle(updatedArticle);
      setIsOpenAddArticleModal(false);
    } catch (e) {
      alert("Error");
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const exportTable = async () => {
    try {
      dispatch(toggleLoading(true));
      if (!article) return;

      const response = await FileAPI.getLogsExport("article", article?.id);
      const blob = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `Article_${article.id}_Logs_${moment().format(
        "MM-DD-YYYY"
      )}.xlsx`;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (e: any) {
      toast(e.response.data.message, {
        type: "error"
      });
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const renderTab = () => {
    if (!article) return null;

    switch (currentTab) {
      case 0:
        return (
          <div
            className={cls(styles.wrapper_text, "ql-snow", "ql-editor")}
            dangerouslySetInnerHTML={{
              __html: article?.text
            }}
          />
        );
      default:
        return (
          <div>
            <header className={styles.wrapper_table_header}>
              <div className={styles.wrapper_table_header_search}>
                <div className={styles.wrapper_table_header_search_wrapper}>
                  <SearchInput
                    value={filter?.search || ""}
                    onChange={(search) => {
                      onChangeSearch(search.trim() === "" ? null : search);
                    }}
                  />
                </div>
              </div>

              <div className={styles.wrapper_table_header_button}>
                <Button
                  title="Export XLSX"
                  variant={VariantButton.Transparent}
                  icon={<ICONS.ExportFile />}
                  onClick={exportTable}
                />
              </div>

              <div>
                <Filt
                  filter={filter}
                  onChange={(newFilter) =>
                    onChangeFilter({
                      ...filter,
                      ...newFilter
                    })
                  }
                  onClear={() =>
                    //@ts-ignore
                    onChangeFilter({
                      dateRange: null
                    })
                  }
                />
              </div>
            </header>
            <LogsTable
              data={data}
              page={page}
              isLoading={articleLoading}
              limit={LIMIT}
              type="article"
              sort={
                filter?.sorting || {
                  sortBy: null,
                  order: null
                }
              }
              onChangeSort={(sorting) => onChangeFilter({ ...filter, sorting })}
              onChangePage={onChangePage}
            />
          </div>
        );
    }
  };

  if (isLoading)
    return (
      <div className={styles.loading}>
        <ClipLoader size={50} />
      </div>
    );

  if (!article) return <EmptyListTitle title="Something went wrong" />;

  return (
    <div>
      <LogsHeader
        title="Article Logs"
        routeBack="/articles"
        actions={[
          <Button
            title="Edit"
            variant={VariantButton.Transparent}
            icon={<ICONS.Edit />}
            onClick={() => setIsOpenAddArticleModal(article)}
          />,
          <Button
            title="Delete"
            variant={VariantButton.RedTransparent}
            icon={<ICONS.Delete fill="red" />}
            onClick={() => setIsOpenDeleteArticleModal(true)}
          />,
          <div style={{ paddingLeft: "20px" }}>
            <Switcher
              value={article.status}
              label={article.status ? "Deactivate" : "Activate"}
              subLabel={`Switch to ${article.status ? "disable" : "enable"}`}
              onChange={toggleDeactivated}
            />
          </div>
        ]}
      />

      <div className="shadow_box">
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_info}>
            <h2 className={styles.wrapper_header_info_title}>
              {article.title}
            </h2>
            {article.note && (
              <p className={styles.wrapper_header_info_description}>
                {article.note}
              </p>
            )}
          </div>

          <div className={styles.wrapper_header_item}>
            <div className={styles.wrapper_header_item_icon}>
              <ICONS.User />
            </div>

            <div className={styles.wrapper_header_item_info}>
              <p className={styles.wrapper_header_item_info_value}>
                {article.readersCount}
              </p>
              <p className={styles.wrapper_header_item_info_label}>
                Number of Readers
              </p>
            </div>
          </div>
        </header>

        <div className={styles.wrapper_info}>
          <div className={styles.wrapper_info_item}>
            <p className={styles.wrapper_info_item_label}>ID</p>
            <p className={styles.wrapper_info_item_value}>{article.id}</p>
          </div>
          <div className={styles.wrapper_info_item}>
            <p className={styles.wrapper_info_item_label}>Program Date</p>
            <p className={styles.wrapper_info_item_value}>
              {article.programDate}
              {AudioService.isMaintenanceStage(article.programDate)
                ? " (Maintenance)"
                : null}
            </p>
          </div>
          <div className={styles.wrapper_info_item}>
            <p className={styles.wrapper_info_item_label}>Upload Date</p>
            <p className={styles.wrapper_info_item_value}>
              {moment(article.uploadDate).format("MM/DD/YYYY")}
            </p>
          </div>
          <div className={styles.wrapper_info_item}>
            <p className={styles.wrapper_info_item_label}>Word Count</p>
            <p className={styles.wrapper_info_item_value}>
              {ArticleService.formatNumberWithCommas(article.wordCount)}
            </p>
          </div>
          <div className={styles.wrapper_info_item}>
            <p className={styles.wrapper_info_item_label}>Status</p>
            <Status status={article.status} />
          </div>
        </div>

        <div className={styles.wrapper_tabs}>
          <Tabs
            tabs={[
              { title: "Article Text" },
              {
                title: "Logs",
                count: countData?.meta.itemsCount
              }
            ]}
            currentIndex={currentTab}
            onChange={onChangeTab}
          />
        </div>

        {renderTab()}
      </div>

      <AddArticleModal
        payload={isOpenAddArticleModal}
        onClose={() => setIsOpenAddArticleModal(false)}
        onCreateUpdateArticle={handleUpdateArticle}
      />

      <ConfirmModal
        isOpen={!!isOpenDeleteArticleModal}
        title={`Are you sure you want to delete ${article.title}`}
        onClose={() => setIsOpenDeleteArticleModal(false)}
        onConfirm={handleDeleteArticle}
      />
    </div>
  );
};

export default withRoleAuthorization([AdminRole.Admin])(ArticleLogs);
