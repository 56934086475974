import { configureStore } from "@reduxjs/toolkit";

import app from "./slices/app";
import player from "./slices/player";

export const store = configureStore({
  reducer: {
    app,
    player
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
