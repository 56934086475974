import { FC, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./styles.module.scss";

import { FileAPI } from "api";

import avatar from "./avatar.png";

type Props = {
  src: string | null | File;
  type?: string;
};

const PrivateImage: FC<Props> = ({ src, type = "banner" }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [link, setLink] = useState<undefined | string>();

  useEffect(() => {
    if (src) {
      if (src instanceof File) {
        setLink(URL.createObjectURL(src));
        setIsLoading(false);
      } else {
        fetchUrl();
      }
    }
  }, [src]);

  const fetchUrl = async () => {
    try {
      setIsLoading(true);
      const { links } = await FileAPI.getFileAsLink(type, [src as string]);
      const [url] = links;
      setLink(url.downloadLink);
    } catch (e) {
      alert("Error");
    }
  };

  return (
    <div className={styles.wrapper}>
      <img
        className={styles.wrapper_image}
        src={src === null ? avatar : link}
        onLoad={() => setIsLoading(false)}
        onError={() => setIsLoading(false)}
      />

      {isLoading && <Skeleton className={styles.wrapper_skeletone} />}
    </div>
  );
};

export default PrivateImage;
