import { FC } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";

import { Label } from "components";
import { ICONS } from "assets";

interface Props {
  min: number;
  max: number;
  step?: number;
  value: string | null;
  placeholder?: string;
  label?: string;
  onChange: (newValue: any) => void;
}

const NumberSelect: FC<Props> = ({
  min,
  max,
  step = 5,
  value,
  placeholder = "",
  label,
  onChange
}) => {
  const handleChange = (increment: number) => {
    let newValue: any;

    if (value === "" || value === null) {
      newValue = increment > 0 ? min : max;
    } else {
      newValue = Number(value) + increment * step;
      if (newValue > max) {
        newValue = min;
      } else if (newValue < min) {
        newValue = max;
      }
    }

    onChange(newValue.toString());
  };

  return (
    <div>
      {label && <Label text={label} />}

      <div className={styles.wrapper}>
        <div className={styles.wrapper_title}>
          <input
            className={cls(styles.wrapper_block_placeholder, {
              [styles.wrapper_block_title]: value !== null
            })}
            placeholder={placeholder}
            value={value !== null ? value.toString() : ""}
            onChange={(e) => {
              let newValue: any = e.target.value.trim();
              if (newValue === "") {
                onChange(newValue);
                return;
              }
              newValue = Number(newValue);

              if (!isNaN(newValue)) {
                onChange(
                  (newValue >= min && newValue <= max
                    ? newValue
                    : value || min
                  ).toString()
                );
              } else {
                onChange(value?.toString());
              }
            }}
          />
        </div>

        <div className={styles.wrapper_arrows}>
          <button
            className={cls(
              styles.wrapper_arrows_item,
              styles.wrapper_arrows_item_transform
            )}
            onClick={() => handleChange(1)}
          >
            <ICONS.SmallArrow />
          </button>
          <button
            className={styles.wrapper_arrows_item}
            onClick={() => handleChange(-1)}
          >
            <ICONS.SmallArrow />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NumberSelect;
