export const formatNumberWithCommas = (number: number): string => {
  return number.toLocaleString();
};

export const removeTagsFromText = (text: string) =>
  text.replace(/<\/?[^>]+(>|$)/g, "");

export const countWords = (text: string): number => {
  const cleanText = removeTagsFromText(text);

  const words = cleanText.split(/\s+/);

  const wordCount = words.length;

  return wordCount;
};
