import React from "react";
import { Navigate } from "react-router-dom";

import { useUser } from "hooks";

const PrivateRoute: React.FC<any> = ({ children }) => {
  const { user, loading } = useUser();

  if (loading) return;

  if (!loading && !user) {
    return <Navigate to="/login" />;
  }

  // If user is authenticated, render the private route
  return children;
};

export default PrivateRoute;
