import { FC } from "react";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

const DragDropUI: FC = () => (
  <div className={styles.wrapper_file}>
    <div className={styles.wrapper_file_icon}>
      <ICONS.Upload />
    </div>
    <p className={styles.wrapper_file_text}>
      <span className={styles.wrapper_file_text_underline}>
        Click to upload
      </span>{" "}
      or drag and drop
    </p>
  </div>
);

export default DragDropUI;
