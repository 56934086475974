import { FC } from "react";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

type Props = {
  audio: File;
  formatFileSize: string;
  title: string;
  icon?: any;
  onDelete: () => void;
  onPlay: () => void;
};

const AudioComponent: FC<Props> = ({
  audio,
  formatFileSize,
  title,
  icon,
  onDelete,
  onPlay
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_left}>
        {icon && (
          <div className={styles.wrapper_left_icon} onClick={onPlay}>
            {icon}
          </div>
        )}
        <div className={styles.wrapper_left_info}>
          <p className={styles.wrapper_left_info_title}>
            {audio.name || title}
          </p>
          <p className={styles.wrapper_left_info_size}>{formatFileSize}</p>
        </div>
      </div>

      <div className={styles.wrapper_delete} onClick={onDelete}>
        <ICONS.Delete />
      </div>
    </div>
  );
};

export default AudioComponent;
