import client from "./client";

class AuthAPI {
  static signIn(email: string, password: string) {
    return client
      .post("admin/signin", { email, password })
      .then(({ data }) => data);
  }

  static getCurrentAdmin() {
    return client.get("admin").then(({ data }) => data);
  }

  static changePassword(password: string) {
    return client
      .patch("admin/change/password", {
        password
      })
      .then(({ data }) => data);
  }

  static setFirstSignIn() {
    return client.get("admin/first/signin").then(({ data }) => data);
  }

  static logout() {
    return client.delete("admin/logout").then(({ data }) => data);
  }
}

export default AuthAPI;
