import { FC } from "react";
import styles from "./styles.module.scss";

type Props = {
  message: string;
};

const Error: FC<Props> = ({ message }) => (
  <p className={styles.error}>{message}</p>
);

export default Error;
