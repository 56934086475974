import Articles from "./Articles";
import Audio from "./Audio";
import Banners from "./Banners";
import Users from "./Users";
import Settings from "./Settings";
import Main from "./Main";
import Staff from "./Staff";

export default {
  Main,
  Banners,
  Audio,
  Articles,
  Users,
  Settings,
  Staff
};
