import { FC, useRef, useState, useEffect, useMemo } from "react";
import styles from "./styles.module.scss";

import { Filter as FilterComponents } from "components";

import { useOutsideClick } from "hooks";

type Props = {
  filter: any;
  onChange: (value: any) => void;
  onClear: () => void;
};

const Filter: FC<Props> = ({ filter: initialFilter, onChange, onClear }) => {
  const [filter, setFilter] = useState<any>(initialFilter);
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpenFilter) {
      setFilter(initialFilter);
    }
  }, [isOpenFilter, initialFilter]);

  const divRef = useRef(null);
  useOutsideClick({
    ref: divRef,
    onClickOutside: () => setIsOpenFilter(false)
  });

  const handleApplyFilter = () => {
    onChange(filter);
    setIsOpenFilter(false);
  };

  const handleClearFilter = () => {
    onClear();
    setIsOpenFilter(false);
  };

  const disableApply = useMemo(() => {
    if (
      (filter?.createdAt?.from && !filter?.createdAt?.to) ||
      (filter?.createdAt?.to && !filter?.createdAt?.from)
    )
      return true;

    if (
      (filter?.schedulingDate?.from && !filter?.schedulingDate?.to) ||
      (filter?.schedulingDate?.to && !filter?.schedulingDate?.from)
    )
      return true;

    if (
      (filter?.endDate?.from && !filter?.endDate?.to) ||
      (filter?.endDate?.to && !filter?.endDate?.from)
    )
      return true;

    const isDisable = Object.keys(filter)
      .filter((key) => {
        return (
          filter[key] !== "page" &&
          filter[key] !== "search" &&
          filter[key] !== "sorting"
        );
      })
      .every((key) => {
        return !filter[key]?.from && !filter[key]?.to;
      });

    return isDisable;
  }, [filter]);

  return (
    <div ref={divRef} className={styles.wrapper}>
      <div
        className={styles.wrapper_icon}
        onClick={() => setIsOpenFilter(!isOpenFilter)}
      >
        <FilterComponents.Wrapper />
      </div>

      {isOpenFilter && (
        <div className={styles.wrapper_filter}>
          <FilterComponents.Container
            disableApply={disableApply}
            onApply={handleApplyFilter}
            onClear={handleClearFilter}
          >
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.DateRange
                value={filter.createdAt || null}
                title="Upload range"
                //@ts-ignore
                position={["Left", "Right"]}
                onChange={(createdAt) =>
                  setFilter({
                    ...filter,
                    createdAt
                  })
                }
              />
            </div>
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.DateRange
                value={filter.schedulingDate || null}
                title="Start date range"
                //@ts-ignore
                position={["Left", "Right"]}
                onChange={(schedulingDate) =>
                  setFilter({
                    ...filter,
                    schedulingDate
                  })
                }
              />
            </div>
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.DateRange
                value={filter.endDate || null}
                title="End date range"
                //@ts-ignore
                position={["Left", "Right"]}
                onChange={(endDate) =>
                  setFilter({
                    ...filter,
                    endDate
                  })
                }
              />
            </div>
          </FilterComponents.Container>
        </div>
      )}
    </div>
  );
};

export default Filter;
