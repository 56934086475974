import { FC } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import styles from "../styles.module.scss";

import { AdminAPI } from "api";

import { Button, Input } from "components";

import { toggleLoading } from "store/slices/app";

const passwordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required(
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .min(
      8,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .max(
      30,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .matches(
      /[a-z]/,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .matches(
      /[A-Z]/,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .matches(
      /\d/,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .matches(
      /[~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]/,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    ),
  repeatPassword: Yup.string()
    //@ts-ignore
    .oneOf([Yup.ref("password"), null], "Passwords do not match! Try again.")
    .required("Repeat password is required")
});

const ChangePassword: FC = () => {
  const dispatch = useDispatch();

  const {
    values,
    errors,
    isValid,
    touched,
    setFieldTouched,
    handleSubmit,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      password: "",
      repeatPassword: ""
    },
    validationSchema: passwordValidationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: handleChangePassword
  });

  async function handleChangePassword() {
    try {
      dispatch(toggleLoading(true));
      await AdminAPI.changePassword(values.password);
      toast("Password updated successfully", {
        type: "success"
      });
    } catch (e: any) {
      if (e?.response?.data?.message) {
        toast(e.response.data.message, {
          type: "error"
        });
      }
      resetForm();
    } finally {
      dispatch(toggleLoading(false));
    }
  }

  return (
    <div className={styles.wrapper_group}>
      <h3 className={styles.wrapper_group_title}>Password</h3>
      <div className={styles.wrapper_group_field}>
        <Input
          label="Password"
          isSucure
          value={values.password}
          error={
            touched.password && errors.password ? errors.password : undefined
          }
          onChange={(val) => {
            if (val.length > 30) return;

            setFieldTouched("password");
            setFieldValue("password", val);
          }}
        />
      </div>
      <div className={styles.wrapper_group_field}>
        <Input
          value={values.repeatPassword}
          label="Confirm Password"
          isSucure
          error={
            touched.repeatPassword && errors.repeatPassword
              ? errors.repeatPassword
              : undefined
          }
          onChange={(val) => {
            if (val.length > 30) return;

            setFieldTouched("repeatPassword");
            setFieldValue("repeatPassword", val);
          }}
        />
      </div>

      <div className={styles.wrapper_group_buttons}>
        <div className={styles.wrapper_group_buttons_item}>
          <Button
            title="Save password"
            disable={!isValid}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
