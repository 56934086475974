import { FC } from "react";
import moment from "moment";
import styles from "./styles.module.scss";

import { DatePicker } from "components";

enum Position {
  Left = "Left",
  Right = "Right"
}

type Props = {
  value: null | {
    from: Date | null;
    to: Date | null;
  };
  position?: [Position, Position];
  title?: string;
  labelFrom?: string | false;
  labelTo?: string | false;
  onChange: (
    val: null | {
      from: Date | null;
      to: Date | null;
    }
  ) => void;
};

const DateRange: FC<Props> = ({
  value,
  title,
  labelFrom = "From",
  labelTo = "To",
  position = [Position.Left, Position.Right],
  onChange
}) => {
  const handleChangeFrom = (day: Date | null) => {
    if (!value || !value.to) {
      onChange({
        from: day,
        to: value ? value.to : null
      });
      return;
    }

    if (moment(day).diff(moment(value.to), "day") > 0) {
      onChange({ from: day, to: null });
      return;
    }

    onChange({ from: day, to: value.to });
  };

  const handleChangeTo = (day: Date | null) => {
    if (!value || !value.from) {
      onChange({
        from: value ? value.from : null,
        to: day
      });
      return;
    }

    if (moment(day).diff(moment(value.from), "day") < 0) {
      onChange({ from: null, to: day });
      return;
    }

    onChange({ from: value.from, to: day });
  };

  return (
    <div>
      {title && <p className={styles.title}>{title}</p>}
      <div className={styles.wrapper}>
        <div className={styles.wrapper_item}>
          <DatePicker
            value={value ? value.from : null}
            label={labelFrom}
            position={position[0]}
            onChange={handleChangeFrom}
          />
        </div>
        <p className={styles.wrapper_separator}>-</p>
        <div className={styles.wrapper_item}>
          <DatePicker
            value={value ? value.to : null}
            label={labelTo}
            position={position[1]}
            onChange={handleChangeTo}
          />
        </div>
      </div>
    </div>
  );
};

export default DateRange;
