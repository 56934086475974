import nav from "./nav";

import { ReactComponent as Arrows } from "./arrows.svg";
import { ReactComponent as Logout } from "./logout.svg";
import { ReactComponent as Plus } from "./plus.svg";
import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as ExportFile } from "./exportFile.svg";
import { ReactComponent as Filter } from "./filter.svg";
import { ReactComponent as Checked } from "./checked.svg";
import { ReactComponent as SmallArrow } from "./smallArrow.svg";
import { ReactComponent as Dots } from "./dots.svg";
import { ReactComponent as Delete } from "./delete.svg";
import { ReactComponent as Edit } from "./edit.svg";
import { ReactComponent as ReviewReport } from "./reviewReport.svg";
import { ReactComponent as ReviewLogs } from "./reviewLogs.svg";
import { ReactComponent as Deactivate } from "./deactivate.svg";
import { ReactComponent as Close } from "./close.svg";
import { ReactComponent as SelectArrow } from "./selectArrow.svg";
import { ReactComponent as Upload } from "./upload.svg";
import { ReactComponent as Play } from "./play.svg";
import { ReactComponent as Cross } from "./cross.svg";
import { ReactComponent as SmallLeftArrow } from "./smallLeftArrow.svg";
import { ReactComponent as Back } from "./back.svg";
import { ReactComponent as Reupload } from "./reupload.svg";
import { ReactComponent as User } from "./user.svg";
import { ReactComponent as Minus } from "./minus.svg";
import { ReactComponent as Block } from "./block.svg";
import { ReactComponent as Anonymize } from "./anonymize.svg";
import { ReactComponent as FreeAccess } from "./freeAccess.svg";
import { ReactComponent as Pause } from "./pause.svg";

const ICONS = {
  nav,

  Arrows,
  Logout,
  Plus,
  Search,
  ExportFile,
  Filter,
  Checked,
  SmallArrow,
  Dots,
  Delete,
  Edit,
  ReviewReport,
  ReviewLogs,
  Deactivate,
  Close,
  SelectArrow,
  Upload,
  Play,
  Cross,
  SmallLeftArrow,
  Back,
  Reupload,
  User,
  Minus,
  Block,
  Anonymize,
  FreeAccess,
  Pause
};

export default ICONS;
