import { FC } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import styles from "./styles.module.scss";

import { FileAPI } from "api";

import {
  Button,
  EmptyListTitle,
  Pagination,
  SearchInput,
  TableHead,
  TableLoading
} from "components";
import Filt from "./Filter";

import { toggleLoading } from "store/slices/app";
import { usePagination } from "hooks";
import { ICONS } from "assets";
import { VariantButton } from "enums";
import { toast } from "react-toastify";

const LIMIT = 10;

type Props = {
  userId: number;
};

const Articles: FC<Props> = ({ userId }) => {
  const dispatch = useDispatch();

  const fetchData = async (page: number, take: number, filter: any) => {
    const resp = await FileAPI.getUserFileSession(
      "article",
      userId,
      page,
      take,
      filter
    );
    return resp;
  };

  const {
    isLoading,
    data,
    page,
    filter,
    onChangeSearch,
    onChangeFilter,
    onChangePage
  } = usePagination<
    {
      data: any;
      meta: { itemsCount: number };
    },
    {
      dateRange: {
        from: Date | null;
        to: Date | null;
      } | null;
      search: string | null;
    }
  >(fetchData, LIMIT, ["tab"]);

  const exportTable = async () => {
    try {
      dispatch(toggleLoading(true));

      const response = await FileAPI.getUserLogsExport("article", userId);
      const blob = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `User_${userId}_Article_Logs_${moment().format(
        "MM-DD-YYYY"
      )}.xlsx`;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (e: any) {
      toast(e.response.data.message, {
        type: "error"
      });
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const renderLogs = () => {
    if (isLoading) return <TableLoading />;

    if (!data?.data) return null;

    if (data.data.length === 0) return <EmptyListTitle title="No logs yet" />;

    return data.data.map((log: any, index: number) => (
      <div key={index} className={styles.item}>
        <div>{log.article.id}</div>
        <div className={styles.item_title}>{log.article.title}</div>
        <div>{moment(log.sessionDate).format("MM/DD/YYYY")}</div>
      </div>
    ));
  };

  return (
    <div>
      <header className={styles.wrapper_table_header}>
        <div className={styles.wrapper_table_header_search}>
          <div className={styles.wrapper_table_header_search_wrapper}>
            <SearchInput
              value={filter?.search || ""}
              onChange={(search) => {
                onChangeSearch(search.trim() === "" ? null : search);
              }}
            />
          </div>
        </div>

        <div className={styles.wrapper_table_header_button}>
          <Button
            title="Export XLSX"
            variant={VariantButton.Transparent}
            icon={<ICONS.ExportFile />}
            onClick={exportTable}
          />
        </div>

        <div>
          <Filt
            filter={filter}
            onChange={(newFilter) =>
              onChangeFilter({
                ...filter,
                ...newFilter
              })
            }
            onClear={() =>
              //@ts-ignore
              onChangeFilter({
                dateRange: null
              })
            }
          />
        </div>
      </header>

      <TableHead
        items={[
          { label: "Article ID" },
          { label: "Title" },
          { label: "Reading Date" }
        ]}
        flexes={[1, 3, 1, 1]}
        sortOptions={{
          sortBy: null,
          order: null
        }}
        onChangeSorted={() => {}}
      />

      {renderLogs()}
      {data?.meta.itemsCount && data?.meta.itemsCount !== 0 ? (
        <div className={styles.pagination}>
          <Pagination
            count={data.meta.itemsCount}
            limit={LIMIT}
            currentPage={page}
            onChange={onChangePage}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Articles;
