import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface PaginationResult<Data, F> {
  onChangePage: (newPage: number) => void;
  onRefresh: () => void;
  onChangeFilter: (newFilter: F) => void;
  onChangeSearch: (search: string | null) => void;
  filter: F;
  page: number;
  data: Data | null;
  isLoading: boolean;
}

const usePagination = <Data, F = object>(
  apiFunction: (page: number, itemsPerPage: number, filter: F) => Promise<Data>,
  itemsPerPage = 10,
  ignoreQuery: string[] = []
): PaginationResult<Data, F> => {
  const location = useLocation();
  const history = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const currentPage = parseInt(queryParams.get("page") || "1", 10);

  const [filter, setFilter] = useState<F>(() => {
    const filt: any = {};
    //@ts-ignore
    for (const [key, value] of queryParams.entries()) {
      filt[key] = JSON.parse(value);
    }
    filt["page"] = currentPage;
    return filt;
  });
  const [data, setData] = useState<Data | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [timeoutTime, setTimeoutTime] = useState<number>(0);

  const fetchData = async (): Promise<void> => {
    try {
      const parseFilter: any = {};

      for (const key in filter) {
        if (
          filter[key] !== null &&
          key !== "page" &&
          !ignoreQuery.find((q) => q === key)
        ) {
          parseFilter[key] = filter[key];
        }
      }
      const response = await apiFunction(
        //@ts-ignore
        filter.page,
        itemsPerPage,
        parseFilter
      );
      setData(response);
    } catch (error) {
      alert("Error");
    }
  };

  const onChangePage = (newPage: number): void => {
    setFilter({ ...filter, page: newPage });
    setTimeoutTime(0);
    queryParams.set("page", newPage.toString());
    history({ search: queryParams.toString() }, { replace: true });
  };

  const onRefresh = async () => {
    await fetchData();
  };

  const handleChangeFilter = async (newFilter: F) => {
    setFilter({ ...newFilter, page: 1 });
    setTimeoutTime(0);

    queryParams.set("page", "1");
    for (const key in newFilter) {
      if (key !== "page") {
        if (newFilter[key] !== null) {
          queryParams.set(key, JSON.stringify(newFilter[key]));
        } else {
          queryParams.delete(key);
        }
      }
    }

    history({ search: queryParams.toString() }, { replace: true });
  };

  const handleChangeSearch = async (search: string | null) => {
    setFilter({ ...filter, search, page: 1 });
    setTimeoutTime(search !== null ? 300 : 0);
    queryParams.set("page", "1");

    if (search !== null) {
      queryParams.set("search", JSON.stringify(search));
    } else {
      queryParams.delete("search");
    }

    history({ search: queryParams.toString() }, { replace: true });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(true);
      fetchData().then(() => {
        setIsLoading(false);
      });
    }, timeoutTime);

    return () => {
      clearTimeout(timeout);
    };
  }, [filter]);

  return {
    //@ts-ignore
    page: parseInt(filter.page || 1),
    data,
    isLoading,
    filter,
    onChangeFilter: handleChangeFilter,
    onChangeSearch: handleChangeSearch,
    onChangePage,
    onRefresh
  };
};

export default usePagination;
