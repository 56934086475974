import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";

import { AudioAPI, FileAPI } from "api";

import { ICONS } from "assets";

import {
  Header,
  Button,
  SearchInput,
  TableHead,
  Pagination,
  DotSelector,
  ConfirmModal,
  EmptyListTitle,
  AddAudioModal,
  Status,
  TableLoading
} from "components";
import Filt from "./Filter";

import { toggleLoading } from "store/slices/app";
import { usePagination } from "hooks";
import { AudioService, AwsService } from "utils";
import { withRoleAuthorization } from "hoc";

import { AdminRole, OrderBy, VariantButton } from "enums";
import { IAudio } from "interfaces";

const LIMIT = 10;

const CreateAudioInitialValue = {
  title: "",
  description: "",
  category: null,
  url: null
};

const Audio: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteModalPayload, setDeleteModalPayload] = useState<null | IAudio>(
    null
  );
  const [activateDeactivateModal, setActivateDeactivateModal] =
    useState<null | IAudio>(null);
  const [isOpenAddAudioModal, setIsOpenAddAudioModal] = useState<any | false>(
    false
  );

  const fetchData = async (page: number, take: number, filter: any) => {
    const resp = await AudioAPI.getAudios(page, take, filter);
    return resp;
  };

  const {
    isLoading,
    data,
    page,
    filter,
    onChangeFilter,
    onChangePage,
    onRefresh,
    onChangeSearch
  } = usePagination<
    {
      data: Array<IAudio>;
      meta: { itemsCount: number };
    },
    {
      sorting: {
        sortBy: any | null;
        order: OrderBy | null;
      } | null;
      search: string | null;
      dateRange: {
        from: Date | null;
        to: Date | null;
      } | null;
    }
  >(fetchData, LIMIT);

  const toggleDeactivated = async () => {
    try {
      if (!activateDeactivateModal) return;
      dispatch(toggleLoading(true));

      await AudioAPI.editAudio(
        {
          status: !activateDeactivateModal.status
        },
        activateDeactivateModal.id
      );
      await onRefresh();

      toast(
        !activateDeactivateModal.status
          ? "Activated successfully"
          : "Deactivated successfully",
        {
          type: "success",
          icon: !activateDeactivateModal.status ? true : <ICONS.Minus />
        }
      );

      setActivateDeactivateModal(null);
    } catch (e) {
      alert("Error");
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const handleCreateUpdateAudio = async (newAudio: any) => {
    try {
      dispatch(toggleLoading(true));
      const { audio, id, ...other } = newAudio;

      if (!id) {
        // Create new audio

        const { links } = await FileAPI.getLinks("audio", [audio.name]);
        const { uploadLink, key } = links[0];
        await AwsService.uploadFileToStorage(audio, uploadLink, audio.type);

        await AudioAPI.createAudio({
          ...other,
          url: key
        });
        await onRefresh();
        toast("The audio has been created.", {
          type: "success"
        });
      } else {
        // Update audio

        let audioUrl = typeof audio === "string" ? audio : "";

        if (typeof audio !== "string") {
          const { links } = await FileAPI.getLinks("audio", [audio.name]);
          const { uploadLink, key } = links[0];
          await AwsService.uploadFileToStorage(audio, uploadLink, audio.type);
          audioUrl = key;

          FileAPI.deleteFiles("audio", [isOpenAddAudioModal.url]);
        }

        await AudioAPI.editAudio(
          {
            ...other,
            url: audioUrl
          },
          id
        );
        await onRefresh();
        toast("The audio has been updated.", {
          type: "success"
        });
      }

      setIsOpenAddAudioModal(false);
    } catch (e: any) {
      toast(e.response.data.message, {
        type: "error"
      });
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const handleDeleteAudio = async () => {
    try {
      if (deleteModalPayload === null) return;
      dispatch(toggleLoading(true));

      await AudioAPI.deleteAudio(deleteModalPayload.id);
      await onRefresh();
      FileAPI.deleteFiles("audio", [deleteModalPayload.url]);
      setDeleteModalPayload(null);
    } catch (e) {
      alert("Error");
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const exportTable = async () => {
    try {
      dispatch(toggleLoading(true));
      const response = await AudioAPI.getAudioExport();
      const blob = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${moment().format("YYYY-MM-DDT_hh_mm_ss")}_audios.xlsx`;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (e: any) {
      toast(e.response.data.message, {
        type: "error"
      });
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const renderAudio = () => {
    if (isLoading) return <TableLoading />;

    if (!data?.data) return null;

    if (data.data.length === 0) return <EmptyListTitle title="No audios yet" />;

    return data.data.map((audio, index) => (
      <div
        key={audio.id}
        className={styles.wrapper_item}
        onClick={() => navigate(`${audio.id}/logs`)}>
        <div className={styles.wrapper_item_id}>{audio.id}</div>
        <div className={styles.wrapper_item_info}>
          <p className={styles.wrapper_item_info_name}>{audio.title}</p>
          <p className={styles.wrapper_item_info_description}>
            {audio.description}
          </p>
        </div>
        <div>{audio.category}</div>
        <div>{audio.programDate}</div>
        <div>{moment(audio.createdAt).format("MM/DD/YYYY")}</div>
        <div>{AudioService.formatSecondsToMinutesAndSeconds(audio.length)}</div>
        <div className={styles.wrapper_item_status}>
          <Status status={audio.status} />
        </div>
        <div>{audio.rate.toFixed(2)}</div>
        <div>{audio.listenersCount}</div> <div>{audio.listenCount}</div>{" "}
        <div
          className={styles.wrapper_item_dots}
          style={{
            zIndex: data.data.length - index
          }}>
          <DotSelector
            items={[
              {
                label: "Review logs",
                icon: <ICONS.ReviewLogs />,
                onClick: () => navigate(`${audio.id}/logs`)
              },
              {
                label: "Edit",
                icon: <ICONS.Edit />,
                onClick: () => setIsOpenAddAudioModal(audio)
              },
              {
                label: audio.status ? "Deactivate" : "Activate",
                icon: <ICONS.Deactivate />,
                onClick: () => setActivateDeactivateModal(audio)
              },
              {
                label: "Delete",
                icon: <ICONS.Delete />,
                textStyle: {
                  color: "#FF0033"
                },
                style: {
                  borderTop: "1px solid #E6E6E9"
                },
                onClick: () => setDeleteModalPayload(audio)
              }
            ]}
          />
        </div>
      </div>
    ));
  };

  return (
    <div>
      <Header
        title="Audios"
        helper={
          data?.meta.itemsCount ? `(${data?.meta.itemsCount})` : undefined
        }
        buttons={[
          <Button
            title="Add Audio"
            icon={<ICONS.Plus />}
            onClick={() => setIsOpenAddAudioModal(CreateAudioInitialValue)}
          />
        ]}
      />

      <div className="shadow_box">
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_search}>
            <div className={styles.wrapper_header_search_wrapper}>
              <SearchInput
                value={filter?.search || ""}
                onChange={(search) => {
                  onChangeSearch(search.trim() === "" ? null : search);
                }}
              />
            </div>
          </div>

          <div className={styles.wrapper_header_button}>
            <Button
              title="Export XLSX"
              variant={VariantButton.Transparent}
              icon={<ICONS.ExportFile />}
              onClick={exportTable}
            />
          </div>

          <div>
            <Filt
              filter={filter}
              onChange={(newFilter) =>
                onChangeFilter({
                  ...filter,
                  ...newFilter
                })
              }
              onClear={() =>
                //@ts-ignore
                onChangeFilter({
                  dateRange: null
                })
              }
            />
          </div>
        </header>

        <TableHead
          containerStyle={{
            paddingRight: "24px"
          }}
          items={[
            { label: "ID", sortedBy: "id" },
            { label: "Title", sortedBy: "title" },
            {
              label: "Category",
              sortedBy: "category"
            },
            {
              label: "PD",
              sortedBy: "programDate",
              popupText: "Program Date"
            },
            {
              label: "UD",
              sortedBy: "createdAt",
              popupText: "Upload Date"
            },
            { label: "Length" },
            {
              label: "Status",
              sortedBy: "status",
              style: { justifyContent: "center" }
            },
            {
              label: "AR",
              sortedBy: "rate",
              popupText: "Average rating"
            },
            {
              label: "NOL",
              sortedBy: "listenersCount",
              popupText: "Number of listeners"
            },
            {
              label: "TNP",
              sortedBy: "listenCount",
              popupText: "Total number of plays "
            }
          ]}
          flexes={[1, 2, 1, 1, 1, 1, 1, 1, 1, 1]}
          sortOptions={
            filter?.sorting || {
              sortBy: null,
              order: null
            }
          }
          onChangeSorted={(sorting) => onChangeFilter({ ...filter, sorting })}
        />

        {renderAudio()}

        {data?.meta.itemsCount && data?.meta.itemsCount !== 0 ? (
          <div className={styles.wrapper_pagination}>
            <Pagination
              count={data.meta.itemsCount}
              limit={LIMIT}
              currentPage={page}
              onChange={(page) => onChangePage(page)}
            />
          </div>
        ) : null}
      </div>

      {!!isOpenAddAudioModal && (
        <AddAudioModal
          payload={isOpenAddAudioModal}
          onClose={() => setIsOpenAddAudioModal(false)}
          onCreateUpdateAudio={handleCreateUpdateAudio}
        />
      )}

      <ConfirmModal
        isOpen={!!activateDeactivateModal}
        title={`${
          activateDeactivateModal?.status
            ? "Deactivate Audio"
            : "Activate Audio"
        }`}
        leftBtnText="No"
        rightBtnText="Yes"
        onClose={() => setActivateDeactivateModal(null)}
        onConfirm={toggleDeactivated}
      />

      <ConfirmModal
        isOpen={!!deleteModalPayload}
        title={`Are you sure you want to delete ${deleteModalPayload?.title}`}
        onClose={() => setDeleteModalPayload(null)}
        onConfirm={handleDeleteAudio}
      />
    </div>
  );
};

export default withRoleAuthorization([AdminRole.Admin])(Audio);
