import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";

import { AuthAPI } from "api";

import { Input, Button } from "components";
import ChangePasswordModal from "./ChangePasswordModal";

import { useUser } from "hooks";
import { TokenService } from "utils";

const LoginSchema = yup.object({
  email: yup
    .string()
    .required(`Required`)
    .max(80, "Max size 80")
    .matches(/([a-z0-9._]{3,80})@([a-z]{3,80})\.[a-z]{2,3}/, `Incorrect email`),
  password: yup
    .string()
    .required(`Required`)
    .min(8, `Minimum characters: 8`)
    .max(30, `Maximum characters: 30`)
    .matches(/[A-Z]/, "At east one uppercase letter")
    .matches(/[a-z]/, "At least one lowercase letter")
    .matches(/\d/, "At least one number")
    .matches(
      /[~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/]/,
      "At least one special character"
    )
});

const Login: FC = () => {
  const { updateUser } = useUser();
  const navigate = useNavigate();
  const {
    values,
    errors,
    isValid,
    touched,
    setFieldTouched,
    setFieldError,
    setFieldValue,
    handleSubmit
  } = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: LoginSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: login
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] =
    useState<boolean>(false);
  const [tokens, setTokens] = useState<{
    accessToken: null | string;
    refreshToken: null | string;
  }>({ accessToken: null, refreshToken: null });

  async function login() {
    try {
      setIsLoading(true);
      const { accessToken, refreshToken, user } = await AuthAPI.signIn(
        values.email,
        values.password
      );

      updateUser(user);

      if (user.firstSignIn) {
        setIsOpenChangePasswordModal(true);
        setTokens({ accessToken, refreshToken });
      } else {
        TokenService.setTokens(accessToken, refreshToken);
        navigate("/");
      }
    } catch (e: any) {
      if (e.response.data.field) {
        setFieldError(e.response.data.field, e.response.data.message);
      } else {
        toast(e.response.data.message, {
          type: "error"
        });
      }
    } finally {
      setIsLoading(false);
    }
  }
  const handleChangePassword = async (password: string) => {
    try {
      if (!tokens.accessToken || !tokens.refreshToken) return;
      setIsLoading(true);
      TokenService.setTokens(tokens.accessToken, tokens.refreshToken);
      await AuthAPI.changePassword(password);
      AuthAPI.setFirstSignIn();

      navigate("/");
    } catch (e) {
      alert("Error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSkipSetNewPassword = async () => {
    try {
      if (!tokens.accessToken || !tokens.refreshToken) return;
      TokenService.setTokens(tokens.accessToken, tokens.refreshToken);
      AuthAPI.setFirstSignIn();
      navigate("/");
    } catch (e) {
      alert("Error");
    }
  };

  useEffect(() => {
    if (TokenService.getTokens()) {
      navigate("/");
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_logo}>EverCalm</div>

      <div className={styles.wrapper_box}>
        <div className={styles.wrapper_box_input}>
          <Input
            value={values.email}
            label="Email"
            error={touched.email && errors.email ? errors.email : undefined}
            onChange={(val) => {
              setFieldTouched("email");
              setFieldValue("email", val);
            }}
          />
        </div>
        <div className={styles.wrapper_box_input}>
          <Input
            value={values.password}
            label="Password"
            isSucure
            error={
              touched.password && errors.password ? errors.password : undefined
            }
            onChange={(val) => {
              setFieldTouched("password");
              setFieldValue("password", val);
            }}
          />
        </div>

        <Button
          title="Login"
          loading={isLoading}
          disable={!isValid}
          onClick={handleSubmit}
        />
      </div>

      <ChangePasswordModal
        isOpen={isOpenChangePasswordModal}
        isLoading={isLoading}
        onChangePassword={handleChangePassword}
        onSkip={handleSkipSetNewPassword}
      />
    </div>
  );
};

export default Login;
