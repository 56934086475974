import { FC } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";

import { Label } from "components";
import { ICONS } from "assets";

interface Item {
  value: string;
  label: string;
}

interface ArrowSelectProps {
  items: Item[];
  value: string | null;
  placeholder?: string;
  label?: string;
  onChange: (newValue: string) => void;
}

const ArrowSelect: FC<ArrowSelectProps> = ({
  items,
  value,
  placeholder = "",
  label,
  onChange
}) => {
  const handleChange = (increment: number) => {
    if (items.length === 0) return;

    const currentIndex = value
      ? items.findIndex((item) => item.value === value)
      : -1;
    const newIndex = (currentIndex + increment + items.length) % items.length;

    const newValue = items[newIndex].value;
    onChange(newValue);
  };

  const findItem = items.find((i) => i.value === value);

  return (
    <div>
      {label && <Label text={label} />}

      <div className={styles.wrapper}>
        <div className={styles.wrapper_title}>
          <p
            className={cls(styles.wrapper_block_placeholder, {
              [styles.wrapper_block_title]: value
            })}
          >
            {findItem ? findItem.label : placeholder}
          </p>
        </div>

        <div className={styles.wrapper_arrows}>
          <button
            className={cls(
              styles.wrapper_arrows_item,
              styles.wrapper_arrows_item_transform
            )}
            onClick={() => handleChange(1)}
          >
            <ICONS.SmallArrow />
          </button>
          <button
            className={styles.wrapper_arrows_item}
            onClick={() => handleChange(-1)}
          >
            <ICONS.SmallArrow />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ArrowSelect;
