import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import { PrivateRoute, MainLoading } from "components";

import { Dashboard, Login, AudioLogs, ArticleLogs, UserLogs } from "pages";

import { store } from "./store";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Dashboard.Main />
      </PrivateRoute>
    ),
    children: [
      {
        path: "banners",
        element: <Dashboard.Banners />
      },
      {
        path: "users",
        element: <Dashboard.Users />
      },
      {
        path: "articles",
        element: <Dashboard.Articles />
      },
      {
        path: "audio",
        element: <Dashboard.Audio />
      },
      {
        path: "staff",
        element: <Dashboard.Staff />
      },
      {
        path: "settings",
        element: <Dashboard.Settings />
      },
      {
        path: "audio/:id/logs",
        element: <AudioLogs />
      },
      {
        path: "articles/:id/logs",
        element: <ArticleLogs />
      },
      {
        path: "users/:id/logs",
        element: <UserLogs />
      }
    ]
  }
]);

function App() {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
      <MainLoading />
      <ToastContainer />
    </Provider>
  );
}

export default App;
