import { FC } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import styles from "./styles.module.scss";

const TableLoading: FC = () => (
  <div className={styles.wrapper}>
    <ClipLoader size={30} />
  </div>
);

export default TableLoading;
