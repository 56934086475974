import { FC } from "react";
import moment from "moment";
import styles from "./styles.module.scss";

import {
  EmptyListTitle,
  Pagination,
  TableHead,
  TableLoading
} from "components";

// import { OrderBy } from "enums";
import { IFileSession } from "interfaces";

type Props = {
  data: null | {
    data: Array<IFileSession>;
    meta: { itemsCount: number };
  };
  isLoading: boolean;
  page: number;
  type?: "audio" | "article";
  limit?: number;
  sort?: any;
  onChangeSort?: (sort: any) => void;
  onChangePage: (page: number) => void;
};

const LogsTable: FC<Props> = ({
  data,
  isLoading,
  page,
  type = "audio",
  limit = 10,
  sort,
  onChangeSort,
  onChangePage
}) => {
  // const [sort] = useState<{
  //   sortBy: any | null;
  //   order: OrderBy | null;
  // }>({ sortBy: null, order: null });

  const renderLogs = () => {
    if (isLoading) return <TableLoading />;

    if (!data?.data) return null;

    if (data.data.length === 0) return <EmptyListTitle title="No logs yet" />;

    return data.data.map((log) => (
      <div key={log.id} className={styles.item}>
        <div>{log.id}</div>
        <div>{log?.user?.firstName || "-"}</div>
        <div className={styles.item_email}>{log?.user?.email || "-"}</div>
        <div>{moment(log.sessionDate).format("MM/DD/YYYY")}</div>
      </div>
    ));
  };

  return (
    <div>
      <TableHead
        items={[
          { label: "ID", sortedBy: "id" },
          { label: "Name", sortedBy: "firstName" },
          { label: "Email", sortedBy: "email" },
          {
            label: type === "audio" ? "Listening Date" : "Reading Date",
            sortedBy: "sessionDate"
          }
        ]}
        flexes={[1, 1, 2, 1]}
        sortOptions={sort}
        onChangeSorted={(sort) => onChangeSort && onChangeSort(sort)}
      />

      {renderLogs()}

      {data?.meta.itemsCount && data?.meta.itemsCount !== 0 ? (
        <div className={styles.pagination}>
          <Pagination
            count={data.meta.itemsCount}
            limit={limit}
            currentPage={page}
            onChange={onChangePage}
          />
        </div>
      ) : null}
    </div>
  );
};

export default LogsTable;
