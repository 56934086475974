export const setTokens = (accessToken: string, refreshToken: string) => {
  localStorage.setItem("@accessToken", accessToken);
  localStorage.setItem("@refreshToken", refreshToken);
};

export const getTokens = () => {
  const accessToken = localStorage.getItem("@accessToken");
  const refreshToken = localStorage.getItem("@refreshToken");

  if (!accessToken || !refreshToken) return null;

  return { accessToken, refreshToken };
};

export const clearTokens = () => {
  localStorage.removeItem("@accessToken");
  localStorage.removeItem("@refreshToken");
};
