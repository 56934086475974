import client from "./client";

class FileAPI {
  static getLinks(folder: string, fileNames: Array<string>) {
    return client
      .post(`file/${folder}/upload`, {
        fileNames
      })
      .then(({ data }) => data);
  }

  static deleteFiles(folder: string, keys: Array<string>) {
    return client
      .post(`file/${folder}/delete`, { keys })
      .then(({ data }) => data);
  }

  static getFile(folder: string, key: string) {
    return client
      .get(`file/${folder}/receive?key=${key}`, {
        responseType: "stream"
      })
      .then(({ data }) => data);
  }

  static getFileAsLink(folder: string, keys: Array<string>) {
    return client.post(`file/${folder}/get`, { keys }).then(({ data }) => data);
  }

  static getFileSession(
    type: "audio" | "article",
    id: number,
    page: number,
    take: number,
    filter: any
  ) {
    return client
      .post(`file-session/${type}/${id}?page=${page}&take=${take}`, filter)
      .then(({ data }) => data);
  }

  static getUserFileSession(
    type: "audio" | "article",
    id: number,
    page: number,
    take: number,
    filter: any
  ) {
    return client
      .post(`file-session/${type}/user/${id}?page=${page}&take=${take}`, filter)
      .then(({ data }) => data);
  }

  static getLogsExport(type: "audio" | "article", id: number) {
    return client
      .get(`file-session/export/${type}/${id}`, {
        responseType: "blob"
      })
      .then((resp) => {
        return resp.data;
      });
  }

  static getUserLogsExport(type: "audio" | "article", id: number) {
    return client
      .get(`file-session/export/${type}/user/${id}`, {
        responseType: "blob"
      })
      .then((resp) => {
        return resp.data;
      });
  }
}

export default FileAPI;
