import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { AuthAPI } from "api";

import { IAdmin } from "interfaces";

export const fetchCurrentAdmin = createAsyncThunk(
  "app/fetchCurrentAdmin",
  async () => {
    const { admin } = await AuthAPI.getCurrentAdmin();
    return admin;
  }
);

export interface AppState {
  user: null | IAdmin;
  fetchCurrentAdminStatus: "idle" | "pending" | "fulfilled" | "rejected";

  loading: boolean;
}

const initialState: AppState = {
  user: null,
  fetchCurrentAdminStatus: "idle",

  loading: false
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUser: (state, action: PayloadAction<IAdmin | null>) => {
      state.fetchCurrentAdminStatus = "fulfilled";
      state.user = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentAdmin.fulfilled, (state, action) => {
        state.user = action.payload;
        state.fetchCurrentAdminStatus = "fulfilled";
      })
      .addCase(fetchCurrentAdmin.pending, (state) => {
        state.fetchCurrentAdminStatus = "pending";
      })
      .addCase(fetchCurrentAdmin.rejected, (state) => {
        state.fetchCurrentAdminStatus = "rejected";
      });
  }
});

export const { toggleLoading, setUser } = appSlice.actions;

export default appSlice.reducer;
