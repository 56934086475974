import client from "./client";

import { IAudio } from "interfaces";

class AudioAPI {
  static createAudio(audio: IAudio & { id: undefined }) {
    return client.post("audio", audio).then(({ data }) => data);
  }

  static getAudios(page: number, take: number, filter: any) {
    return client
      .post(`audio/all?page=${page}&take=${take}`, filter)
      .then(({ data }) => data);
  }

  static getAudio(id: number) {
    return client.get(`audio/${id}`).then(({ data }) => data);
  }

  static editAudio(audio: any, id: number) {
    return client.patch(`audio/${id}`, audio).then(({ data }) => data);
  }

  static deleteAudio(id: number) {
    return client.delete(`audio/${id}`).then(({ data }) => data);
  }

  static checkDateAudio(programDate: string, category: string) {
    return client
      .get(`audio/check?programDate=${programDate}&category=${category}`)
      .then(({ data }) => data);
  }

  static getAudioExport() {
    return client
      .get(`audio/export`, {
        responseType: "blob"
      })
      .then((resp) => {
        return resp.data;
      });
  }
}

export default AudioAPI;
