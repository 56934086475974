import { useState, useEffect, useMemo } from "react";

import { FileAPI } from "api";

function useAudioFileDetails(file: File | string | null) {
  const [fileSize, setFileSize] = useState<number | null>(null);
  const [duration, setDuration] = useState<number | null>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    fetchFile();
  }, [file]);

  const fetchFile = async () => {
    try {
      if (file) {
        setIsLoading(true);

        if (typeof file === "string") {
          // Remote file
          const { links } = await FileAPI.getFileAsLink("audio", [file]);
          const [link] = links;

          setUrl(link.downloadLink);

          fetch(link.downloadLink, {
            method: "GET"
          }).then((response) => {
            const contentLength = response.headers.get("Content-Length");
            if (contentLength) {
              setFileSize(parseInt(contentLength));
            }
          });

          getDuration(link.downloadLink, (dur) => setDuration(dur));
        } else {
          // Local file
          setFileSize(file.size);

          const blob = new Blob([file], {
            type: file.type
          });
          const audioElement = new Audio(URL.createObjectURL(blob));
          setUrl(URL.createObjectURL(blob));

          audioElement.addEventListener("loadedmetadata", () => {
            setDuration(audioElement.duration);
          });
        }
      }
    } catch (e) {
      alert("Error");
    } finally {
      setIsLoading(false);
    }
  };

  const getDuration = (src: string, cb: (duration: number) => void) => {
    const audio = new Audio();
    audio.addEventListener("loadedmetadata", function () {
      cb(audio.duration);
    });
    audio.src = src;
  };

  const formatFileSize = useMemo(() => {
    if (fileSize === null) return "-";

    if (fileSize < 1024) {
      return fileSize + " B";
    } else if (fileSize < 1024 * 1024) {
      return (fileSize / 1024).toFixed(2) + " KB";
    } else if (fileSize < 1024 * 1024 * 1024) {
      return (fileSize / (1024 * 1024)).toFixed(2) + " MB";
    } else {
      return (fileSize / (1024 * 1024 * 1024)).toFixed(2) + " GB";
    }
  }, [fileSize]);

  return {
    fileSize,
    duration,
    formatFileSize,
    isLoading,
    url
  };
}

export default useAudioFileDetails;
