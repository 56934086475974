import { useState } from "react";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";

const useSearchParams = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<number>(() => {
    const query = new URLSearchParams(search);

    if (query.get("tab")) return Number(query.get("tab"));

    return 0;
  });

  const handleChange = (newTabIndex: number) => {
    setCurrentTab(newTabIndex);
    navigate(
      {
        pathname: "",
        search:
          newTabIndex !== 0
            ? createSearchParams({
                tab: newTabIndex.toString()
              }).toString()
            : undefined
      },
      { replace: true }
    );
  };

  return { currentTab, onChange: handleChange };
};

export default useSearchParams;
