import { FC } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";

import { ArticleAPI } from "api";

import { Options } from "const";

import {
  Checkbox,
  Input,
  Label,
  ModalWrapper,
  Select,
  Textarea,
  ArticleEditor,
  Button,
  Error
} from "components";

import { ArticleService, AudioService } from "utils";
import { toggleLoading } from "store/slices/app";

import { VariantButton } from "enums";
import { IArticle } from "interfaces";

const Schema = yup.object().shape({
  title: yup
    .string()
    .required("This field is required")
    .min(1, "Minimum characters: 1")
    .max(60, "Maximum characters: 60"),
  note: yup.string().max(100, "Maximum characters: 100"),
  text: yup
    .string()
    .required("This field is required")
    .test("htmlLength", "Minimum characters: 1", (value) => {
      const strippedHtml = value.replace(/<\/?[^>]+(>|$)/g, "");
      return strippedHtml.trim().length >= 1;
    })
});

const validateArticle = async (values: any) => {
  try {
    await Schema.validate(values, {
      abortEarly: false
    });
    const programDate = AudioService.validateProgramDate(values);

    if (programDate) {
      return { programDate };
    }
  } catch (error: any) {
    const errors: any = {};
    error.inner.forEach((e: any) => {
      errors[e.path] = e.message;
    });
    const programDate = AudioService.validateProgramDate(values);
    if (programDate) {
      errors.programDate = programDate;
    }

    return errors;
  }
};

type Props = {
  payload: IArticle | false;
  onCreateUpdateArticle: (values: any) => void;
  onClose: () => void;
};

const AddArticleModal: FC<Props> = ({
  payload,
  onClose,
  onCreateUpdateArticle
}) => {
  const dispatch = useDispatch();

  const {
    values,
    errors,
    isValid,
    touched,
    setFieldTouched,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: {
      id: payload && payload.id,
      title: (payload && payload.title) || "",
      week: payload
        ? payload.programDate
          ? AudioService.splitProgrammDate(payload.programDate)?.[0] || null
          : null
        : null,
      day: payload
        ? payload.programDate
          ? AudioService.splitProgrammDate(payload.programDate)?.[1] || null
          : null
        : null,
      note: payload ? payload.note || "" : "",
      text: (payload && payload.text) || ""
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validate: validateArticle,
    onSubmit: onSubmit
  });

  async function onSubmit() {
    try {
      const { day, week, ...other } = values;
      dispatch(toggleLoading(true));

      const { success }: any = await ArticleAPI.checkDateArticle(
        `${week}${day}`
      );

      if (!other.id || (payload && payload?.programDate !== `${week}${day}`)) {
        if (success) {
          toast(`An article is already active for ${`${week}${day}`}`, {
            type: "error"
          });

          dispatch(toggleLoading(false));
          return;
        }
      }

      const data: any = {
        ...other,
        note: other.note.trim() === "" ? null : other.note.trim(),
        programDate: `${week}${day}`,
        wordCount: ArticleService.countWords(other.text)
      };

      onCreateUpdateArticle(data);
    } catch (e) {
      alert("Error");
    }
  }

  const handleChangeArticle = (value: string) => {
    setFieldTouched("text");
    setFieldValue("text", value);
  };

  return (
    <ModalWrapper
      title={values.id ? "Edit Article" : "Add New Article"}
      isOpen={!!payload}
      onClose={onClose}>
      <div className={styles.wrapper_field}>
        <Input
          value={values.title}
          label="Title"
          placeholder="Enter title"
          maxLength={60}
          error={touched.title && errors.title ? errors.title : undefined}
          onChange={(val) => {
            if (/^[^\u0400-\u04FF]*$/.test(val)) {
              setFieldTouched("title");
              setFieldValue("title", val);
            }
          }}
        />
      </div>

      <div className={styles.wrapper_field}>
        <div className={styles.wrapper_program}>
          <Label text="Program Stage" />

          <div className={styles.wrapper_program_container}>
            <div className={styles.wrapper_program_container_item}>
              <Select
                value={values.week}
                options={Options.Week}
                placeholder="W1"
                onChange={(option) => {
                  setFieldTouched("week");
                  setFieldValue("week", option.value);
                }}
              />
            </div>
            <div className={styles.wrapper_program_container_item}>
              <Select
                value={values.day}
                options={Options.Day}
                placeholder="D1"
                onChange={(option) => {
                  setFieldTouched("day");
                  setFieldValue("day", option.value);
                }}
              />
            </div>
            <div>
              <Checkbox
                value={AudioService.isMaintenanceStage(
                  !values.week ? null : `${values.week}`
                )}
                label="Maintenance"
                readOnly
              />
            </div>
          </div>

          {
            //@ts-ignore
            touched.week &&
            touched.day &&
            //@ts-ignore
            errors.programDate ? (
              //@ts-ignore
              <Error message={errors.programDate} />
            ) : null
          }
        </div>
      </div>

      <div className={styles.wrapper_field}>
        <Textarea
          value={values.note}
          label="Note (optional)"
          placeholder="Enter note"
          maxLength={100}
          error={touched.note && errors.note ? errors.note : undefined}
          onChange={(val) => {
            if (/^[^\u0400-\u04FF]*$/.test(val)) {
              setFieldTouched("note");
              setFieldValue("note", val);
            }
          }}
        />
      </div>

      <div className={styles.wrapper_field}>
        <ArticleEditor
          label="Article Text"
          value={values.text}
          error={touched.text && errors.text ? errors.text : undefined}
          onChange={handleChangeArticle}
        />
      </div>

      <div className={styles.wrapper_buttons}>
        <div className={styles.wrapper_buttons_item}>
          <Button
            variant={VariantButton.Transparent}
            title="Cancel"
            onClick={onClose}
          />
        </div>
        <div className={styles.wrapper_buttons_item}>
          <Button title="Save" disable={!isValid} onClick={handleSubmit} />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AddArticleModal;
