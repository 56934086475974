import { FC, useState } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";

import { Label, Error } from "components";

type Props = {
  value: string;
  label: string;
  error?: string;
  disabled?: boolean;
  isSucure?: boolean;
  placeholder?: string;
  maxLength?: number;
  onChange: (value: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

const Input: FC<Props> = ({
  value,
  placeholder,
  label,
  isSucure = false,
  disabled = false,
  error,
  maxLength,
  onChange,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(!isSucure);

  const handleChange = (newVal: string) => {
    if (maxLength && newVal.trim().length > maxLength) return;
    onChange(newVal);
  };

  return (
    <div className={styles.wrapper}>
      <Label text={label} />

      <div className={styles.wrapper_input_wrapper}>
        <input
          value={value}
          placeholder={placeholder}
          className={cls(styles.wrapper_input, {
            [styles.wrapper_input_focused]: isFocused,
            [styles.wrapper_input_hide]: isSucure,
            [styles.wrapper_input_error]: !!error
          })}
          type={showPassword ? "text" : "password"}
          disabled={disabled}
          autoComplete="new-password"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={(e) => handleChange(e.target.value)}
          {...rest}
        />

        {isSucure && (
          <div
            className={styles.wrapper_input_secure}
            onClick={() => setShowPassword(!showPassword)}>
            <p>{showPassword ? "Hide" : "Show"}</p>
          </div>
        )}
      </div>

      {error && <Error message={error} />}
    </div>
  );
};

export default Input;
