import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";

import { ArticleAPI } from "api";

import { ICONS } from "assets";

import {
  Button,
  ConfirmModal,
  DotSelector,
  EmptyListTitle,
  Header,
  Pagination,
  SearchInput,
  TableHead,
  AddArticleModal,
  Status,
  TableLoading
} from "components";
import Filt from "./Filter";

import { toggleLoading } from "store/slices/app";
import { usePagination } from "hooks";
import { ArticleService } from "utils";
import { withRoleAuthorization } from "hoc";

import { AdminRole, OrderBy, VariantButton } from "enums";
import { IArticle } from "interfaces";

const LIMIT = 10;

const Articles: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [createUpdateArticlePayload, setCreateUpdateArticlePayload] = useState<
    any | false
  >(false);
  const [deleteModalPayload, setDeleteModalPayload] = useState<null | IArticle>(
    null
  );
  const [activateDeactivateModal, setActivateDeactivateModal] =
    useState<null | IArticle>(null);

  const fetchData = async (page: number, take: number, filter: any) => {
    const resp = await ArticleAPI.getArticles(page, take, filter);
    return resp;
  };

  const {
    isLoading,
    data,
    page,
    filter,
    onChangePage,
    onChangeFilter,
    onRefresh,
    onChangeSearch
  } = usePagination<
    {
      data: Array<IArticle>;
      meta: { itemsCount: number };
    },
    {
      sorting: {
        sortBy: any | null;
        order: OrderBy | null;
      } | null;
      search: string | null;
      dateRange: {
        from: Date | null;
        to: Date | null;
      } | null;
    }
  >(fetchData, LIMIT);

  const handleCreateUpdateArticle = async (values: any) => {
    try {
      dispatch(toggleLoading(true));

      if (!values.id) {
        // create article
        await ArticleAPI.createArticle(values);
      } else {
        // edit article
        const { id, ...other } = values;
        await ArticleAPI.editArticle(other, id);
      }
      await onRefresh();
      toast(
        values.id
          ? "The article has been updated."
          : "The article has been created.",
        {
          type: "success"
        }
      );
      setCreateUpdateArticlePayload(false);
    } catch (e: any) {
      toast(e.response.data.message, {
        type: "error"
      });
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const toggleDeactivated = async () => {
    try {
      if (!activateDeactivateModal) return;

      dispatch(toggleLoading(true));
      await ArticleAPI.editArticle(
        {
          status: !activateDeactivateModal.status
        },
        activateDeactivateModal.id
      );
      await onRefresh();
      toast(
        !activateDeactivateModal.status
          ? "Activated successfully"
          : "Deactivated successfully",
        {
          type: "success",
          icon: !activateDeactivateModal.status ? true : <ICONS.Minus />
        }
      );

      setActivateDeactivateModal(null);
    } catch (e) {
      alert("Error");
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const handleDeleteArticle = async () => {
    try {
      if (deleteModalPayload === null) return;
      dispatch(toggleLoading(true));

      await ArticleAPI.deleteArticle(deleteModalPayload.id);
      await onRefresh();
      setDeleteModalPayload(null);
    } catch (e) {
      alert("Error");
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const exportTable = async () => {
    try {
      dispatch(toggleLoading(true));
      const response = await ArticleAPI.getArticleExport();
      const blob = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${moment().format(
        "YYYY-MM-DDT_hh_mm_ss"
      )}_articles.xlsx`;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (e: any) {
      toast(e.response.data.message, {
        type: "error"
      });
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const renderArticles = () => {
    if (isLoading) return <TableLoading />;

    if (!data?.data) return null;

    if (data.data.length === 0)
      return <EmptyListTitle title="No articles yet" />;

    return data.data.map((article, index) => (
      <div
        key={article.id}
        className={styles.wrapper_item}
        onClick={() => navigate(`${article.id}/logs`)}>
        <div className={styles.wrapper_item_id}>{article.id}</div>

        <div className={styles.wrapper_item_info}>
          <p className={styles.wrapper_item_info_name}>{article.title}</p>
        </div>

        <div>{article.programDate}</div>

        <div>{moment(article.createdAt).format("MM/DD/YYYY")}</div>

        <div>{ArticleService.formatNumberWithCommas(article.wordCount)}</div>

        <div className={styles.wrapper_item_note}>{article.note || "-"}</div>

        <div className={styles.wrapper_item_status}>
          <Status status={article.status} />
        </div>

        <div>{article.readersCount}</div>

        <div
          className={styles.wrapper_item_dots}
          style={{
            zIndex: data.data.length - index
          }}>
          <DotSelector
            items={[
              {
                label: "Review logs",
                icon: <ICONS.ReviewLogs />,
                onClick: () => navigate(`${article.id}/logs?tab=1`) // tab 1 for redirect to logs
              },
              {
                label: "Edit",
                icon: <ICONS.Edit />,
                onClick: () => setCreateUpdateArticlePayload(article)
              },
              {
                label: article.status ? "Deactivate" : "Activate",
                icon: <ICONS.Deactivate />,
                onClick: () => setActivateDeactivateModal(article)
              },
              {
                label: "Delete",
                icon: <ICONS.Delete />,
                textStyle: {
                  color: "#FF0033"
                },
                style: {
                  borderTop: "1px solid #E6E6E9"
                },
                onClick: () => setDeleteModalPayload(article)
              }
            ]}
          />
        </div>
      </div>
    ));
  };

  return (
    <div>
      <Header
        title="Articles"
        helper={data?.meta ? `(${data.meta.itemsCount})` : undefined}
        buttons={[
          <Button
            title="Add Article"
            icon={<ICONS.Plus />}
            onClick={() => setCreateUpdateArticlePayload({})}
          />
        ]}
      />

      <div className="shadow_box">
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_search}>
            <div className={styles.wrapper_header_search_wrapper}>
              <SearchInput
                value={filter?.search || ""}
                onChange={(search) => {
                  onChangeSearch(search.trim() === "" ? null : search);
                }}
              />
            </div>
          </div>

          <div className={styles.wrapper_header_button}>
            <Button
              title="Export XLSX"
              variant={VariantButton.Transparent}
              icon={<ICONS.ExportFile />}
              onClick={exportTable}
            />
          </div>

          <div>
            <Filt
              filter={filter}
              onChange={(newFilter) =>
                onChangeFilter({
                  ...filter,
                  ...newFilter
                })
              }
              onClear={() =>
                //@ts-ignore
                onChangeFilter({
                  dateRange: null
                })
              }
            />
          </div>
        </header>

        <TableHead
          containerStyle={{
            paddingRight: "24px"
          }}
          items={[
            { label: "ID", sortedBy: "id" },
            { label: "Title", sortedBy: "title" },
            {
              label: "PD",
              sortedBy: "programDate",
              popupText: "Program Date"
            },
            {
              label: "UD",
              sortedBy: "createdAt",
              popupText: "Upload Date"
            },
            {
              label: "WC",
              sortedBy: "wordCount",
              popupText: "Word Count"
            },
            { label: "Note" },
            {
              label: "ST",
              sortedBy: "status",
              style: { justifyContent: "center" },
              popupText: "Status"
            },
            {
              label: "NOR",
              sortedBy: "readersCount",
              popupText: "Number of Readers"
            }
          ]}
          flexes={[1, 2, 1, 1, 1, 2, 1, 1]}
          sortOptions={
            filter?.sorting || {
              sortBy: null,
              order: null
            }
          }
          onChangeSorted={(sorting) => onChangeFilter({ ...filter, sorting })}
        />

        {renderArticles()}

        {data?.meta.itemsCount && data?.meta.itemsCount !== 0 ? (
          <div className={styles.wrapper_pagination}>
            <Pagination
              count={data.meta.itemsCount}
              limit={LIMIT}
              currentPage={page}
              onChange={(page) => onChangePage(page)}
            />
          </div>
        ) : null}
      </div>

      {!!createUpdateArticlePayload && (
        <AddArticleModal
          payload={createUpdateArticlePayload}
          onClose={() => setCreateUpdateArticlePayload(false)}
          onCreateUpdateArticle={handleCreateUpdateArticle}
        />
      )}

      <ConfirmModal
        isOpen={!!activateDeactivateModal}
        title={`${
          activateDeactivateModal?.status
            ? "Deactivate Article"
            : "Activate Article"
        }`}
        leftBtnText="No"
        rightBtnText="Yes"
        onClose={() => setActivateDeactivateModal(null)}
        onConfirm={toggleDeactivated}
      />

      <ConfirmModal
        isOpen={!!deleteModalPayload}
        title={`Are you sure you want to delete ${deleteModalPayload?.title}`}
        onClose={() => setDeleteModalPayload(null)}
        onConfirm={handleDeleteArticle}
      />
    </div>
  );
};

export default withRoleAuthorization([AdminRole.Admin])(Articles);
