import { FC, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import styles from "./styles.module.scss";

import { ICONS } from "../../assets";

import { setAudio, togglePlay as togglePlayStore } from "store/slices/player";

import { RootState } from "store";
import { useAudioFileDetails } from "hooks";
import { AudioService } from "utils";

const AudioPlayer: FC = () => {
  const dispatch = useDispatch<any>();
  const { audio, isPlaying } = useSelector((store: RootState) => store.player);
  const { duration, isLoading, url } = useAudioFileDetails(
    //@ts-ignore
    audio?.url || audio || null
  );

  useEffect(() => {
    if (isPlaying) {
      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
    }
  }, [isPlaying]);

  const [currentTime, setCurrentTime] = useState<number>(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const updateTime = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.play();
    }
  };

  const seek = (val: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime = val;
    }
  };

  return (
    <div className={styles.wrapper}>
      {url && !isLoading && (
        <audio
          ref={audioRef}
          onTimeUpdate={updateTime}
          onEnded={() => dispatch(togglePlayStore(false))}
          onPlay={() => dispatch(togglePlayStore(true))}
          onPause={() => dispatch(togglePlayStore(false))}
          controls
          autoPlay
          style={{ display: "none" }}
        >
          <source src={url} />
          Your browser does not support the audio element.
        </audio>
      )}
      <div
        className={styles.wrapper_cross}
        onClick={() => dispatch(setAudio(null))}
      >
        <ICONS.Close />
      </div>
      <div className={styles.wrapper_container}>
        <div className={styles.wrapper_container_button} onClick={togglePlay}>
          {isPlaying ? <ICONS.Pause /> : <ICONS.Play />}
        </div>
        <div className={styles.wrapper_container_audio}>
          <p className={styles.wrapper_container_audio_name}>
            {audio?.name || audio?.title}
          </p>
          <Slider
            value={currentTime}
            max={duration || 0}
            styles={{
              track: {
                backgroundColor: "#2F4872"
              },
              rail: {
                backgroundColor: "#B2C2DB"
              },
              handle: {
                opacity: 0
              }
            }}
            onChange={(number) => {
              // @ts-ignore
              seek(number);
            }}
          />
          <div className={styles.wrapper_container_audio_footer}>
            <p className={styles.wrapper_container_audio_footer_value}>
              {AudioService.formatSecondsToMinutesAndSeconds(currentTime || 0)}
            </p>
            <p className={styles.wrapper_container_audio_footer_value}>
              {AudioService.formatSecondsToMinutesAndSeconds(duration || 0)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
