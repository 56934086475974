import Wrapper from "./Wrapper";
import Container from "./Container";
import DateRange from "./DateRange";

const Components = {
  Wrapper,
  Container,
  DateRange
};

export default Components;
