import React, { FC } from "react";
import cls from "classnames";
import ClipLoader from "react-spinners/ClipLoader";
import styles from "./styles.module.scss";

import { VariantButton } from "enums";

type Props = {
  title: string;
  disable?: boolean;
  loading?: boolean;
  variant?: VariantButton;
  icon?: React.ReactNode;
  onClick: () => void;
};

const Button: FC<Props> = ({
  title,
  disable = false,
  loading = false,
  variant = VariantButton.General,
  icon,
  onClick
}) => {
  return (
    <button
      className={cls(styles.button, {
        [styles.button_transparent]: variant === VariantButton.Transparent,
        [styles.button_red]: variant === VariantButton.Red,
        [styles.button_red_transparent]:
          variant === VariantButton.RedTransparent,
        [styles.button_green_transparent]:
          variant === VariantButton.GreenTransparent,
        [styles.button_disable]: disable
      })}
      disabled={disable}
      onClick={onClick}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      {loading ? <ClipLoader color="white" size={15} /> : <p>{title}</p>}
    </button>
  );
};

export default Button;
