import { FC } from "react";
import styles from "./styles.module.scss";

type Props = {
  text: string;
  subTitle?: string;
};

const Label: FC<Props> = ({ text, subTitle }) => (
  <div className={styles.wrapper}>
    <p className={styles.label}>{text}</p>
    {subTitle && <p className={styles.subtitle}>{subTitle}</p>}
  </div>
);

export default Label;
