import { FC } from "react";
import { useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import styles from "./styles.module.scss";

import { RootState } from "store";

const MainLoading: FC = () => {
  const { loading } = useSelector((store: RootState) => store.app);

  if (!loading) return null;

  return (
    <div
      className={styles.wrapper}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        zIndex: 2100
      }}
    >
      <BeatLoader color="white" />
    </div>
  );
};

export default MainLoading;
