import { FC } from "react";
import Modal from "react-modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "./styles.module.scss";

import { Button, Input } from "components";

import { VariantButton } from "enums";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "40%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "24px"
  },
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 1010
  }
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required(
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .min(
      8,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .max(
      30,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .matches(
      /[a-z]/,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .matches(
      /[A-Z]/,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .matches(
      /\d/,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .matches(
      /[~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]/,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    ),
  repeatPassword: Yup.string()
    //@ts-ignore
    .oneOf([Yup.ref("password"), null], "Passwords do not match! Try again.")
    .required("Repeat password is required")
});

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  onChangePassword: (password: string) => void;
  onSkip: () => void;
};

const ChangePasswordModal: FC<Props> = ({
  isOpen,
  isLoading,
  onChangePassword,
  onSkip
}) => {
  const {
    values,
    errors,
    isValid,
    touched,
    setFieldTouched,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: {
      password: "",
      repeatPassword: ""
    },
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: handleChangePassword
  });

  const handleSkip = () => {
    onSkip();
  };

  async function handleChangePassword() {
    onChangePassword(values.password);
  }

  return (
    <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false}>
      <div className={styles.wrapper}>
        <h3 className={styles.wrapper_title}>Change Password</h3>

        <div className={styles.wrapper_input}>
          <Input
            value={values.password}
            label="New Password"
            isSucure
            error={
              touched.password && errors.password ? errors.password : undefined
            }
            onChange={(val) => {
              if (val.length > 30) return;

              setFieldTouched("password");
              setFieldValue("password", val);
            }}
          />
        </div>
        <div className={styles.wrapper_input}>
          <Input
            value={values.repeatPassword}
            label="Repeat new Password"
            isSucure
            error={
              touched.repeatPassword && errors.repeatPassword
                ? errors.repeatPassword
                : undefined
            }
            onChange={(val) => {
              if (val.length > 30) return;

              setFieldTouched("repeatPassword");
              setFieldValue("repeatPassword", val);
            }}
          />
        </div>

        <div className={styles.wrapper_buttons}>
          <div className={styles.wrapper_buttons_item}>
            <Button
              title="Skip"
              variant={VariantButton.Transparent}
              onClick={handleSkip}
            />
          </div>
          <div className={styles.wrapper_buttons_item}>
            <Button
              title="Change Password"
              disable={!isValid}
              loading={isLoading}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
