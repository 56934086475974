import { FC } from "react";
import moment from "moment";
import styles from "./styles.module.scss";

import {
  ArrowSelect,
  Checkbox,
  DatePicker,
  Label,
  NumberSelect
} from "components";

type Value = Date | null;

type Props = {
  startDate: any;
  endDate: any;
  publishNow: boolean;
  onChange: (values: any) => void;
};

const DateRange: FC<Props> = ({ startDate, endDate, publishNow, onChange }) => {
  const handleChangeDate = (date: Value, key: "startDate" | "endDate") => {
    if (key === "startDate") {
      onChange({
        startDate: date,
        publishNow: false
      });
    } else {
      onChange({
        endDate: date
      });
    }
  };

  const handleTogglePublish = () => {
    const isPublish = !publishNow;
    const data: any = { publishNow: isPublish };

    if (isPublish) {
      const currenDate = moment();

      data.startDate = currenDate.toDate();
      data.startHours = currenDate.format("h");
      data.startMins = currenDate.format("m");
      data.startPmAm = currenDate.format("A");

      if (!endDate) {
        const datePlusDay = currenDate.add(1, "day");

        data.endDate = datePlusDay.toDate();
        data.endHours = datePlusDay.format("h");
        data.endMins = datePlusDay.format("m");
        data.endPmAm = datePlusDay.format("A");
      }
    }

    onChange(data);
  };

  return (
    <div>
      <div className={styles.wrapper_scheduling_wrapper}>
        <div className={styles.wrapper_scheduling}>
          <div className={styles.wrapper_scheduling_date}>
            <DatePicker
              value={startDate.date}
              placeholder="Date"
              label="Start date"
              disableDateBefore={moment().toDate()}
              onChange={(date) => handleChangeDate(date, "startDate")}
            />
          </div>
          <div className={styles.wrapper_scheduling_time}>
            <Label text="Start time" />
            <div className={styles.wrapper_scheduling_time_wrapper}>
              <div className={styles.wrapper_scheduling_time_wrapper_item}>
                <NumberSelect
                  value={startDate.hours || null}
                  placeholder="H"
                  min={1}
                  max={12}
                  step={1}
                  onChange={(date) => {
                    onChange({
                      startHours: date,
                      publishNow: false
                    });
                  }}
                />
              </div>

              <div className={styles.wrapper_scheduling_time_wrapper_item}>
                <NumberSelect
                  value={startDate.mins || null}
                  placeholder="M"
                  min={0}
                  max={59}
                  step={1}
                  onChange={(date) => {
                    onChange({
                      startMins: date,
                      publishNow: false
                    });
                  }}
                />
              </div>

              <div className={styles.wrapper_scheduling_time_wrapper_item}>
                <ArrowSelect
                  value={startDate.pmAm || null}
                  items={[
                    { value: "PM", label: "PM" },
                    { value: "AM", label: "AM" }
                  ]}
                  placeholder="AM/PM"
                  onChange={(date) => {
                    onChange({
                      startPmAm: date,
                      publishNow: false
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.wrapper_checkbox}>
          <Checkbox
            value={publishNow}
            label="Publish now"
            onChange={handleTogglePublish}
          />
        </div>

        <div className={styles.wrapper_scheduling}>
          <div className={styles.wrapper_scheduling_date}>
            <DatePicker
              value={endDate.date}
              placeholder="Date"
              label="End date"
              disableDateBefore={
                startDate.date
                  ? moment(startDate.date).toDate()
                  : moment().toDate()
              }
              onChange={(date) => handleChangeDate(date, "endDate")}
            />
          </div>
          <div className={styles.wrapper_scheduling_time}>
            <Label text="End time" />
            <div className={styles.wrapper_scheduling_time_wrapper}>
              <div className={styles.wrapper_scheduling_time_wrapper_item}>
                <NumberSelect
                  value={endDate.hours || null}
                  placeholder="H"
                  min={1}
                  max={12}
                  step={1}
                  onChange={(date) => {
                    onChange({ endHours: date });
                  }}
                />
              </div>

              <div className={styles.wrapper_scheduling_time_wrapper_item}>
                <NumberSelect
                  value={endDate.mins || null}
                  placeholder="M"
                  min={0}
                  max={59}
                  step={1}
                  onChange={(date) => {
                    onChange({ endMins: date });
                  }}
                />
              </div>

              <div className={styles.wrapper_scheduling_time_wrapper_item}>
                <ArrowSelect
                  value={endDate.pmAm}
                  items={[
                    { value: "PM", label: "PM" },
                    { value: "AM", label: "AM" }
                  ]}
                  placeholder="AM/PM"
                  onChange={(date) => {
                    onChange({ endPmAm: date });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRange;
