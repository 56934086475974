import { FC } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import styles from "./styles.module.scss";

import { ModalWrapper, Button, Input } from "components";

import { VariantButton } from "enums";

const UserSchema = yup.object({
  email: yup
    .string()
    .required(`Required`)
    .max(80, "Max size 80")
    .matches(/([a-z0-9._]{3,80})@([a-z]{3,80})\.[a-z]{2,3}/, `Incorrect email`),
  firstName: yup
    .string()
    .min(2, ({ min }) => `First name must be at least ${min} characters`)
    .max(20, ({ max }) => `First name must be less than ${max} characters`)
    .required()
});

type Props = {
  payload: any | false;
  onCreateUpdateUser: (values: any) => void;
  onClose: () => void;
};

const AddUserModal: FC<Props> = ({ payload, onClose, onCreateUpdateUser }) => {
  const {
    values,
    errors,
    isValid,
    touched,
    setFieldTouched,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: {
      id: payload.id || payload.ID,
      firstName: payload?.firstName || payload?.FN || "",
      email: payload?.email || payload?.Email || ""
    },
    validationSchema: UserSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: onSubmit
  });

  async function onSubmit() {
    onCreateUpdateUser(values);
  }

  return (
    <ModalWrapper
      title={payload.id ? "Edit User" : "Add New User"}
      isOpen={!!payload}
      onClose={onClose}
    >
      <div className={styles.wrapper_field}>
        <Input
          value={values.firstName}
          label="First Name"
          placeholder="Enter first name"
          maxLength={20}
          //@ts-ignore
          error={
            touched.firstName && errors.firstName ? errors.firstName : undefined
          }
          onChange={(val) => {
            if (/^[^\u0400-\u04FF]*$/.test(val)) {
              setFieldTouched("firstName");
              setFieldValue("firstName", val);
            }
          }}
        />
      </div>

      <div className={styles.wrapper_field}>
        <Input
          value={values.email}
          label="Email"
          placeholder="Enter email"
          maxLength={60}
          //@ts-ignore
          error={touched.email && errors.email ? errors.email : undefined}
          onChange={(val) => {
            if (/^[^\u0400-\u04FF]*$/.test(val)) {
              setFieldTouched("email");
              setFieldValue("email", val);
            }
          }}
        />
      </div>

      <div className={styles.wrapper_buttons}>
        <div className={styles.wrapper_buttons_item}>
          <Button
            variant={VariantButton.Transparent}
            title="Cancel"
            onClick={onClose}
          />
        </div>
        <div className={styles.wrapper_buttons_item}>
          <Button title="Save" disable={!isValid} onClick={handleSubmit} />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AddUserModal;
