import { ChangeEvent, FC, useRef } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import styles from "./styles.module.scss";

import { AdminAPI } from "api";

import { ModalWrapper, PrivateImage, Input, Select, Button } from "components";

import { toggleLoading } from "store/slices/app";

import { AdminRole } from "enums";

const validationSchema = Yup.object().shape({
  id: Yup.number().nullable(),
  password: Yup.string()
    .min(
      8,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .max(
      30,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .matches(
      /[a-z]/,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .matches(
      /[A-Z]/,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .matches(
      /\d/,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .matches(
      /[~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]/,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .when("id", (id, schema) => {
      if (id[0] === undefined) return schema.required("Required");
      return schema;
    }),
  email: Yup.string()
    .required(`Required`)
    .max(80, "Max size 80")
    .matches(/([a-z0-9._]{3,80})@([a-z]{3,80})\.[a-z]{2,3}/, `Incorrect email`),
  firstName: Yup.string()
    .required("This field is required")
    .min(2, "Minimum: 2 character")
    .max(20, "Maximum characters: 20"),
  lastName: Yup.string()
    .required("This field is required")
    .min(2, "Minimum: 2 character")
    .max(20, "Maximum characters: 20")
});

type Props = {
  payload: false | any;
  onClose: () => void;
  onCreateUpdateUser: (data: any) => void;
};

const AddStaffModal: FC<Props> = ({ payload, onClose, onCreateUpdateUser }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const {
    values,
    isValid,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleSubmit
  } = useFormik({
    initialValues: {
      id: payload?.id,
      firstName: payload?.firstName || "",
      lastName: payload?.lastName || "",
      email: payload?.email || "",
      password: "",
      role: payload?.role || AdminRole.Moderator,
      avatar: payload?.avatar || null
    },
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: handleCreateUser
  });

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (!fileInputRef.current) return;

    if (file && file.size > 5 * 1000 * 1000) {
      toast("File size exceeds 5 MB limit. Please choose a smaller file.", {
        type: "error"
      });

      fileInputRef.current.value = "";
      return;
    }

    if (
      file &&
      (file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png")
    ) {
      setFieldValue("avatar", file);
    }
  };

  async function handleCreateUser() {
    try {
      dispatch(toggleLoading(true));
      debugger;

      if (payload?.email !== values.email) {
        const { emailExist } = await AdminAPI.checkAdmin(values.email);

        if (emailExist) {
          toast(`User with ${values.email} email is already exist`, {
            type: "error"
          });

          dispatch(toggleLoading(false));
          return;
        }
      }

      const data = {
        id: values.id,
        avatar: typeof values.avatar !== "string" ? values.avatar : undefined,
        firstName:
          values.firstName !== payload?.firstName
            ? values.firstName
            : undefined,
        lastName:
          values.lastName !== payload?.lastName ? values.lastName : undefined,
        email: values.email !== payload?.email ? values.email : undefined,
        password: values.password || undefined,
        role: values.role !== payload?.role ? values.role : undefined
      };

      onCreateUpdateUser(data);
    } catch (e) {
      dispatch(toggleLoading(false));
    }
  }

  return (
    <ModalWrapper
      title={`${!payload.id ? "New Manager" : "Edit Manager"}`}
      isOpen={!!payload}
      onClose={onClose}>
      <div className={styles.wrapper_header}>
        <div
          className={styles.wrapper_header_wrapper}
          onClick={() => fileInputRef?.current?.click()}>
          <div className={styles.wrapper_header_wrapper_avatar}>
            <PrivateImage src={values?.avatar || null} type="avatar" />
          </div>
          <p className={styles.wrapper_header_wrapper_text}>Change photo</p>
        </div>
      </div>

      <div className={styles.wrapper_name}>
        <div className={styles.wrapper_name_item}>
          <Input
            value={values.firstName}
            label="First Name"
            maxLength={20}
            //@ts-ignore
            error={touched?.firstName && errors?.firstName && errors.firstName}
            onChange={(val) => {
              if (/^[^\u0400-\u04FF]*$/.test(val)) {
                setFieldTouched("firstName");
                setFieldValue("firstName", val);
              }
            }}
          />
        </div>
        <div className={styles.wrapper_name_item}>
          <Input
            value={values.lastName}
            label="Last Name"
            maxLength={20}
            //@ts-ignore
            error={touched?.lastName && errors?.lastName && errors.lastName}
            onChange={(val) => {
              if (/^[^\u0400-\u04FF]*$/.test(val)) {
                setFieldTouched("lastName");
                setFieldValue("lastName", val);
              }
            }}
          />
        </div>
      </div>

      <div className={styles.wrapper_field}>
        <Input
          value={values.email}
          label="Email"
          //@ts-ignore
          error={touched.email && errors.email ? errors.email : undefined}
          onChange={(val) => {
            setFieldTouched("email");
            setFieldValue("email", val);
          }}
        />
      </div>

      <div className={styles.wrapper_field}>
        <Input
          value={values.password}
          label="Password"
          isSucure
          error={
            touched.password && errors.password ? errors.password : undefined
          }
          onChange={(val) => {
            setFieldTouched("password");
            setFieldValue("password", val);
          }}
        />
      </div>
      <Select
        value={values.role}
        options={[
          {
            label: "Marketing manager",
            value: "moderator"
          },
          { label: "Admin", value: "admin" }
        ]}
        readOnly
        label="Role"
        placeholder="Select"
        position="top"
        onChange={(option) => setFieldValue("role", option.value)}
      />

      <div className={styles.wrapper_button}>
        <Button
          title={payload?.id ? "Edit" : "Create"}
          disable={!isValid}
          onClick={handleSubmit}
        />
      </div>

      <input
        ref={fileInputRef}
        type="file"
        accept=".jpg, .png"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </ModalWrapper>
  );
};

export default AddStaffModal;
