import axios from "axios";

import { API_URL } from "const";

import { TokenService } from "utils";

const apiClient = axios.create({
  baseURL: API_URL,
  timeout: 15000
});

apiClient.interceptors.request.use(async (config) => {
  const tokens = TokenService.getTokens();

  if (tokens && !config.headers.Authorization) {
    config.headers["Authorization"] = `Bearer ${tokens.accessToken}`;
  }

  return config;
});

export default apiClient;
