import { FC, useState, useRef } from "react";
import moment from "moment";
import cls from "classnames";
import styles from "./styles.module.scss";

import { Label } from "components";
import Calendar from "./Calendar";

import { useOutsideClick } from "hooks";
import { ICONS } from "assets";

type Props = {
  value: Date | null;
  label?: string | false;
  disable?: boolean;
  placeholder?: string;
  disableDateBefore?: Date;
  position?: "Left" | "Right";
  onChange: (value: Date | null) => void;
};

const DatePicker: FC<Props> = ({
  value,
  label,
  disable = false,
  placeholder = "",
  position = "Left",
  disableDateBefore,
  onChange
}) => {
  const [isOpenCalendar, setIsOpenCalendar] = useState<boolean>(false);
  const divRef = useRef<any>(null);
  useOutsideClick({
    ref: divRef,
    onClickOutside: () => setIsOpenCalendar(false)
  });

  return (
    <div ref={divRef} className={styles.container}>
      {label && <Label text={label} />}
      <div
        className={cls(styles.wrapper, {
          [styles.wrapper_active]: value || isOpenCalendar,
          [styles.wrapper_disable]: disable
        })}
        onClick={() => setIsOpenCalendar(!isOpenCalendar)}
      >
        <p
          className={cls(styles.wrapper_block_placeholder, {
            [styles.wrapper_block_title]: value
          })}
        >
          {value ? moment(value).format("MM/DD/YYYY") : placeholder}
        </p>
        <div className={`${styles.wrapper_block_icon}`}>
          <ICONS.SelectArrow />
        </div>
      </div>

      {isOpenCalendar && (
        <div
          className={styles.calendar}
          style={position === "Left" ? { left: 0 } : { right: 0 }}
        >
          <Calendar
            value={value}
            disableDateBefore={disableDateBefore}
            onChange={(date) => {
              onChange(date);
              setIsOpenCalendar(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default DatePicker;
