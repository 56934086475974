import axios from "axios";

export const uploadFileToStorage = async (
  file: File,
  url: string,
  contentType: string = "application/octet-stream"
) => {
  const response = await axios.put(url, file, {
    headers: {
      "Content-Type": contentType,
      "Access-Control-Allow-Origin": "*"
    }
  });

  if (response.status !== 200) {
    throw Error("Save file error");
  }

  return true;
};
