import { FC, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
//@ts-ignore
import quillEmoji from "quill-emoji";
import "quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import "./styles.css";
import styles from "./styles.module.scss";

import { Label, Error } from "components";
import { ArticleService } from "utils";

const ColorStyles = Quill.import("attributors/style/color");
const SizeStyle = Quill.import("attributors/style/size");
const DirectionAttribute = Quill.import("attributors/attribute/direction");
const AlignClass = Quill.import("attributors/style/align");

SizeStyle.whitelist = [
  "6px",
  "8px",
  "10px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
  "30px",
  "32px"
];

Quill.register(DirectionAttribute, true);
Quill.register(ColorStyles, true);
Quill.register(SizeStyle, true);
Quill.register(AlignClass, true);

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register(
  {
    "formats/emoji": EmojiBlot,
    "modules/emoji-shortname": ShortNameEmoji,
    "modules/emoji-toolbar": ToolbarEmoji,
    "modules/emoji-textarea": TextAreaEmoji
  },
  true
);

const toolbarOptions = {
  container: [
    [
      "emoji",
      "bold",
      "italic",
      "underline",
      {
        size: [
          "6px",
          "8px",
          "10px",
          "12px",
          "14px",
          "16px",
          "18px",
          "20px",
          "22px",
          "24px",
          "26px",
          "28px",
          "30px",
          "32px"
        ]
      },
      { color: [] },
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      "blockquote"
    ]
  ],
  handlers: { emoji: function () {} }
};

const DEFAULT_FORMATS = [
  "bold",
  "italic",
  "emoji",
  "color",
  "underline",
  "indent",
  "blockquote",
  "align",
  "list",
  "size"
];

const DEFAULT_MODULES = {
  // toolbar: { container: "#toolbar" },
  toolbar: toolbarOptions,
  history: { delay: 0 },
  "emoji-toolbar": true,
  "emoji-shortname": true
};

type Props = {
  value: string;
  label?: string;
  error?: string;
  maxLength?: number;
  height?: number;
  onChange: (content: string, length: number, currentText: string) => void;
};

const ArticleEditor: FC<Props> = ({
  value,
  label,
  error,
  maxLength = 15000,
  height = 350,
  onChange
}) => {
  const editorRef = useRef<any>(null);

  const handleChange = (value: string, _: any, __: any, editor: any) => {
    if (editorRef.current) {
      const myEditor = editorRef.current.getEditor();
      const text = ArticleService.removeTagsFromText(value);

      if (editor.getLength() > maxLength || !/^[^\u0400-\u04FF]*$/.test(text)) {
        myEditor.history.undo();
      } else {
        onChange(value, editor.getLength(), text);
      }
    }
  };

  return (
    <div className={styles.area}>
      {label && <Label text={label} />}

      <div className={styles.editor}>
        <ReactQuill
          ref={editorRef}
          value={value}
          theme="snow"
          modules={DEFAULT_MODULES}
          formats={DEFAULT_FORMATS}
          style={{ height: height + "px" }}
          onChange={handleChange}
        />
      </div>
      {error && <Error message={error} />}
    </div>
  );
};

export default ArticleEditor;
