import { FC } from "react";
import moment from "moment";
import styles from "./styles.module.scss";

import { Status } from "components";

type Props = {
  user: any;
};

const MainInfo: FC<Props> = ({ user }) => {
  return (
    <div className={styles.wrapper_info}>
      <div className={styles.wrapper_info_item}>
        <p className={styles.wrapper_info_item_title}>User ID</p>
        <p className={styles.wrapper_info_item_value}>{user.ID}</p>
      </div>
      <div className={styles.wrapper_info_item}>
        <p className={styles.wrapper_info_item_title}>Payment Source</p>
        <p className={styles.wrapper_info_item_value}>{user.Source || "-"}</p>
      </div>
      <div className={styles.wrapper_info_item}>
        <p className={styles.wrapper_info_item_title}>Email</p>
        <p className={styles.wrapper_info_item_value}>{user.Email}</p>
      </div>
      <div className={styles.wrapper_info_item}>
        <p className={styles.wrapper_info_item_title}>Sign In Date</p>
        <p className={styles.wrapper_info_item_value}>
          {user.SID ? moment(user.SID).format("MM/DD/YYYY") : "-"}
        </p>
      </div>
      <div className={styles.wrapper_info_item}>
        <p className={styles.wrapper_info_item_title}>Last Audio Date</p>
        <p className={styles.wrapper_info_item_value}>
          {user.LAD ? moment(user.LAD).format("MM/DD/YYYY") : "-"}
        </p>
      </div>
      <div className={styles.wrapper_info_item}>
        <p className={styles.wrapper_info_item_title}>Last Sleep Audio Date</p>
        <p className={styles.wrapper_info_item_value}>
          {user.LSD ? moment(user.LSD).format("MM/DD/YYYY") : "-"}
        </p>
      </div>
      <div className={styles.wrapper_info_item}>
        <p className={styles.wrapper_info_item_title}>Last Reading Date</p>
        <p className={styles.wrapper_info_item_value}>
          {user.LRD ? moment(user.LRD).format("MM/DD/YYYY") : "-"}
        </p>
      </div>
      <div className={styles.wrapper_info_item}>
        <p className={styles.wrapper_info_item_title}>Device OS</p>
        <p className={styles.wrapper_info_item_value}>
          {user.OS ? (user.OS === "ios" ? "iOS" : "Android") : "-"}{" "}
        </p>
      </div>
      <div className={styles.wrapper_info_item}>
        <p className={styles.wrapper_info_item_title}>Status</p>
        <p className={styles.wrapper_info_item_value}>
          <Status status={user.ST} />
        </p>
      </div>
      <div className={styles.wrapper_info_item}>
        <p className={styles.wrapper_info_item_title}>Active Subscription</p>
        <p className={styles.wrapper_info_item_value}>
          <Status status={user.AS} />
        </p>
      </div>
      <div className={styles.wrapper_info_item}>
        <p className={styles.wrapper_info_item_title}>Free Access End Date</p>
        <p className={styles.wrapper_info_item_value}>
          {user.FA ? moment(user.FA).format("MM/DD/YYYY") : "-"}
        </p>
      </div>
      <div className={styles.wrapper_info_item}>
        <p className={styles.wrapper_info_item_title}>
          Active Subscription End Date
        </p>
        <p className={styles.wrapper_info_item_value}>
          {user.SD ? moment(user.SD).format("MM/DD/YYYY") : "-"}
        </p>
      </div>
      <div className={styles.wrapper_info_item}>
        <p className={styles.wrapper_info_item_title}>Program Start Date</p>
        <p className={styles.wrapper_info_item_value}>
          {user.PSD ? moment(user.PSD).format("MM/DD/YYYY") : "-"}
        </p>
      </div>
      <div className={styles.wrapper_info_item}>
        <p className={styles.wrapper_info_item_title}>Completed Free Trial</p>
        <p className={styles.wrapper_info_item_value}>
          <Status status={user.CFTA} />
        </p>
      </div>
      <div className={styles.wrapper_info_item}>
        <p className={styles.wrapper_info_item_title}>Program Stage</p>
        <p className={styles.wrapper_info_item_value}>{user.Stage || "-"}</p>
      </div>
    </div>
  );
};

export default MainInfo;
