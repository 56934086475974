import { FC, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";

import { AdminAPI } from "api";

import { Button, Header, ConfirmModal } from "components";
import ChangePassword from "./ChangePassword";
import ChangeEmail from "./ChangeEmail";

import { TokenService } from "utils";
import { toggleLoading } from "store/slices/app";

import { AdminRole, VariantButton } from "enums";
import { RootState } from "store";
import { useUser } from "hooks";

const Settings: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { updateUser } = useUser();
  const [deactivateModal, setDeactivateModal] = useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.app);

  const handleDeleteAccount = async () => {
    try {
      dispatch(toggleLoading(true));
      await AdminAPI.deleteAccount();
      TokenService.clearTokens();
      updateUser(null);
      navigate("/login");
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  return (
    <div>
      <Header title="Settings" />

      <div className="shadow_box">
        <ChangePassword />
        <ChangeEmail />
        {user?.role === AdminRole.Moderator && (
          <footer>
            <h3 className={styles.wrapper_group_title}>Deactivate account</h3>
            <p className={styles.wrapper_footer_description}>
              You won’t be able to restore it back
            </p>
            <div className={styles.wrapper_footer_button}>
              <Button
                title="Deactivate my account"
                variant={VariantButton.Red}
                onClick={() => setDeactivateModal(true)}
              />
            </div>
          </footer>
        )}
      </div>

      <ConfirmModal
        isOpen={deactivateModal}
        title="Deactivate account?"
        onClose={() => setDeactivateModal(false)}
        onConfirm={handleDeleteAccount}
      />
    </div>
  );
};

export default Settings;
