import { FC } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

type Props = {
  value: boolean;
  label?: string;
  readOnly?: boolean;
  onChange?: () => void;
};

const Checkbox: FC<Props> = ({ value, label, readOnly = false, onChange }) => {
  return (
    <button
      className={styles.container}
      onClick={(e) => {
        e.stopPropagation();
        if (!readOnly && onChange) onChange();
      }}
    >
      <div
        className={cls(styles.wrapper, {
          [styles.wrapper_checked]: value
        })}
      >
        <ICONS.Checked />
      </div>

      {label && <p className={styles.label}>{label}</p>}
    </button>
  );
};

export default Checkbox;
