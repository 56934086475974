import React, { FC } from "react";
import styles from "./styles.module.scss";

type Props = {
  title: string;
  helper?: string;
  buttons?: Array<React.ReactNode>;
};

const Header: FC<Props> = ({ title, helper, buttons }) => {
  return (
    <header className={styles.header}>
      <div className={styles.header_left}>
        <h2 className={styles.header_left_title}>{title}</h2>

        {helper && <p className={styles.header_left_helper}>{helper}</p>}
      </div>

      {buttons && (
        <div className={styles.header_right}>
          {buttons.map((btn, index) => (
            <div key={index}>{btn}</div>
          ))}
        </div>
      )}
    </header>
  );
};

export default Header;
