import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";

import { AdminAPI, FileAPI } from "api";

import { ICONS } from "assets";

import {
  Button,
  ConfirmModal,
  DotSelector,
  EmptyListTitle,
  Header,
  Pagination,
  PrivateImage,
  SearchInput,
  TableHead,
  TableLoading
} from "components";
import AddStaffModal from "./AddStaffModal";

import { usePagination } from "hooks";
import { toggleLoading } from "store/slices/app";
import { AwsService } from "utils";

import { IAdmin } from "interfaces";
import { AdminRole, OrderBy } from "enums";

const LIMIT = 10;

const Staff: FC = () => {
  const dispatch = useDispatch();
  const [deleteModalPayload, setDeleteModalPayload] = useState<null | any>(
    null
  );
  const [addEditModalPayload, setAddEditModalPayload] = useState<null | any>(
    null
  );

  const fetchData = async (page: number, take: number, filter: any) => {
    const resp = await AdminAPI.getAdmins(page, take, filter);
    return resp;
  };

  const {
    isLoading,
    data,
    page,
    filter,
    onChangePage,
    onRefresh,
    onChangeFilter,
    onChangeSearch
  } = usePagination<
    {
      data: Array<IAdmin>;
      meta: { itemsCount: number };
    },
    {
      sorting: {
        sortBy: any | null;
        order: OrderBy | null;
      } | null;
      search: string | null;
    }
  >(fetchData, LIMIT);

  const handleCreateUpdateUser = async (data: any) => {
    try {
      dispatch(toggleLoading(true));
      debugger;

      let avatarUrl = typeof data.avatar === "string" ? data.avatar : "";

      if (
        typeof data.avatar !== "string" &&
        data.avatar !== null &&
        data.avatar !== undefined
      ) {
        const { links } = await FileAPI.getLinks("avatar", [data.avatar.name]);
        const { uploadLink, key } = links[0];
        await AwsService.uploadFileToStorage(data.avatar, uploadLink);
        avatarUrl = key;

        if (addEditModalPayload.avatar) {
          FileAPI.deleteFiles("avatar", [addEditModalPayload.avatar]);
        }
      }

      if (!data.id) {
        // create admin here
        const dataForSend = { ...data, avatar: avatarUrl || undefined };
        await AdminAPI.createAdmin(dataForSend);
      } else {
        // update admin here
        const { id, ...other } = data;
        await AdminAPI.updateAdmin(
          { ...other, avatar: avatarUrl || undefined },
          id
        );
      }

      await onRefresh();
      toast(
        `The ${
          data.role === AdminRole.Admin ? "admin" : "marketing manager"
        } has been ${data?.id ? "updated" : "created"}.`,
        {
          type: "success"
        }
      );
      setAddEditModalPayload(false);
    } catch (e: any) {
      if (e?.response?.data?.message) {
        toast(e.response.data.message, {
          type: "error"
        });
      }
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const handleDeleteAdmin = async () => {
    try {
      dispatch(toggleLoading(true));
      if (!deleteModalPayload) return;
      await AdminAPI.deleteAccountById(deleteModalPayload.id);
      await onRefresh();
      toast("Deleted successfully", { type: "success" });

      setDeleteModalPayload(false);
    } catch (e: any) {
      if (e?.response?.data?.message) {
        toast(e?.response?.data?.message, { type: "error" });
      }
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const renderAdmins = () => {
    if (isLoading) return <TableLoading />;

    if (!data?.data) return null;

    if (data.data.length === 0) return <EmptyListTitle title="No admins yet" />;

    return data.data.map((admin, index) => {
      return (
        <div key={index} className={styles.wrapper_item}>
          <div className={styles.wrapper_item_user}>
            <div className={styles.wrapper_item_user_avatar}>
              <PrivateImage src={admin.avatar} type="avatar" />
            </div>
            <p className={styles.wrapper_item_user_name}>
              {admin.firstName} {admin.lastName}
            </p>
          </div>
          <div className={styles.wrapper_item_email}>{admin.email}</div>
          <div className={styles.wrapper_item_role}>
            {admin.role === AdminRole.Admin ? "Admin" : "Marketing manager"}
          </div>

          <div
            className={styles.wrapper_item_dots}
            style={{
              zIndex: data.data.length - index
            }}>
            <DotSelector
              items={[
                {
                  label: "Edit",
                  icon: <ICONS.Edit />,
                  onClick: () => setAddEditModalPayload(admin)
                },
                admin.role === AdminRole.Admin
                  ? null
                  : {
                      label: "Delete",
                      icon: <ICONS.Delete />,
                      textStyle: {
                        color: "#FF0033"
                      },
                      style: {
                        borderTop: "1px solid #E6E6E9"
                      },
                      onClick: () => setDeleteModalPayload(admin)
                    }
              ]}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      <Header
        title="Staff Management"
        helper={data?.meta ? `(${data.meta.itemsCount})` : undefined}
        buttons={[
          <Button
            title="Create New"
            icon={<ICONS.Plus />}
            onClick={() => setAddEditModalPayload({})}
          />
        ]}
      />

      <div className="shadow_box">
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_search}>
            <div className={styles.wrapper_header_search_wrapper}>
              <SearchInput
                value={filter?.search || ""}
                onChange={(search) => {
                  onChangeSearch(search.trim() === "" ? null : search);
                }}
              />
            </div>
          </div>
        </header>

        <TableHead
          containerStyle={{
            paddingRight: "24px"
          }}
          items={[
            { label: "User", sortedBy: "admin" },
            { label: "Email", sortedBy: "email" },
            { label: "Role", sortedBy: "role" }
          ]}
          flexes={[1, 1, 1]}
          sortOptions={
            filter?.sorting || {
              sortBy: null,
              order: null
            }
          }
          onChangeSorted={(sorting) => onChangeFilter({ ...filter, sorting })}
        />

        {renderAdmins()}

        {data?.meta.itemsCount && data?.meta.itemsCount !== 0 ? (
          <div className={styles.wrapper_pagination}>
            <Pagination
              count={data.meta.itemsCount}
              limit={LIMIT}
              currentPage={page}
              onChange={(page) => onChangePage(page)}
            />
          </div>
        ) : null}
      </div>

      {!!addEditModalPayload && (
        <AddStaffModal
          payload={addEditModalPayload}
          onClose={() => setAddEditModalPayload(false)}
          onCreateUpdateUser={handleCreateUpdateUser}
        />
      )}

      <ConfirmModal
        isOpen={!!deleteModalPayload}
        title={`Are you sure you want to delete ${deleteModalPayload?.firstName} ${deleteModalPayload?.lastName}`}
        onClose={() => setDeleteModalPayload(null)}
        onConfirm={handleDeleteAdmin}
      />
    </div>
  );
};

export default Staff;
