import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { useFormik } from "formik";
import * as yup from "yup";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";

import { AudioAPI } from "api";

import { Options } from "const";

import {
  ModalWrapper,
  Input,
  Select,
  Label,
  Checkbox,
  Textarea,
  Button,
  Error,
  DragDropUI
} from "components";
import Audio from "./Audio";

import { useAudioFileDetails } from "hooks";
import { IAudio } from "interfaces";
import { toggleLoading } from "store/slices/app";
import { setAudio } from "store/slices/player";
import { AudioService } from "utils";

import { Category, VariantButton } from "enums";
import { ICONS } from "assets";
import { RootState } from "store";

const Schema = yup.object().shape({
  title: yup
    .string()
    .required("This field is required")
    .min(1, "Minimum: 1 character")
    .max(60, "Maximum characters: 60"),
  category: yup.string().required("This field is required"),
  description: yup.string(),
  audio: yup.mixed().required("This field is required")
});

const validateAudio = async (values: any) => {
  try {
    await Schema.validate(values, {
      abortEarly: false
    });
    const programDate = AudioService.validateProgramDate(values);
    if (programDate) {
      return { programDate };
    }
  } catch (error: any) {
    const errors: any = {};
    error.inner.forEach((e: any) => {
      errors[e.path] = e.message;
    });
    const programDate = AudioService.validateProgramDate(values);
    if (programDate) {
      errors.programDate = programDate;
    }

    return errors;
  }
};

type Props = {
  payload: any | false;
  onCreateUpdateAudio: (
    values: IAudio & {
      id?: number;
      audio: string | File;
    }
  ) => void;
  onClose: () => void;
};

const AddAudioModal: FC<Props> = ({
  payload,
  onClose,
  onCreateUpdateAudio
}) => {
  const dispatch = useDispatch();
  const {
    values,
    errors,
    submitCount,
    isValid,
    touched,
    setFieldTouched,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: {
      id: payload.id,
      title: payload.title || "",
      category: payload.category || null,
      week: payload.programDate
        ? AudioService.splitProgrammDate(payload.programDate)?.[0] || null
        : null,
      day: payload.programDate
        ? AudioService.splitProgrammDate(payload.programDate)?.[1] || null
        : null,
      description: payload.description || "",
      audio: payload.url || null
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validate: validateAudio,
    onSubmit: onSubmit
  });

  const { audio: storeAudio, isPlaying } = useSelector(
    (store: RootState) => store.player
  );

  const { formatFileSize, duration, isLoading } = useAudioFileDetails(
    values.audio
  );

  async function onSubmit() {
    const { day, week, ...other } = values;

    dispatch(toggleLoading(true));

    const { success }: any = await AudioAPI.checkDateAudio(
      `${week}${day}`,
      other.category
    );

    if (
      !other.id ||
      (payload?.programDate && payload.programDate !== `${week}${day}`) ||
      payload?.category !== other.category
    ) {
      if (success) {
        toast(`An audio is already active for ${`${week}${day}`}`, {
          type: "error"
        });

        dispatch(toggleLoading(false));
        return;
      }
    }

    const data: any = {
      ...other,
      description:
        other.description.trim() === "" ? null : other.description.trim(),
      programDate: `${week}${day}`,
      length: duration || payload.length
    };

    onCreateUpdateAudio(data);
  }

  const handleUploadAudio = (files: Array<File>) => {
    setFieldTouched("audio");
    setFieldValue("audio", files[0]);
  };

  const renderAudio = () => {
    if (!values.audio)
      return (
        <FileUploader
          multiple={true}
          handleChange={handleUploadAudio}
          name="file"
          types={["mp3", "wav"]}
          maxSize={150}
          onTypeError={() =>
            toast("Supported formats: .wav, .mp3", { type: "error" })
          }
          onSizeError={() =>
            toast("Maximum file size: 150 MB", {
              type: "error"
            })
          }
        >
          <DragDropUI />
        </FileUploader>
      );

    const isPlayIcon =
      typeof values?.audio === "string"
        ? storeAudio !== null && storeAudio?.id !== payload?.id
        : storeAudio !== null && storeAudio?.name !== values?.audio?.name;

    return (
      <Audio
        audio={values.audio}
        formatFileSize={formatFileSize}
        title={payload.title}
        icon={
          isPlayIcon || !isPlaying ? (
            <ICONS.Play height={15} />
          ) : (
            <ICONS.Pause height={15} />
          )
        }
        onPlay={() => {
          if (typeof values.audio === "string") {
            dispatch(setAudio(payload));
          } else {
            dispatch(setAudio(values.audio));
          }
        }}
        onDelete={() => {
          setFieldTouched("audio");
          setFieldValue("audio", null);
        }}
      />
    );
  };

  return (
    <ModalWrapper
      title={`${!values.id ? "Add New" : "Update"} Audio`}
      isOpen={!!payload}
      onClose={onClose}
    >
      <div className={styles.wrapper_field}>
        <Input
          value={values.title}
          label="Title"
          placeholder="Enter title"
          maxLength={60}
          //@ts-ignore
          error={touched.title && errors.title ? errors.title : undefined}
          onChange={(val) => {
            if (/^[^\u0400-\u04FF]*$/.test(val)) {
              setFieldTouched("title");
              setFieldValue("title", val);
            }
          }}
        />
      </div>

      <div className={styles.wrapper_field} style={{ zIndex: 100 }}>
        <Select
          value={values.category}
          options={[
            {
              value: Category.Daily,
              label: "Daily"
            },
            {
              value: Category.Sleep,
              label: "Sleep"
            }
          ]}
          placeholder="Choose category"
          //@ts-ignore
          error={submitCount && errors.category ? errors.category : undefined}
          label="Category"
          onChange={(option) => {
            setFieldValue("category", option.value);
          }}
        />
      </div>

      <div className={styles.wrapper_program}>
        <Label text="Program Date" />

        <div className={styles.wrapper_program_container}>
          <div className={styles.wrapper_program_container_item}>
            <Select
              value={values.week}
              options={Options.Week}
              placeholder="W1"
              onChange={(option) => {
                setFieldTouched("week");
                setFieldValue("week", option.value);
              }}
            />
          </div>
          <div className={styles.wrapper_program_container_item}>
            <Select
              value={values.day}
              options={Options.Day}
              placeholder="D1"
              onChange={(option) => {
                setFieldTouched("day");
                setFieldValue("day", option.value);
              }}
            />
          </div>
          <div>
            <Checkbox
              value={AudioService.isMaintenanceStage(
                !values.week ? null : `${values.week}`
              )}
              label="Maintenance"
              readOnly
            />
          </div>
        </div>

        {touched.week &&
        touched.day &&
        //@ts-ignore
        errors.programDate ? (
          //@ts-ignore
          <Error message={errors.programDate} />
        ) : null}
      </div>

      <div className={styles.wrapper_field}>
        <Textarea
          value={values.description}
          label="Description"
          placeholder="Enter description"
          maxLength={300}
          //@ts-ignore
          error={errors.description}
          onChange={(val) => {
            if (/^[^\u0400-\u04FF]*$/.test(val)) {
              setFieldValue("description", val);
            }
          }}
        />
      </div>

      <div className={styles.wrapper_field}>
        <Label text="Audio file" />
        {isLoading ? <BeatLoader size={10} /> : renderAudio()}

        {touched.audio && errors.audio ? (
          <Error
            //@ts-ignore
            message={errors.audio}
          />
        ) : null}
      </div>

      <div className={styles.wrapper_buttons}>
        <div className={styles.wrapper_buttons_item}>
          <Button
            variant={VariantButton.Transparent}
            title="Cancel"
            onClick={onClose}
          />
        </div>
        <div className={styles.wrapper_buttons_item}>
          <Button title="Save" disable={!isValid} onClick={handleSubmit} />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AddAudioModal;
