import React, { FC } from "react";
import styles from "./styles.module.scss";

import { Button, ModalWrapper } from "components";

import { VariantButton } from "enums";

type Props = {
  isOpen: boolean;
  title: string;
  disableConfirmBtn?: boolean;
  children?: React.ReactNode;
  description?: string;
  leftBtnText?: string;
  rightBtnText?: string;
  onConfirm: () => void;
  onClose: () => void;
};

const ConfirmModal: FC<Props> = ({
  isOpen,
  title,
  description,
  children,
  disableConfirmBtn = false,
  leftBtnText = "Cancel",
  rightBtnText = "Confirm",
  onClose,
  onConfirm
}) => {
  return (
    <ModalWrapper
      title={title}
      isOpen={isOpen}
      style={{
        top: "50%",
        transform: "translate(-50%, -50%)"
      }}
      onClose={onClose}
    >
      {description && (
        <p className={styles.wrapper_description}>{description}</p>
      )}

      {children}

      <div className={styles.wrapper_buttons}>
        <div className={styles.wrapper_buttons_item}>
          <Button title={leftBtnText} onClick={onClose} />
        </div>
        <div className={styles.wrapper_buttons_item}>
          <Button
            title={rightBtnText}
            variant={VariantButton.Red}
            disable={disableConfirmBtn}
            onClick={onConfirm}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ConfirmModal;
