import { FC, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { useFormik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";

import { BannerAPI } from "api";

import { Options } from "const";

import {
  ModalWrapper,
  Input,
  Select,
  Checkbox,
  Label,
  Textarea,
  DragDropUI,
  Button,
  Error,
  ArticleEditor
} from "components";
import ImageComponent from "./Image";
import DateRange from "./DateRange";

import { BannerCategory, VariantButton } from "enums";
import { AudioService } from "utils";
import { toggleLoading } from "store/slices/app";

const validate = (values: any) => {
  const errors: any = {};

  if (values.title.trim() === "") {
    errors.title = "This field is required";
  }

  if (values.category === BannerCategory.Core) {
    const error = AudioService.validateProgramDate(values);
    if (error) errors.date = error;
  } else {
    if (
      !values.startDate ||
      !values.startHours ||
      !values.startMins ||
      !values.startPmAm ||
      !values.endDate ||
      !values.endHours ||
      !values.endMins ||
      !values.endPmAm
    ) {
      errors.date = "Fill all field";
    } else {
      if (
        moment(
          `${moment(values.startDate).format("MM/DD/YYYY")} ${
            values.startHours
          } ${values.startMins} ${values.startPmAm}`,
          "MM/DD/YYYY h m A"
        ).isAfter(
          moment(
            `${moment(values.endDate).format("MM/DD/YYYY")} ${
              values.endHours
            } ${values.endMins} ${values.endPmAm}`,
            "MM/DD/YYYY h m A"
          ),
          "minutes"
        )
      ) {
        errors.date =
          "The Scheduling date cannot be greater than the end date.";
      }
    }
  }

  if (
    values.ref.trim() !== "" &&
    !/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(values.ref)
  ) {
    errors.ref = "Incorrect link format";
  }

  if (values.url === null) {
    errors.url = "Banner is required";
  }

  return errors;
};

type Props = {
  payload: any | false;
  onCreateUpdateBanner: (values: any) => void;
  onClose: () => void;
};

const AddBannerModal: FC<Props> = ({
  payload,
  onClose,
  onCreateUpdateBanner
}) => {
  const dispatch = useDispatch();
  const currentDate = useMemo(() => moment(), []);

  const {
    values,
    errors,
    isValid,
    touched,
    setFieldTouched,
    setTouched,
    setValues,
    setFieldValue,
    handleSubmit,
    setFieldError,
    resetForm
  } = useFormik({
    initialValues: {
      id: payload.id,
      title: payload.title || "",
      category: payload.category || BannerCategory.Core,
      week: payload.programDate
        ? AudioService.splitProgrammDate(payload.programDate)?.[0] || null
        : null,
      day: payload.programDate
        ? AudioService.splitProgrammDate(payload.programDate)?.[1] || null
        : null,

      startDate: payload.schedulingDate
        ? moment(payload.schedulingDate).toDate()
        : currentDate.toDate(),
      startHours: payload.schedulingDate
        ? moment(payload.schedulingDate).format("h")
        : currentDate.format("h"),
      startMins: payload.schedulingDate
        ? moment(payload.schedulingDate).format("m")
        : currentDate.format("m"),
      startPmAm: payload.schedulingDate
        ? moment(payload.schedulingDate).format("A")
        : currentDate.format("A"),

      endDate: payload.endDate
        ? moment(payload.endDate).toDate()
        : moment(currentDate).add(1, "day").toDate(),
      endHours: payload.endDate
        ? moment(payload.endDate).format("h")
        : currentDate.format("h"),
      endMins: payload.endDate
        ? moment(payload.endDate).format("m")
        : currentDate.format("m"),
      endPmAm: payload.endDate
        ? moment(payload.endDate).format("A")
        : currentDate.format("A"),

      publishNow: false,

      description: payload.description || "",
      modifiedDescription:
        payload.modifiedDescription || payload.description || "",
      ref: payload.ref || "",
      url: payload.url || null
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validate,
    onSubmit: onSubmit
  });

  useEffect(() => {
    if (!payload) resetForm();
  }, [payload]);

  async function onSubmit() {
    const { category, week, day, description, ref } = values;

    dispatch(toggleLoading(true));

    const { success }: any = await BannerAPI.checkBannerAudio(`${week}${day}`);

    if (!payload || payload.programDate !== `${week}${day}`) {
      if (success) {
        toast(`A banner is already active for ${`${week}${day}`}`, {
          type: "error"
        });

        dispatch(toggleLoading(false));
        return;
      }
    }

    const isMaintenance = AudioService.isMaintenanceStage(
      !values.week ? null : `${values.week}`
    );

    const data = {
      id: values.id,
      category,
      programDate: category === BannerCategory.Core ? `${week}${day}` : null,
      isMaintenance,
      schedulingDate:
        category === BannerCategory.Core
          ? null
          : moment(
              `${moment(values.startDate).format("MM/DD/YYYY")} ${
                values.startHours
              } ${values.startMins} ${values.startPmAm}`,
              "MM/DD/YYYY h m A"
            ).toDate(),
      endDate:
        category === BannerCategory.Core
          ? null
          : moment(
              `${moment(values.endDate).format("MM/DD/YYYY")} ${
                values.endHours
              } ${values.endMins} ${values.endPmAm}`,
              "MM/DD/YYYY h m A"
            ).toDate(),
      ref: ref.trim() === "" ? null : ref.trim(),
      description: description.trim() === "" ? null : description.trim(),
      modifiedDescription:
        description.trim() === "" ? null : values.modifiedDescription,
      url: values.url,
      title: values.title
    };

    onCreateUpdateBanner(data);
  }

  const handleUploadBanner = (files: any) => {
    setFieldTouched("url");
    setFieldValue("url", files[0]);
  };

  useEffect(() => {
    setFieldError("date", undefined);
  }, [values.category]);

  const renderBanner = () => {
    if (!values.url)
      return (
        <FileUploader
          multiple={true}
          handleChange={handleUploadBanner}
          name="file"
          types={["jpeg", "jpg", "png"]}
          maxSize={1}
          onTypeError={() =>
            toast("Supported formats: .jpg, .png", { type: "error" })
          }
          onSizeError={() =>
            toast("Max size 1MB", {
              type: "error"
            })
          }>
          <DragDropUI />
        </FileUploader>
      );

    return (
      <ImageComponent
        source={values.url}
        onDelete={() => {
          setFieldTouched("url");
          setFieldValue("url", null);
        }}
      />
    );
  };

  const getDateError = () => {
    if (values.category === BannerCategory.Core) {
      return touched.week &&
        touched.day &&
        //@ts-ignore
        errors.date ? (
        //@ts-ignore
        <Error message={errors.date} />
      ) : null;
    } else {
      //@ts-ignore
      return errors.date ? (
        //@ts-ignore
        <Error message={errors.date} />
      ) : null;
    }
  };

  return (
    <ModalWrapper title={`Add New Banner`} isOpen={!!payload} onClose={onClose}>
      <div className={styles.wrapper_field}>
        <Input
          value={values.title}
          label="Title"
          placeholder="Enter title"
          maxLength={60}
          //@ts-ignore
          error={touched.title && errors.title ? errors.title : undefined}
          onChange={(val) => {
            if (/^[^\u0400-\u04FF]*$/.test(val)) {
              setFieldTouched("title");
              setFieldValue("title", val);
            }
          }}
        />
      </div>

      <div className={styles.wrapper_field} style={{ zIndex: 300 }}>
        <Select
          value={values.category}
          options={[
            {
              value: BannerCategory.Core,
              label: "Core"
            },
            {
              value: BannerCategory.AdHoc,
              label: "Ad hoc"
            }
          ]}
          placeholder="Choose category"
          label="Category"
          onChange={(option) => {
            setFieldValue("category", option.value);
          }}
        />
      </div>

      <div className={styles.wrapper_field}>
        {values.category === BannerCategory.Core ? (
          <div className={styles.wrapper_program}>
            <Label text="Program Stage" />

            <div className={styles.wrapper_program_container}>
              <div className={styles.wrapper_program_container_item}>
                <Select
                  value={values.week}
                  options={Options.Week}
                  placeholder="W1"
                  onChange={(option) => {
                    setFieldTouched("week");
                    setFieldValue("week", option.value);
                  }}
                />
              </div>
              <div className={styles.wrapper_program_container_item}>
                <Select
                  value={values.day}
                  options={Options.Day}
                  placeholder="D1"
                  onChange={(option) => {
                    setFieldTouched("day");
                    setFieldValue("day", option.value);
                  }}
                />
              </div>
              <div>
                <Checkbox
                  value={AudioService.isMaintenanceStage(
                    !values.week ? null : `${values.week}`
                  )}
                  label="Maintenance"
                  readOnly
                />
              </div>
            </div>
          </div>
        ) : (
          <DateRange
            startDate={{
              date: values.startDate,
              hours: values.startHours,
              mins: values.startMins,
              pmAm: values.startPmAm
            }}
            endDate={{
              date: values.endDate,
              hours: values.endHours,
              mins: values.endMins,
              pmAm: values.endPmAm
            }}
            publishNow={values.publishNow}
            onChange={(val) => {
              setValues({ ...values, ...val });

              const touchedFields: any = {};
              for (const key in val) {
                touchedFields[key] = true;
              }
              setTouched(touchedFields, false);
            }}
          />
        )}

        {getDateError()}
      </div>

      <div className={styles.wrapper_field}>
        <ArticleEditor
          value={values.modifiedDescription}
          label="Description (optional)"
          maxLength={300}
          height={250}
          onChange={(val, length, text) => {
            setValues({
              ...values,
              description: text,
              modifiedDescription: val
            });
            setFieldTouched("description");
            // setFieldValue("description", val);
          }}
        />
      </div>

      <div className={styles.wrapper_field}>
        <Input
          value={values.ref}
          label="URL (optional)"
          placeholder="Enter link"
          //@ts-ignore
          error={touched.ref && errors.ref ? errors.ref : undefined}
          onChange={(val) => {
            setFieldTouched("ref");
            setFieldValue("ref", val);
          }}
        />
      </div>

      <div className={styles.wrapper_field}>
        <Label text="Picture" subTitle="Recommended picture size: 500*200" />

        {renderBanner()}
        {
          //@ts-ignore
          touched.url && errors.url ? <Error message={errors.url} /> : null
        }
      </div>

      <div className={styles.wrapper_buttons}>
        <div className={styles.wrapper_buttons_item}>
          <Button
            variant={VariantButton.Transparent}
            title="Cancel"
            onClick={onClose}
          />
        </div>
        <div className={styles.wrapper_buttons_item}>
          <Button title="Save" disable={!isValid} onClick={handleSubmit} />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AddBannerModal;
