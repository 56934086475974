import { useMemo, useState, useRef, FC } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { Label, Error } from "components";

import { useOutsideClick } from "hooks";

interface Option {
  value: any;
  label: string;
}

interface SelectProps {
  value: any;
  options: Option[];
  position?: "top" | "bottom";
  label?: string;
  disable?: boolean;
  placeholder?: string;
  error?: string;
  readOnly?: boolean;
  onChange: (option: Option) => void;
}

const Select: FC<SelectProps> = ({
  value,
  options,
  label,
  placeholder = "Search",
  position = "bottom",
  error,
  disable = false,
  readOnly = false,
  onChange
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const optionsContainerRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: optionsContainerRef,
    onClickOutside: () => setIsOpen(false)
  });

  const handleSelectOption = (option: Option) => {
    setIsOpen(false);
    onChange(option);
  };

  const findValue = useMemo(() => {
    if (value) return options.find((o) => o.value === value);
    return null;
  }, [value, options]);

  return (
    <div>
      {label && <Label text={label} />}

      <div
        ref={optionsContainerRef}
        className={cls(styles.wrapper, {
          [styles.wrapper_disable]: disable,
          [styles.wrapper_error]: !!error
        })}>
        <div
          className={styles.wrapper_block}
          onClick={() => {
            if (!disable && !readOnly) {
              setIsOpen(!isOpen);
            }
          }}>
          <p
            className={cls(styles.wrapper_block_placeholder, {
              [styles.wrapper_block_title]: value
            })}>
            {findValue ? findValue.label : placeholder}
          </p>
          <div className={`${styles.wrapper_block_icon}`}>
            <ICONS.SelectArrow />
          </div>
        </div>

        {isOpen && (
          <div
            className={styles.wrapper_options}
            style={
              position === "bottom"
                ? {
                    top: "top: 100%",
                    transform: "translateY(5px)"
                  }
                : {
                    bottom: "100%",
                    transform: "translateY(-5px)"
                  }
            }>
            {options.map((option) => (
              <div
                key={option.value}
                className={`${styles.wrapper_options_item} ${
                  option.value === value
                    ? styles.wrapper_options_item_selected
                    : ""
                }`}
                onClick={() => handleSelectOption(option)}>
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>

      {error && <Error message={error} />}
    </div>
  );
};

export default Select;
