import { ICONS } from "assets";
import { FC, ReactNode } from "react";
import Modal from "react-modal";
import styles from "./styles.module.scss";

const customStyles = {
  content: {
    top: "100px",
    left: "50%",
    right: "auto",
    width: "40%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translateX(-50%)",
    display: "flex",
    border: "none",
    borderRadius: "8px",
    maxHeight: "calc(80vh - 50px)"
  },
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 1010
  }
};

type Props = {
  isOpen: boolean;
  children: ReactNode;
  title: string;
  style?: React.CSSProperties;
  onClose: () => void;
};

const ModalWrapper: FC<Props> = ({
  isOpen,
  children,
  title,
  style = {},
  onClose
}) => {
  return (
    <Modal
      isOpen={isOpen}
      style={{
        ...customStyles,
        content: {
          ...customStyles.content,
          ...style
        }
      }}
      ariaHideApp={false}
      onRequestClose={onClose}
    >
      <div className={styles.wrapper}>
        <header className={styles.wrapper_header}>
          <h4 className={styles.wrapper_header_title}>{title}</h4>
          <div className={styles.wrapper_header_cross} onClick={onClose}>
            <ICONS.Close />
          </div>
        </header>

        {children}
      </div>
    </Modal>
  );
};

export default ModalWrapper;
