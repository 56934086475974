export const formatFileSize = (fileSizeInBytes: number): string => {
  if (fileSizeInBytes < 1024) {
    return fileSizeInBytes + " B";
  } else if (fileSizeInBytes < 1024 * 1024) {
    return (fileSizeInBytes / 1024).toFixed(2) + " KB";
  } else if (fileSizeInBytes < 1024 * 1024 * 1024) {
    return (fileSizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
  } else {
    return (fileSizeInBytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
  }
};

export const formatSecondsToMinutesAndSeconds = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
};

export const validateProgramDate = (values: any) => {
  if (values.week && values.day) return false;

  return "Select both day and week";
};

export const isMaintenanceStage = (selection: string | null): boolean => {
  if (selection === null) {
    return false;
  }

  const wRegex = /^W(\d+)$/;
  const wdRegex = /^W(\d+)D(\d+)$/;

  const wMatch = selection.match(wRegex);
  const wdMatch = selection.match(wdRegex);

  if (wMatch) {
    const wNumber = parseInt(wMatch[1], 10);

    if (wNumber >= 5) {
      return true;
    }
  }

  if (wdMatch) {
    const wNumber = parseInt(wdMatch[1], 10);

    if (wNumber >= 5) {
      return true;
    }
  }

  return false;
};

export const splitProgrammDate = (input: string): [string, string] | null => {
  const regex = /(W\d+)(D\d+)/;

  const match = input.match(regex);

  if (match) {
    const wPart = match[1];
    const dPart = match[2];
    return [wPart, dPart];
  }

  return null;
};
