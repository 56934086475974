import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IAudio } from "interfaces";

export interface AppState {
  audio: IAudio | any | null;
  isPlaying: boolean;
}

const initialState: AppState = {
  audio: null,
  isPlaying: false
};

export const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    setAudio: (state, action: PayloadAction<IAudio | any | null>) => {
      const payload = action.payload;

      if (payload === null) {
        state.audio = payload;
        state.isPlaying = false;
        return;
      }

      if (state.audio?.id !== undefined && payload?.id !== undefined) {
        if (state.audio?.id === payload?.id) {
          state.isPlaying = !state.isPlaying;
        } else {
          state.audio = payload;
        }

        return;
      }

      if (state.audio?.name !== undefined && payload?.name !== undefined) {
        if (state.audio?.name === payload?.name) {
          state.isPlaying = !state.isPlaying;
        } else {
          state.audio = payload;
        }

        return;
      }

      state.audio = payload;
    },
    togglePlay: (state, action: PayloadAction<boolean>) => {
      state.isPlaying = action.payload;
    }
  }
});

export const { setAudio, togglePlay } = playerSlice.actions;

export default playerSlice.reducer;
