import { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";
import styles from "./styles.module.scss";

import { AudioAPI, FileAPI } from "api";

import { ICONS } from "assets";

import {
  LogsHeader,
  Button,
  Switcher,
  EmptyListTitle,
  SearchInput,
  ConfirmModal,
  AddAudioModal,
  LogsTable,
  Status
} from "components";
import Filt from "./Filter";

import { AudioService, AwsService } from "utils";
import { toggleLoading } from "store/slices/app";
import { setAudio as setPlayerAudio } from "store/slices/player";
import { usePagination } from "hooks";
import { withRoleAuthorization } from "hoc";

import { AdminRole, OrderBy, VariantButton } from "enums";
import { IAudio, IFileSession } from "interfaces";
import { RootState } from "store";
import { toast } from "react-toastify";

const LIMIT = 10;

const AudioLogs: FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [audio, setAudio] = useState<null | IAudio>(null);
  const [isOpenDeleteAudioModal, setIsOpenDeleteAudioModal] =
    useState<boolean>(false);
  const [isOpenAddAudioModal, setIsOpenAddAudioModal] = useState<any | false>(
    false
  );
  const { audio: storeAudio, isPlaying } = useSelector(
    (store: RootState) => store.player
  );

  const fetchData = async (page: number, take: number, filter: any) => {
    const resp = await FileAPI.getFileSession(
      "audio",
      Number(id),
      page,
      take,
      filter
    );
    return resp;
  };

  const {
    isLoading: articleLoading,
    data,
    page,
    filter,
    onChangeSearch,
    onChangeFilter,
    onChangePage
  } = usePagination<
    {
      data: Array<IFileSession>;
      meta: { itemsCount: number };
    },
    {
      sorting: {
        sortBy: any | null;
        order: OrderBy | null;
      } | null;
      search: string | null;
      dateRange: {
        from: Date | null;
        to: Date | null;
      } | null;
    }
  >(fetchData, LIMIT);

  useEffect(() => {
    fetchAudio();
  }, []);

  const fetchAudio = async () => {
    try {
      setIsLoading(true);
      const { audio } = await AudioAPI.getAudio(Number(id));

      setAudio(audio);
    } catch (e) {
      alert("Error");
    } finally {
      setIsLoading(false);
    }
  };

  const toggleDeactivated = async (newStatus: boolean) => {
    try {
      if (!audio) return;
      AudioAPI.editAudio({ status: newStatus }, audio.id);
      setAudio({ ...audio, status: newStatus });
    } catch (e) {
      alert("Error");
    }
  };

  const handleDeleteAudio = async () => {
    try {
      if (!audio) return;
      dispatch(toggleLoading(true));

      await AudioAPI.deleteAudio(audio.id);
      await FileAPI.deleteFiles("audio", [audio.url]);
      navigate("/audio");
    } catch (e) {
      alert("Error");
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const handleUpdateAudio = async (newAudio: any) => {
    try {
      dispatch(toggleLoading(true));
      const { audio, id, ...other } = newAudio;
      let audioUrl = typeof audio === "string" ? audio : "";

      if (typeof audio !== "string") {
        const { links } = await FileAPI.getLinks("audio", [audio.name]);
        const { uploadLink, key } = links[0];
        await AwsService.uploadFileToStorage(audio, uploadLink, audio.type);
        audioUrl = key;

        FileAPI.deleteFiles("audio", [isOpenAddAudioModal.url]);
      }

      const { audio: updatedAudio } = await AudioAPI.editAudio(
        {
          ...other,
          url: audioUrl
        },
        id
      );

      setAudio(updatedAudio);
      setIsOpenAddAudioModal(false);
    } catch (e) {
      alert("Error");
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const exportTable = async () => {
    try {
      dispatch(toggleLoading(true));
      if (!audio) return;

      const response = await FileAPI.getLogsExport("audio", audio?.id);
      const blob = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${moment().format("YYYY-MM-DDT_hh_mm_ss")}_audio_${
        audio.id
      }_logs.xlsx`;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (e: any) {
      toast(e.response.data.message, {
        type: "error"
      });
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  if (isLoading)
    return (
      <div className={styles.loading}>
        <ClipLoader size={50} />
      </div>
    );

  if (!audio) return <EmptyListTitle title="Something went wrong" />;

  return (
    <div>
      <LogsHeader
        title="Audio Logs"
        routeBack="/audio"
        actions={[
          <Button
            title="Edit"
            variant={VariantButton.Transparent}
            icon={<ICONS.Edit />}
            onClick={() => setIsOpenAddAudioModal(audio)}
          />,
          <Button
            title="Delete"
            variant={VariantButton.RedTransparent}
            icon={<ICONS.Delete fill="red" />}
            onClick={() => setIsOpenDeleteAudioModal(true)}
          />,
          <div style={{ paddingLeft: "20px" }}>
            <Switcher
              value={audio.status}
              label={audio.status ? "Deactivate" : "Activate"}
              subLabel={`Switch to ${audio.status ? "disable" : "enable"}`}
              onChange={toggleDeactivated}
            />
          </div>
        ]}
      />

      <div className="shadow_box">
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_left}>
            <div
              className={styles.wrapper_header_left_icon}
              onClick={() => dispatch(setPlayerAudio(audio))}>
              {
                //@ts-ignore
                storeAudio?.id !== audio.id || !isPlaying ? (
                  <ICONS.Play height={15} />
                ) : (
                  <ICONS.Pause height={15} />
                )
              }
            </div>
            <div className={styles.wrapper_header_left_info}>
              <h3 className={styles.wrapper_header_left_info_title}>
                {audio.title}
              </h3>
              {audio.description && (
                <p className={styles.wrapper_header_left_info_description}>
                  {audio.description}
                </p>
              )}
            </div>
          </div>

          <div className={styles.wrapper_header_right}>
            <div className={styles.wrapper_header_right_item}>
              <div className={styles.wrapper_header_right_item_icon}>
                <ICONS.User />
              </div>

              <div className={styles.wrapper_header_right_item_info}>
                <p className={styles.wrapper_header_right_item_info_value}>
                  {audio.listenersCount}
                </p>
                <p className={styles.wrapper_header_right_item_info_label}>
                  Number of Listeners
                </p>
              </div>
            </div>

            <div className={styles.wrapper_header_right_item}>
              <div className={styles.wrapper_header_right_item_icon}>
                <ICONS.Play height={14} />
              </div>

              <div className={styles.wrapper_header_right_item_info}>
                <p className={styles.wrapper_header_right_item_info_value}>
                  {audio.listenCount}
                </p>
                <p className={styles.wrapper_header_right_item_info_label}>
                  Number of Plays
                </p>
              </div>
            </div>
          </div>
        </header>

        <div className={styles.wrapper_info}>
          <div className={styles.wrapper_info_item}>
            <p className={styles.wrapper_info_item_label}>ID</p>
            <p className={styles.wrapper_info_item_value}>{audio.id}</p>
          </div>
          <div className={styles.wrapper_info_item}>
            <p className={styles.wrapper_info_item_label}>Category</p>
            <p className={styles.wrapper_info_item_value}>{audio.category}</p>
          </div>
          <div className={styles.wrapper_info_item}>
            <p className={styles.wrapper_info_item_label}>Program Date</p>
            <p className={styles.wrapper_info_item_value}>
              {audio.programDate}
              {AudioService.isMaintenanceStage(audio.programDate)
                ? " (Maintenance)"
                : null}
            </p>
          </div>
          <div className={styles.wrapper_info_item}>
            <p className={styles.wrapper_info_item_label}>Upload Date</p>
            <p className={styles.wrapper_info_item_value}>
              {moment(audio.uploadDate).format("MM/DD/YYYY")}
            </p>
          </div>
          <div className={styles.wrapper_info_item}>
            <p className={styles.wrapper_info_item_label}>Length</p>
            <p className={styles.wrapper_info_item_value}>
              {AudioService.formatSecondsToMinutesAndSeconds(audio.length)}
            </p>
          </div>
          <div className={styles.wrapper_info_item}>
            <p className={styles.wrapper_info_item_label}>Status</p>
            <Status status={audio.status} />
          </div>
          <div className={styles.wrapper_info_item}>
            <p className={styles.wrapper_info_item_label}>Average Rating</p>
            <p className={styles.wrapper_info_item_value}>
              {audio.rate.toFixed(2)}
            </p>
          </div>
        </div>

        <header className={styles.wrapper_table_header}>
          <div className={styles.wrapper_table_header_search}>
            <div className={styles.wrapper_table_header_search_wrapper}>
              <SearchInput
                value={filter?.search || ""}
                onChange={(search) => {
                  onChangeSearch(search.trim() === "" ? null : search);
                }}
              />
            </div>
          </div>

          <div className={styles.wrapper_table_header_button}>
            <Button
              title="Export XLSX"
              variant={VariantButton.Transparent}
              icon={<ICONS.ExportFile />}
              onClick={exportTable}
            />
          </div>

          <div>
            <Filt
              filter={filter}
              onChange={(newFilter) =>
                onChangeFilter({
                  ...filter,
                  ...newFilter
                })
              }
              onClear={() =>
                //@ts-ignore
                onChangeFilter({
                  dateRange: null
                })
              }
            />
          </div>
        </header>

        <LogsTable
          data={data}
          page={page}
          isLoading={articleLoading}
          limit={LIMIT}
          type="audio"
          sort={
            filter?.sorting || {
              sortBy: null,
              order: null
            }
          }
          onChangeSort={(sorting) => onChangeFilter({ ...filter, sorting })}
          onChangePage={onChangePage}
        />
      </div>

      <AddAudioModal
        payload={isOpenAddAudioModal}
        onClose={() => setIsOpenAddAudioModal(false)}
        onCreateUpdateAudio={handleUpdateAudio}
      />

      <ConfirmModal
        isOpen={!!isOpenDeleteAudioModal}
        title={`Are you sure you want to delete ${audio.title}`}
        onClose={() => setIsOpenDeleteAudioModal(false)}
        onConfirm={handleDeleteAudio}
      />
    </div>
  );
};

export default withRoleAuthorization([AdminRole.Admin])(AudioLogs);
