import { FC } from "react";
import styles from "./styles.module.scss";

type Props = {
  title: string;
};

const EmptyListTitle: FC<Props> = ({ title }) => (
  <p className={styles.title}>{title}</p>
);

export default EmptyListTitle;
