import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { useOutsideClick } from "hooks";

type Props = {
  items: Array<{
    label: string;
    icon: React.ReactNode;
    style?: React.CSSProperties;
    textStyle?: React.CSSProperties;
    onClick: () => void;
  } | null>;
  onOpen?: () => void;
  onClose?: () => void;
};

const DotSelector: FC<Props> = ({ items, onOpen, onClose }) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const divRef = useRef(null);
  useOutsideClick({
    ref: divRef,
    onClickOutside: () => {
      setIsOpenModal(false);
    }
  });

  useEffect(() => {
    if (onOpen && onClose) {
      isOpenModal ? onOpen() : onClose();
    }
  }, [isOpenModal]);

  return (
    <div
      ref={divRef}
      className={styles.wrapper}
      onClick={(e) => {
        e.stopPropagation();
        setIsOpenModal(!isOpenModal);
      }}>
      <div className={styles.wrapper_arrows}>
        <ICONS.Dots />
      </div>

      {isOpenModal && (
        <div className={styles.modal}>
          <div className={styles.modal_wrapper}>
            {items.map((item, index) => {
              const style = item?.style || {};
              const textStyle = item?.textStyle || {};

              return (
                <React.Fragment key={index}>
                  {item && (
                    <div style={style}>
                      <div
                        className={styles.modal_wrapper_item}
                        onClick={item.onClick}>
                        <div className={styles.modal_wrapper_item_icon}>
                          {item.icon}
                        </div>
                        <p style={textStyle}>{item.label}</p>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default DotSelector;
