export const Week = [
  { label: "W1 (Core)", value: "W1" },
  { label: "W2 (Core)", value: "W2" },
  { label: "W3 (Core)", value: "W3" },
  { label: "W4 (Core)", value: "W4" },
  { label: "W5", value: "W5" },
  { label: "W6", value: "W6" },
  { label: "W7", value: "W7" },
  { label: "W8", value: "W8" },
  { label: "W9", value: "W9" },
  { label: "W10", value: "W10" },
  { label: "W11", value: "W11" },
  { label: "W12", value: "W12" },
  { label: "W13", value: "W13" }
];

export const Day = [
  { label: "D1", value: "D1" },
  { label: "D2", value: "D2" },
  { label: "D3", value: "D3" },
  { label: "D4", value: "D4" },
  { label: "D5", value: "D5" },
  { label: "D6", value: "D6" },
  { label: "D7", value: "D7" }
];

export const Mins = (() => {
  const minutesArray = [];
  for (let i = 0; i <= 59; i += 1) {
    const label = i.toString();
    minutesArray.push({ label, value: label });
  }
  return minutesArray;
})();

export const Hours = (() => {
  const hourOptions = [];

  for (let h = 1; h <= 12; h++) {
    const hourLabel = h.toString();
    const value = hourLabel;
    hourOptions.push({ label: hourLabel, value });
  }

  return hourOptions;
})();
