import { IAdmin } from "interfaces";
import client from "./client";

class AdminAPI {
  static changeEmail(email: string, password: string) {
    return client
      .patch("admin/change/email", { email, password })
      .then(({ data }) => data);
  }

  static changePassword(password: string) {
    return client
      .patch("admin/change/password", { password })
      .then(({ data }) => data);
  }

  static getAdmins(page: number, take: number, filter: any) {
    return client
      .post(`admin/all?page=${page}&take=${take}`, filter)
      .then(({ data }) => data);
  }

  static checkAdmin(email: string) {
    return client
      .post(`admin/check/email-exist`, { email })
      .then(({ data }) => data);
  }

  static createAdmin(admin: Partial<IAdmin>) {
    return client.post(`admin/create`, admin).then(({ data }) => data);
  }

  static updateAdmin(admin: Partial<IAdmin>, id: number) {
    return client.patch(`admin/update/${id}`, admin).then(({ data }) => data);
  }

  static deleteAccount() {
    return client.delete(`admin`).then(({ data }) => data);
  }

  static deleteAccountById(id: number) {
    return client.delete(`admin/${id}`).then(({ data }) => data);
  }
}

export default AdminAPI;
