import { FC } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";

import { ICONS } from "assets";
import { ReactComponent as PopupArrow } from "./popupArrow.svg";

import { Checkbox } from "components";

import { OrderBy } from "enums";

type Props = {
  items: Array<{
    label: string;
    popupText?: string;
    sortedBy?: any;
    style?: React.CSSProperties;
  }>;
  isChecked?: boolean;
  sortOptions: {
    sortBy: any | null;
    order: OrderBy | null;
  };
  flexes: Array<number>;
  containerStyle?: React.CSSProperties;
  onChangeChecked?: () => void;
  onChangeSorted: (
    sorted: {
      sortBy: any | null;
      order: OrderBy | null;
    } | null
  ) => void;
};

const TableHead: FC<Props> = ({
  items,
  flexes,
  sortOptions,
  isChecked,
  containerStyle = {},
  onChangeChecked,
  onChangeSorted
}) => {
  const handleChangeSort = (index: number) => {
    const selectedItem = items[index];
    if (!selectedItem) return;

    if (
      selectedItem.sortedBy === sortOptions.sortBy &&
      sortOptions.order === OrderBy.DESC
    ) {
      // onChangeSorted({ sortedBy: null, orderBy: null });
      onChangeSorted(null);
      return;
    }

    if (selectedItem.sortedBy === sortOptions.sortBy) {
      onChangeSorted({
        ...sortOptions,
        order: OrderBy.DESC
      });
      return;
    }

    onChangeSorted({
      sortBy: selectedItem.sortedBy || null,
      order: OrderBy.ASC
    });
  };

  return (
    <div className={styles.wrapper} style={containerStyle}>
      {isChecked !== undefined && onChangeChecked && (
        <div className={styles.wrapper_checkbox}>
          <Checkbox value={isChecked} onChange={onChangeChecked} />
        </div>
      )}

      {items.map((item, index) => {
        const flex = flexes[index];
        const isSelected = sortOptions.sortBy === item.sortedBy;
        const style = item && item.style ? item.style : {};

        return (
          <div
            key={index}
            className={styles.wrapper_item}
            style={{
              flex: flex || undefined,
              width: item ? "auto" : 24,
              ...style
            }}
            onClick={() => {
              if (item.sortedBy) {
                handleChangeSort(index);
              }
            }}
          >
            <>
              <div
                className={cls(styles.wrapper_item_title, {
                  [styles.wrapper_item_title_active]: isSelected
                })}
              >
                {item.label}

                {item.popupText && (
                  <div className={styles.wrapper_item_popup}>
                    <div className={styles.wrapper_item_popup_wrapper}>
                      {item.popupText}
                      <div className={styles.wrapper_item_popup_wrapper_arrow}>
                        <PopupArrow />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {item.sortedBy && (
                <div
                  className={styles.wrapper_item_arrow}
                  style={{
                    transform: `rotate(${
                      isSelected && sortOptions.order === OrderBy.DESC
                        ? "180deg"
                        : 0
                    })`
                  }}
                >
                  <ICONS.SmallArrow />
                </div>
              )}
            </>
          </div>
        );
      })}
    </div>
  );
};

export default TableHead;
