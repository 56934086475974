import { ICONS } from "assets";
import { PrivateImage } from "components";
import React from "react";
import styles from "./styles.module.scss";

interface ImageComponentProps {
  source: string | File;
  onDelete: () => void;
}

const Image: React.FC<ImageComponentProps> = ({ source, onDelete }) => {
  let imageSource: string;
  let fileName: string | null = null;

  if (typeof source === "string") {
    imageSource = source;
  } else if (source instanceof File) {
    imageSource = URL.createObjectURL(source);
    fileName = source.name;
  } else {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {typeof source !== "string" ? (
        <img
          src={imageSource}
          alt="Image"
          style={{
            maxWidth: "100%",
            height: "auto"
          }}
        />
      ) : (
        <PrivateImage src={imageSource} />
      )}

      <div className={styles.wrapper_delete} onClick={onDelete}>
        <ICONS.Delete />
      </div>

      {fileName && <div className={styles.wrapper_filename}>{fileName}</div>}
    </div>
  );
};

export default Image;
