import client from "./client";

import { IBanner } from "interfaces";

class BannerAPI {
  static createBanner(banner: IBanner & { id: undefined }) {
    return client.post("banner", banner).then(({ data }) => data);
  }

  static editBanner(banner: Partial<IBanner>, id: number) {
    return client.patch(`banner/${id}`, banner).then(({ data }) => data);
  }

  static getBanners(page: number, take: number, filter: any) {
    return client
      .post(`banner/all?page=${page}&take=${take}`, filter)
      .then(({ data }) => data);
  }

  static checkBannerAudio(programDate: string) {
    return client
      .get(`banner/check?programDate=${programDate}`)
      .then(({ data }) => data);
  }

  static deleteBanner(id: number) {
    return client.delete(`banner/${id}`).then(({ data }) => data);
  }

  static getBannerExport() {
    return client
      .get(`banner/export`, {
        responseType: "blob"
      })
      .then((resp) => {
        return resp.data;
      });
  }
}

export default BannerAPI;
