import { FC, useEffect, useState } from "react";
import styles from "./styles.module.scss";

import { Button, DatePicker, ModalWrapper } from "components";
import { VariantButton } from "enums";

type Props = {
  payload: any;
  onClose: () => void;
  onCreate: (date: Date | null) => void;
};

const FreeAccessModal: FC<Props> = ({ payload, onClose, onCreate }) => {
  const [date, setDate] = useState<null | Date>(payload?.FA || null);

  useEffect(() => {
    setDate(payload?.FA || null);
  }, [payload]);

  return (
    <ModalWrapper
      title="Free Access"
      isOpen={!!payload}
      onClose={onClose}
      style={{ overflow: "visible" }}
    >
      <div className={styles.wrapper}>
        <p className={styles.wrapper_description}>
          Are you sure you want to give free access for {payload?.FN}?
        </p>

        <div className={styles.wrapper_item}>
          <DatePicker
            placeholder="MM/DD/YYYY"
            value={date}
            label="End date"
            onChange={setDate}
          />
        </div>

        <div className={styles.wrapper_buttons}>
          <div className={styles.wrapper_buttons_item}>
            <Button
              variant={VariantButton.Transparent}
              title="Cancel"
              onClick={onClose}
            />
          </div>
          <div className={styles.wrapper_buttons_item}>
            <Button title="Save" onClick={() => onCreate(date)} />
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default FreeAccessModal;
