import client from "./client";

class ArticleAPI {
  static createArticle(article: any) {
    return client.post("article", article).then(({ data }) => data);
  }

  static getArticles(page: number, take: number, filter: any) {
    return client
      .post(`article/all?page=${page}&take=${take}`, filter)
      .then(({ data }) => data);
  }

  static getArticle(id: number) {
    return client.get(`article/${id}`).then(({ data }) => data);
  }

  static editArticle(article: any, id: number) {
    return client.patch(`article/${id}`, article).then(({ data }) => data);
  }

  static deleteArticle(id: number) {
    return client.delete(`article/${id}`).then(({ data }) => data);
  }

  static checkDateArticle(programDate: string) {
    return client
      .get(`article/check?programDate=${programDate}`)
      .then(({ data }) => data);
  }

  static getArticleExport() {
    return client
      .get(`article/export`, {
        responseType: "blob"
      })
      .then((resp) => {
        return resp.data;
      });
  }
}

export default ArticleAPI;
