import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import cls from "classnames";
import moment from "moment";
import styles from "./styles.module.scss";

import { BannerAPI, FileAPI } from "api";

import { ICONS } from "assets";

import {
  Header,
  Button,
  TableHead,
  SearchInput,
  Pagination,
  TableLoading,
  EmptyListTitle,
  DotSelector,
  PrivateImage,
  Status,
  ConfirmModal
} from "components";
import AddBannerModal from "./AddBannerModal";
import Filt from "./Filter";

import { toggleLoading } from "store/slices/app";
import { AwsService } from "utils";
import { usePagination } from "hooks";

import { IBanner } from "interfaces";
import { OrderBy, VariantButton } from "enums";

const LIMIT = 10;

const Banners: FC = () => {
  const dispatch = useDispatch();
  const [updateCreateBannerPayload, setUpdateCreateBannerPayload] = useState<
    any | false
  >(false);
  const [deleteModalPayload, setDeleteModalPayload] = useState<null | IBanner>(
    null
  );
  const [activateDeactivateModal, setActivateDeactivateModal] =
    useState<null | IBanner>(null);

  const fetchData = async (page: number, take: number, filter: any) => {
    const updatedFilter = { ...filter };
    delete updatedFilter.search;
    delete updatedFilter.sorting;

    const filterArr = Object.keys(updatedFilter).map(function (key) {
      return {
        field: key,
        dateRange: {
          from: updatedFilter[key].from,
          to: updatedFilter[key].to
        }
      };
    });

    const filterData: any = { filter: filterArr };

    if (filter.sorting) {
      filterData.sorting = filter.sorting;
    }

    if (filter.search) {
      filterData.search = filter.search;
    }

    const resp = await BannerAPI.getBanners(page, take, filterData);
    return resp;
  };

  const {
    isLoading,
    data,
    page,
    filter,
    onChangeFilter,
    onChangePage,
    onRefresh,
    onChangeSearch
  } = usePagination<
    {
      data: Array<IBanner>;
      meta: { itemsCount: number };
    },
    {
      sorting: {
        sortBy: any | null;
        order: OrderBy | null;
      } | null;
      search: string | null;
      createdAt: {
        from: Date | null;
        to: Date | null;
      } | null;
      schedulingDate: {
        from: Date | null;
        to: Date | null;
      } | null;
      endDate: {
        from: Date | null;
        to: Date | null;
      } | null;
    }
  >(fetchData, LIMIT);

  const renderBanners = () => {
    if (isLoading) return <TableLoading />;

    if (!data?.data) return null;

    if (data.data.length === 0)
      return <EmptyListTitle title="No banners yet" />;

    return data.data.map((banner, index) => (
      <div key={banner.id} className={styles.wrapper_item}>
        <div className={styles.wrapper_item_id}>{banner.id}</div>
        <div>
          <div className={styles.wrapper_item_picture}>
            <PrivateImage src={banner.url} />
          </div>
        </div>
        <div className={styles.wrapper_item_info}>
          <p className={styles.wrapper_item_info_name}>{banner.title}</p>
          <p className={styles.wrapper_item_info_description}>
            {banner.description}
          </p>
        </div>
        <div>{banner.category}</div>
        <div>{banner.programDate || "-"}</div>
        <div>
          {banner.schedulingDate
            ? moment(banner.schedulingDate).format("MM/DD/YYYY")
            : "-"}
        </div>

        <div>
          {banner.endDate ? moment(banner.endDate).format("MM/DD/YYYY") : "-"}
        </div>

        <div
          className={cls({
            [styles.wrapper_item_link]: !!banner.ref
          })}>
          {banner.ref ? (
            <a target="_blank" href={banner.ref} rel="noreferrer">
              Link
            </a>
          ) : (
            "-"
          )}
        </div>
        <div>
          {banner.createdAt
            ? moment(banner.createdAt).format("MM/DD/YYYY")
            : "-"}
        </div>
        <div className={styles.wrapper_item_status}>
          <Status status={banner.status} />
        </div>
        <div
          className={styles.wrapper_item_dots}
          style={{
            zIndex: data.data.length - index
          }}>
          <DotSelector
            items={[
              {
                label: "Edit",
                icon: <ICONS.Edit />,
                onClick: () => setUpdateCreateBannerPayload(banner)
              },
              {
                label: banner.status ? "Unpublish" : "Publish",
                icon: <ICONS.Deactivate />,
                onClick: () => setActivateDeactivateModal(banner)
              },
              {
                label: "Delete",
                icon: <ICONS.Delete />,
                textStyle: {
                  color: "#FF0033"
                },
                style: {
                  borderTop: "1px solid #E6E6E9"
                },
                onClick: () => setDeleteModalPayload(banner)
              }
            ]}
          />
        </div>
      </div>
    ));
  };

  const toggleDeactivated = async () => {
    try {
      if (!activateDeactivateModal) return;
      dispatch(toggleLoading(true));

      await BannerAPI.editBanner(
        {
          status: !activateDeactivateModal.status
        },
        activateDeactivateModal.id
      );
      await onRefresh();

      toast(
        !activateDeactivateModal.status
          ? "Publish successfully"
          : "Unpublish successfully",
        {
          type: "success",
          icon: !activateDeactivateModal.status ? true : <ICONS.Minus />
        }
      );

      setActivateDeactivateModal(null);
    } catch (e) {
      alert("Error");
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const handleDeleteAudio = async () => {
    try {
      if (deleteModalPayload === null) return;
      dispatch(toggleLoading(true));

      await BannerAPI.deleteBanner(deleteModalPayload.id);
      await onRefresh();
      FileAPI.deleteFiles("audio", [deleteModalPayload.url]);
      setDeleteModalPayload(null);
    } catch (e) {
      alert("Error");
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const handleCreateUpdateBanner = async (values: any) => {
    try {
      debugger;
      dispatch(toggleLoading(true));
      const { url, id, ...other } = values;

      if (!id) {
        // create banner

        const { links } = await FileAPI.getLinks("banner", [url.name]);
        const { uploadLink, key } = links[0];
        await AwsService.uploadFileToStorage(url, uploadLink);

        await BannerAPI.createBanner({
          ...other,
          url: key
        });
        await onRefresh();
        toast("The banner has been created.", {
          type: "success"
        });
      } else {
        // here should be update banner

        let bannerUrl = typeof url === "string" ? url : "";

        if (typeof url !== "string") {
          const { links } = await FileAPI.getLinks("banner", [url.name]);
          const { uploadLink, key } = links[0];
          await AwsService.uploadFileToStorage(url, uploadLink);
          bannerUrl = key;

          FileAPI.deleteFiles("banner", [updateCreateBannerPayload.url]);
        }

        await BannerAPI.editBanner(
          {
            ...other,
            url: bannerUrl
          },
          id
        );
        await onRefresh();
        toast("The banner has been updated.", {
          type: "success"
        });
      }

      setUpdateCreateBannerPayload(false);
    } catch (e: any) {
      toast(e.response.data.message, {
        type: "error"
      });
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  console.log(data?.data);

  const exportTable = async () => {
    try {
      dispatch(toggleLoading(true));
      const response = await BannerAPI.getBannerExport();
      const blob = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${moment().format("YYYY-MM-DDT_hh_mm_ss")}_banners.xlsx`;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (e: any) {
      toast(e.response.data.message, {
        type: "error"
      });
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  return (
    <div>
      <Header
        title="Banners"
        helper={data?.meta ? `(${data.meta.itemsCount})` : undefined}
        buttons={[
          <Button
            title="Add Banner"
            icon={<ICONS.Plus />}
            onClick={() => setUpdateCreateBannerPayload(true)}
          />
        ]}
      />

      <div className="shadow_box">
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_search}>
            <div className={styles.wrapper_header_search_wrapper}>
              <SearchInput
                value={filter?.search || ""}
                onChange={(search) => {
                  onChangeSearch(search.trim() === "" ? null : search);
                }}
              />
            </div>
          </div>

          <div className={styles.wrapper_header_button}>
            <Button
              title="Export XLSX"
              variant={VariantButton.Transparent}
              icon={<ICONS.ExportFile />}
              onClick={exportTable}
            />
          </div>

          <div>
            <Filt
              filter={filter}
              onChange={(newFilter) =>
                onChangeFilter({
                  ...filter,
                  ...newFilter
                })
              }
              onClear={() => {
                //@ts-ignore
                onChangeFilter({
                  createdAt: null,
                  schedulingDate: null,
                  endDate: null
                });
              }}
            />
          </div>
        </header>

        <TableHead
          containerStyle={{
            paddingRight: "24px"
          }}
          items={[
            { label: "ID", sortedBy: "id" },
            { label: "Picture" },
            { label: "Title", sortedBy: "title" },
            {
              label: "Category",
              sortedBy: "category"
            },
            {
              label: "PS",
              sortedBy: "programDate",
              popupText: "Program Stage"
            },
            {
              label: "SD",
              sortedBy: "schedulingDate",
              popupText: "Start Date"
            },
            {
              label: "ED",
              sortedBy: "endDate",
              popupText: "End Date"
            },
            { label: "Link" },
            {
              label: "UD",
              sortedBy: "createdAt",
              popupText: "Upload Date"
            },
            {
              label: "ST",
              sortedBy: "status",
              popupText: "Status",
              style: { justifyContent: "center" }
            }
          ]}
          flexes={[1, 1, 2, 1, 1, 1, 1, 1, 1, 1]}
          sortOptions={
            filter?.sorting || {
              sortBy: null,
              order: null
            }
          }
          onChangeSorted={(sorting) => onChangeFilter({ ...filter, sorting })}
        />

        {renderBanners()}

        {data?.meta.itemsCount && data?.meta.itemsCount !== 0 ? (
          <div className={styles.wrapper_pagination}>
            <Pagination
              count={data.meta.itemsCount}
              limit={LIMIT}
              currentPage={page}
              onChange={(page) => onChangePage(page)}
            />
          </div>
        ) : null}
      </div>

      {!!updateCreateBannerPayload && (
        <AddBannerModal
          payload={updateCreateBannerPayload}
          onClose={() => setUpdateCreateBannerPayload(false)}
          onCreateUpdateBanner={handleCreateUpdateBanner}
        />
      )}

      <ConfirmModal
        isOpen={!!activateDeactivateModal}
        title={`${
          activateDeactivateModal?.status
            ? "Unpublish Banner"
            : "Publish Banner"
        }`}
        leftBtnText="No"
        rightBtnText="Yes"
        onClose={() => setActivateDeactivateModal(null)}
        onConfirm={toggleDeactivated}
      />

      <ConfirmModal
        isOpen={!!deleteModalPayload}
        title={`Are you sure you want to delete ${deleteModalPayload?.title}`}
        onClose={() => setDeleteModalPayload(null)}
        onConfirm={handleDeleteAudio}
      />
    </div>
  );
};

export default Banners;
