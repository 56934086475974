import { FC, useRef, useState, useEffect } from "react";
import styles from "./styles.module.scss";

import { Filter as FilterComponents } from "components";

import { useOutsideClick } from "hooks";

type Props = {
  filter: any;
  onChange: (value: any) => void;
  onClear: () => void;
};

const Filter: FC<Props> = ({ filter: initialFilter, onChange, onClear }) => {
  const [filter, setFilter] = useState<any>(initialFilter);
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpenFilter) {
      setFilter(initialFilter);
    }
  }, [isOpenFilter, initialFilter]);

  const divRef = useRef(null);
  useOutsideClick({
    ref: divRef,
    onClickOutside: () => setIsOpenFilter(false)
  });

  const handleApplyFilter = () => {
    onChange(filter);
    setIsOpenFilter(false);
  };

  const handleClearFilter = () => {
    onClear();
    setIsOpenFilter(false);
  };

  const disableApply =
    !filter.dateRange || !filter.dateRange.from || !filter.dateRange.to;

  return (
    <div ref={divRef} className={styles.wrapper}>
      <div
        className={styles.wrapper_icon}
        onClick={() => setIsOpenFilter(!isOpenFilter)}
      >
        <FilterComponents.Wrapper />
      </div>

      {isOpenFilter && (
        <div className={styles.wrapper_filter}>
          <FilterComponents.Container
            disableApply={disableApply}
            onApply={handleApplyFilter}
            onClear={handleClearFilter}
          >
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.DateRange
                value={filter.dateRange || null}
                title="Upload range"
                //@ts-ignore
                position={["Left", "Right"]}
                onChange={(dateRange) =>
                  setFilter({
                    ...filter,
                    dateRange
                  })
                }
              />
            </div>
          </FilterComponents.Container>
        </div>
      )}
    </div>
  );
};

export default Filter;
