import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

type Props = {
  title: string;
  actions: Array<React.ReactNode>;
  routeBack: string;
};

const LogsHeader: FC<Props> = ({ title, actions, routeBack }) => {
  const navigate = useNavigate();

  return (
    <header className={styles.header}>
      <div
        className={styles.header_left}
        onClick={() => {
          if (window.history.length > 2) {
            navigate(-1);
          } else {
            navigate(routeBack);
          }
        }}
      >
        <div className={styles.header_left_icon}>
          <ICONS.Back />
        </div>
        <h2 className={styles.header_left_title}>{title}</h2>
      </div>

      <div className={styles.header_right}>
        {actions.map((action, index) => (
          <React.Fragment key={index}>
            {action === null ? null : (
              <div className={styles.header_right_item}>{action}</div>
            )}
          </React.Fragment>
        ))}
      </div>
    </header>
  );
};

export default LogsHeader;
