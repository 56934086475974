import { FC, useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import styles from "./styles.module.scss";

import { UserAPI } from "api";

import { ICONS } from "../../../assets";

import {
  Header,
  Button,
  SearchInput,
  TableHead,
  DotSelector,
  ConfirmModal,
  AddUserModal,
  Status,
  Pagination,
  TableLoading,
  EmptyListTitle,
  FreeAccessModal
} from "components";
import Filt from "./Filter";

import { usePagination } from "hooks";
import { toggleLoading } from "store/slices/app";

import { OrderBy, VariantButton } from "enums";
import { toast } from "react-toastify";

const LIMIT = 10;
const FAKE_USERS = [
  {
    id: 1,
    source: "Google",
    name: "Anastasia",
    email: "melisa.fergusson@gmail.com",
    sid: "09/09/2022",
    lad: "09/09/2022",
    lsd: "09/09/2022",
    lrd: "09/09/2022",
    os: "Android",
    status: true,
    as: false,
    fa: "09/09/2022",
    sd: "09/09/2022",
    psd: "09/09/2022",
    cfta: false,
    stage: "W3D4"
  },
  {
    id: 1,
    source: "Google",
    name: "Anastasia",
    email: "melisa.fergusson@gmail.com",
    sid: "09/09/2022",
    lad: "09/09/2022",
    lsd: "09/09/2022",
    lrd: "09/09/2022",
    os: "Android",
    status: true,
    as: false,
    fa: "09/09/2022",
    sd: "09/09/2022",
    psd: "09/09/2022",
    cfta: false,
    stage: "W3D4"
  },
  {
    id: 1,
    source: "Google",
    name: "Anastasia",
    email: "melisa.fergusson@gmail.com",
    sid: "09/09/2022",
    lad: "09/09/2022",
    lsd: "09/09/2022",
    lrd: "09/09/2022",
    os: "Android",
    status: true,
    as: false,
    fa: "09/09/2022",
    sd: "09/09/2022",
    psd: "09/09/2022",
    cfta: false,
    stage: "W3D4"
  },
  {
    id: 1,
    source: "Google",
    name: "Anastasia",
    email: "melisa.fergusson@gmail.com",
    sid: "09/09/2022",
    lad: "09/09/2022",
    lsd: "09/09/2022",
    lrd: "09/09/2022",
    os: "Android",
    status: true,
    as: false,
    fa: "09/09/2022",
    sd: "09/09/2022",
    psd: "09/09/2022",
    cfta: false,
    stage: "W3D4"
  },
  {
    id: 1,
    source: "Google",
    name: "Anastasia",
    email: "melisa.fergusson@gmail.com",
    sid: "09/09/2022",
    lad: "09/09/2022",
    lsd: "09/09/2022",
    lrd: "09/09/2022",
    os: "Android",
    status: true,
    as: false,
    fa: "09/09/2022",
    sd: "09/09/2022",
    psd: "09/09/2022",
    cfta: false,
    stage: "W3D4"
  },
  {
    id: 1,
    source: "Google",
    name: "Anastasia",
    email: "melisa.fergusson@gmail.com",
    sid: "09/09/2022",
    lad: "09/09/2022",
    lsd: "09/09/2022",
    lrd: "09/09/2022",
    os: "Android",
    status: true,
    as: false,
    fa: "09/09/2022",
    sd: "09/09/2022",
    psd: "09/09/2022",
    cfta: false,
    stage: "W3D4"
  },
  {
    id: 1,
    source: "Google",
    name: "Anastasia",
    email: "melisa.fergusson@gmail.com",
    sid: "09/09/2022",
    lad: "09/09/2022",
    lsd: "09/09/2022",
    lrd: "09/09/2022",
    os: "Android",
    status: true,
    as: false,
    fa: "09/09/2022",
    sd: "09/09/2022",
    psd: "09/09/2022",
    cfta: false,
    stage: "W3D4"
  },
  {
    id: 1,
    source: "Google",
    name: "Anastasia",
    email: "melisa.fergusson@gmail.com",
    sid: "09/09/2022",
    lad: "09/09/2022",
    lsd: "09/09/2022",
    lrd: "09/09/2022",
    os: "Android",
    status: true,
    as: false,
    fa: "09/09/2022",
    sd: "09/09/2022",
    psd: "09/09/2022",
    cfta: false,
    stage: "W3D4"
  },
  {
    id: 1,
    source: "Google",
    name: "Anastasia",
    email: "melisa.fergusson@gmail.com",
    sid: "09/09/2022",
    lad: "09/09/2022",
    lsd: "09/09/2022",
    lrd: "09/09/2022",
    os: "Android",
    status: true,
    as: false,
    fa: "09/09/2022",
    sd: "09/09/2022",
    psd: "09/09/2022",
    cfta: false,
    stage: "W3D4"
  },
  {
    id: 1,
    source: "Google",
    name: "Anastasia",
    email: "melisa.fergusson@gmail.com",
    sid: "09/09/2022",
    lad: "09/09/2022",
    lsd: "09/09/2022",
    lrd: "09/09/2022",
    os: "Android",
    status: true,
    as: false,
    fa: "09/09/2022",
    sd: "09/09/2022",
    psd: "09/09/2022",
    cfta: false,
    stage: "W3D4"
  },
  {
    id: 1,
    source: "Google",
    name: "Anastasia",
    email: "melisa.fergusson@gmail.com",
    sid: "09/09/2022",
    lad: "09/09/2022",
    lsd: "09/09/2022",
    lrd: "09/09/2022",
    os: "Android",
    status: true,
    as: false,
    fa: "09/09/2022",
    sd: "09/09/2022",
    psd: "09/09/2022",
    cfta: false,
    stage: "W3D4"
  },
  {
    id: 1,
    source: "Google",
    name: "Anastasia",
    email: "melisa.fergusson@gmail.com",
    sid: "09/09/2022",
    lad: "09/09/2022",
    lsd: "09/09/2022",
    lrd: "09/09/2022",
    os: "Android",
    status: true,
    as: false,
    fa: "09/09/2022",
    sd: "09/09/2022",
    psd: "09/09/2022",
    cfta: false,
    stage: "W3D4"
  },
  {
    id: 1,
    source: "Google",
    name: "Anastasia",
    email: "melisa.fergusson@gmail.com",
    sid: "09/09/2022",
    lad: "09/09/2022",
    lsd: "09/09/2022",
    lrd: "09/09/2022",
    os: "Android",
    status: true,
    as: false,
    fa: "09/09/2022",
    sd: "09/09/2022",
    psd: "09/09/2022",
    cfta: false,
    stage: "W3D4"
  },
  {
    id: 1,
    source: "Google",
    name: "Anastasia",
    email: "melisa.fergusson@gmail.com",
    sid: "09/09/2022",
    lad: "09/09/2022",
    lsd: "09/09/2022",
    lrd: "09/09/2022",
    os: "Android",
    status: true,
    as: false,
    fa: "09/09/2022",
    sd: "09/09/2022",
    psd: "09/09/2022",
    cfta: false,
    stage: "W3D4"
  }
];

const Users: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scrollDivRef = useRef<HTMLDivElement | null>(null);

  const [deleteModal, setDeleteModal] = useState<any>(false);
  const [anonymizeModal, setAnonymizeModal] = useState<any>(false);
  const [blockModal, setBlockModal] = useState<any>(false);
  const [createUserModal, setCreateUserModal] = useState<any>(false);
  const [freeAccessModal, setFreeAccessModal] = useState<any>(false);

  const itemsRef = useRef<any>([]);
  const [, setHeights] = useState<any>([]);

  const fetchData = async (page: number, take: number, filter: any) => {
    const updatedFilter = { ...filter };
    delete updatedFilter.search;
    delete updatedFilter.sorting;

    const filterArr = Object.keys(updatedFilter).map(function (key) {
      return {
        field: key,
        dateRange: {
          from: updatedFilter[key].from,
          to: updatedFilter[key].to
        }
      };
    });

    const filterData: any = { filter: filterArr };

    if (filter.sorting) {
      filterData.sorting = filter.sorting;
    }

    if (filter.search) {
      filterData.search = filter.search;
    }

    const resp = await UserAPI.getUsers(page, take, filterData);
    return resp;
  };

  const {
    isLoading,
    data,
    page,
    filter,
    onChangeFilter,
    onChangePage,
    onRefresh,
    onChangeSearch
  } = usePagination<
    {
      data: Array<any>;
      meta: { itemsCount: number };
    },
    {
      sorting: {
        sortBy: any | null;
        order: OrderBy | null;
      } | null;
      search: string | null;
      SID: {
        from: Date | null;
        to: Date | null;
      } | null;
      LAD: {
        from: Date | null;
        to: Date | null;
      } | null;
      LSD: {
        from: Date | null;
        to: Date | null;
      } | null;
      LRD: {
        from: Date | null;
        to: Date | null;
      } | null;
      FA: {
        from: Date | null;
        to: Date | null;
      } | null;
      SD: {
        from: Date | null;
        to: Date | null;
      } | null;
      PSD: {
        from: Date | null;
        to: Date | null;
      } | null;
    }
  >(fetchData, LIMIT);

  useEffect(() => {
    setHeights(itemsRef.current.map((i: any) => i?.clientHeight));
  }, [isLoading]);

  const handleCreateUpdateUser = async (userData: any) => {
    try {
      dispatch(toggleLoading(true));

      if (userData.id) {
        // Edit user should be here
        await UserAPI.editUser(userData.id, {
          email: userData.email,
          firstName: userData.firstName
        });
        await onRefresh();
        toast("Updated successfully.", {
          type: "success"
        });
      } else {
        await UserAPI.createUser(userData.firstName, userData.email);
        await onRefresh();
        toast("The user has been created.", {
          type: "success"
        });
      }

      setCreateUserModal(false);
    } catch (e: any) {
      toast(e.response.data.message, {
        type: "error"
      });
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const handleDeleteModal = async () => {
    try {
      dispatch(toggleLoading(true));
      if (!deleteModal?.ID) return;

      await UserAPI.deleteUser(deleteModal.ID);
      await onRefresh();
      toast("Deleted successfully.", {
        type: "success"
      });

      setDeleteModal(false);
    } catch (e: any) {
      toast(e.response.data.message, {
        type: "error"
      });
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const handleBlockUnblockUser = async () => {
    try {
      dispatch(toggleLoading(true));
      if (!blockModal?.ID) return;

      await UserAPI.blockUnblockUser(blockModal.ID, !blockModal.ST);
      await onRefresh();
      toast(`${!blockModal.ST ? "Activated" : "Deactivated"} successfully.`, {
        type: "success"
      });

      setBlockModal(false);
    } catch (e: any) {
      toast(e.response.data.message, {
        type: "error"
      });
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const exportTable = async () => {
    try {
      dispatch(toggleLoading(true));
      const response = await UserAPI.getUserExport();
      const blob = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${moment().format("YYYY-MM-DDT_hh_mm_ss")}_users.xlsx`;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (e: any) {
      toast(e.response.data.message, {
        type: "error"
      });
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const handleUpdateFreeAccess = async (date: null | Date) => {
    try {
      dispatch(toggleLoading(true));
      if (date === null || !freeAccessModal?.ID) return;

      await UserAPI.addFreeAcces(freeAccessModal?.ID, date);
      await onRefresh();
      toast(`Added successfully.`, {
        type: "success"
      });

      setFreeAccessModal(false);
    } catch (e: any) {
      toast(e.response.data.message, {
        type: "error"
      });
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const handleAnonymizeUser = async () => {
    try {
      dispatch(toggleLoading(true));
      if (!anonymizeModal?.ID) return;

      await UserAPI.anonimyzeUser(anonymizeModal.ID);
      await onRefresh();
      toast(`Anonymize successfully.`, {
        type: "success"
      });

      setAnonymizeModal(false);
    } catch (e: any) {
      toast(e.response.data.message, {
        type: "error"
      });
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const isEmailAnonymized = (email: string): boolean => {
    const pattern = /^([^@]+)@([^@]+)\.([a-zA-Z]{2,})-?(\d*)$/;
    const match = email.match(pattern);

    if (match) {
      const [, , , , id] = match;

      return !!id;
    }

    return false;
  };

  const renderUsers = () => {
    if (isLoading) return null;

    if (!data?.data) return null;

    if (data.data.length === 0) return <EmptyListTitle title="No users yet" />;

    return data?.data.map((user, index) => (
      <div
        key={user.ID}
        ref={(el) => (itemsRef.current[index] = el)}
        className={styles.wrapper_item}
        onClick={() => navigate(`${user.ID}/logs`)}>
        <div className={styles.wrapper_item_id}>{user.ID}</div>
        <div className={styles.wrapper_item_source}>{user.Source || "-"}</div>
        <div className={styles.wrapper_item_name}>{user.FN}</div>
        <div className={styles.wrapper_item_email}>{user.Email}</div>
        <div className={styles.wrapper_item_sid}>
          {user.SID ? moment(user.SID).format("MM/DD/YYYY") : "-"}
        </div>
        <div className={styles.wrapper_item_lad}>
          {user.LAD ? moment(user.LAD).format("MM/DD/YYYY") : "-"}
        </div>
        <div className={styles.wrapper_item_lsd}>
          {user.LSD ? moment(user.LSD).format("MM/DD/YYYY") : "-"}
        </div>
        <div className={styles.wrapper_item_lrd}>
          {user.LRD ? moment(user.LRD).format("MM/DD/YYYY") : "-"}
        </div>
        <div className={styles.wrapper_item_os}>
          {user.OS ? (user.OS === "ios" ? "iOS" : "Android") : "-"}
        </div>
        <div className={styles.wrapper_item_status}>
          <Status status={user.ST} />
        </div>
        <div className={styles.wrapper_item_as}>
          <Status status={user.AS} />
        </div>
        <div className={styles.wrapper_item_fa}>
          {user.FA ? moment(user.FA).format("MM/DD/YYYY") : "-"}
        </div>
        <div className={styles.wrapper_item_sd}>
          {user.SD ? moment(user.SD).format("MM/DD/YYYY") : "-"}
        </div>
        <div className={styles.wrapper_item_psd}>
          {user.PSD ? moment(user.PSD).format("MM/DD/YYYY") : "-"}
        </div>
        <div className={styles.wrapper_item_cfta}>
          <Status status={user.CFTA} />
        </div>
        <div className={styles.wrapper_item_stage}>{user.Stage || "-"}</div>
      </div>
    ));
  };

  return (
    <div>
      <Header
        title="Users"
        helper={data?.meta ? `(${data.meta.itemsCount})` : undefined}
        buttons={[
          <Button
            title="Add User"
            icon={<ICONS.Plus />}
            onClick={() =>
              setCreateUserModal({
                name: "",
                email: ""
              })
            }
          />
        ]}
      />

      <div className="shadow_box">
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_search}>
            <div className={styles.wrapper_header_search_wrapper}>
              <SearchInput
                value={filter?.search || ""}
                onChange={(search) => {
                  onChangeSearch(search.trim() === "" ? null : search);
                }}
              />
            </div>
          </div>

          <div className={styles.wrapper_header_button}>
            <Button
              title="Export XLSX"
              variant={VariantButton.Transparent}
              icon={<ICONS.ExportFile />}
              onClick={exportTable}
            />
          </div>

          <div>
            <Filt
              filter={filter}
              onChange={(newFilter) =>
                onChangeFilter({
                  ...filter,
                  ...newFilter
                })
              }
              onClear={() => {
                //@ts-ignore
                onChangeFilter({
                  SID: null,
                  LAD: null,
                  LSD: null,
                  LRD: null,
                  FA: null,
                  SD: null,
                  PSD: null
                });
              }}
            />
          </div>
        </header>

        <div
          style={{
            minHeight: isLoading ? "300px" : "max-content"
          }}
          className={styles.wrapper_container}>
          <div
            className={styles.wrapper_table}
            ref={scrollDivRef}
            style={{
              overflowX: isLoading ? "hidden" : "auto"
            }}>
            <TableHead
              containerStyle={{
                width: "max-content"
              }}
              items={[
                {
                  label: "ID",
                  sortedBy: "ID",
                  style: { minWidth: "106px" }
                },
                {
                  label: "Source",
                  sortedBy: "Source",
                  style: { minWidth: "80px" }
                },
                {
                  label: "Name",
                  sortedBy: "FN",
                  style: { minWidth: "100px" }
                },
                {
                  label: "Email",
                  sortedBy: "Email",
                  style: { minWidth: "160px" }
                },
                {
                  label: "SID",
                  sortedBy: "SID",
                  popupText: "Sign in Date ",
                  style: { minWidth: "100px" }
                },
                {
                  label: "LAD",
                  sortedBy: "LAD",
                  popupText: "Last Audio Date",
                  style: { minWidth: "100px" }
                },
                {
                  label: "LSD",
                  sortedBy: "LSD",
                  popupText: "Last Sleep audio Date",
                  style: { minWidth: "100px" }
                },
                {
                  label: "LRD",
                  sortedBy: "LRD",
                  popupText: "Last Reading Date",
                  style: { minWidth: "100px" }
                },
                {
                  label: "OS",
                  sortedBy: "OS",
                  popupText: "Operating System",
                  style: { minWidth: "100px" }
                },
                {
                  label: "ST",
                  sortedBy: "ST",
                  popupText: "Status",
                  style: {
                    justifyContent: "center",
                    minWidth: "100px"
                  }
                },
                {
                  label: "AS",
                  sortedBy: "AS",
                  popupText: "Active Subscription",
                  style: {
                    justifyContent: "center",
                    minWidth: "100px"
                  }
                },
                {
                  label: "FA",
                  sortedBy: "FA",
                  popupText: "Free Access",
                  style: { minWidth: "100px" }
                },
                {
                  label: "SD",
                  sortedBy: "SD",
                  popupText: "Active Subscription",
                  style: { minWidth: "100px" }
                },
                {
                  label: "PSD",
                  sortedBy: "PSD",
                  popupText: "Program Start Date",
                  style: { minWidth: "100px" }
                },
                {
                  label: "CFTA",
                  sortedBy: "CFTA",
                  popupText: "Completed Free Trial/Access",
                  style: { minWidth: "100px" }
                },
                {
                  label: "Stage",
                  sortedBy: "Stage",
                  popupText: "Program Stage",
                  style: { minWidth: "130px" }
                }
              ]}
              flexes={[]}
              sortOptions={
                filter?.sorting || {
                  sortBy: null,
                  order: null
                }
              }
              onChangeSorted={(sorting) => {
                onChangeFilter({
                  ...filter,
                  sorting
                });
              }}
            />

            {renderUsers()}
          </div>

          {!isLoading && (
            <div className={styles.wrapper_actions}>
              {data?.data.map((_, index) => {
                const height = itemsRef.current?.[index]?.clientHeight
                  ? `${itemsRef.current?.[index]?.clientHeight + 1}px`
                  : "auto";
                const user = data?.data[index];

                return (
                  <div
                    key={user.ID}
                    className={styles.wrapper_actions_item}
                    style={{
                      zIndex: FAKE_USERS.length - index,
                      height: height
                    }}>
                    <DotSelector
                      items={[
                        {
                          label: "Review logs",
                          icon: <ICONS.ReviewLogs />,
                          onClick: () => navigate(`${user.ID}/logs?tab=1`)
                        },
                        {
                          label: "Edit",
                          icon: <ICONS.Edit />,
                          onClick: () => setCreateUserModal(user)
                        },
                        {
                          label: user.ST ? "Block" : "Unblock",
                          icon: <ICONS.Block />,
                          style: {
                            borderTop: "1px solid #E6E6E9"
                          },
                          onClick: () => setBlockModal(user)
                        },
                        !isEmailAnonymized(user.Email)
                          ? {
                              label: "Anonymize",
                              icon: <ICONS.Anonymize />,
                              onClick: () => setAnonymizeModal(user)
                            }
                          : null,
                        {
                          label: "Delete",
                          icon: <ICONS.Delete />,
                          textStyle: {
                            color: "#FF0033"
                          },
                          onClick: () => setDeleteModal(user)
                        },
                        {
                          label: "Free access",
                          icon: <ICONS.FreeAccess height={15} />,
                          style: {
                            borderTop: "1px solid #E6E6E9"
                          },
                          textStyle: {
                            color: "#16C350"
                          },
                          onClick: () => setFreeAccessModal(user)
                        }
                      ]}
                    />
                  </div>
                );
              })}
            </div>
          )}

          {isLoading && (
            <div className={styles.wrapper_loading}>
              <TableLoading />
            </div>
          )}
        </div>

        {data?.meta.itemsCount && data?.meta.itemsCount !== 0 ? (
          <div className={styles.wrapper_pagination}>
            <Pagination
              count={data.meta.itemsCount}
              limit={LIMIT}
              currentPage={page}
              onChange={(page) => {
                onChangePage(page);
                if (scrollDivRef.current) scrollDivRef.current.scrollLeft = 0;
              }}
            />
          </div>
        ) : null}
      </div>

      <ConfirmModal
        isOpen={!!deleteModal}
        title="Delete User"
        description={deleteModal ? `Delete ${deleteModal.FN}` : undefined}
        leftBtnText="Cancel"
        rightBtnText="Delete"
        onClose={() => setDeleteModal(false)}
        onConfirm={handleDeleteModal}
      />
      <ConfirmModal
        isOpen={!!anonymizeModal}
        title="Anonymize User"
        description={
          anonymizeModal ? `Anonymize ${anonymizeModal.FN}` : undefined
        }
        leftBtnText="Cancel"
        rightBtnText="Anonymize"
        onClose={() => setAnonymizeModal(false)}
        onConfirm={handleAnonymizeUser}
      />
      <ConfirmModal
        isOpen={!!blockModal}
        title={`${blockModal?.ST ? "Block" : "Unblock"} User`}
        description={
          blockModal
            ? `${blockModal?.ST ? "Block" : "Unblock"} ${blockModal.FN}`
            : undefined
        }
        leftBtnText="Cancel"
        rightBtnText={blockModal?.ST ? "Block" : "Unblock"}
        onClose={() => setBlockModal(false)}
        onConfirm={handleBlockUnblockUser}
      />

      <FreeAccessModal
        payload={freeAccessModal}
        onClose={() => setFreeAccessModal(false)}
        onCreate={handleUpdateFreeAccess}
      />
      <AddUserModal
        payload={createUserModal}
        onClose={() => setCreateUserModal(false)}
        onCreateUpdateUser={handleCreateUpdateUser}
      />
    </div>
  );
};

export default Users;
