import { FC } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";

type Props = {
  value: boolean;
  label: string;
  subLabel?: string;
  onChange: (value: boolean) => void;
};

const Switcher: FC<Props> = ({ value, label, subLabel, onChange }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_info}>
        <p className={styles.wrapper_info_label}>{label}</p>
        {subLabel && <p className={styles.wrapper_info_sublabel}>{subLabel}</p>}
      </div>

      <div
        className={cls(styles.wrapper_switcher, {
          [styles.wrapper_switcher_active]: value
        })}
        onClick={() => onChange(!value)}
      >
        <div
          className={cls(styles.wrapper_circle, {
            [styles.wrapper_circle_active]: value
          })}
        />
      </div>
    </div>
  );
};

export default Switcher;
