import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import styles from "./styles.module.scss";

import { FileAPI, UserAPI } from "api";

import { ICONS } from "assets";

import {
  LogsHeader,
  Button,
  Tabs,
  EmptyListTitle,
  ConfirmModal,
  AddUserModal,
  FreeAccessModal
} from "components";
import { MainInfo, Audio, Articles } from "./Tabs";

import { withRoleAuthorization } from "hoc";
import { usePagination, useTab } from "hooks";
import { toggleLoading } from "store/slices/app";

import { AdminRole, VariantButton } from "enums";
import { toast } from "react-toastify";

const UserLogs: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentTab, onChange: onChangeTab } = useTab();

  //   const [currentTab, setCurrentTab] = useState<number>(0);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<any>(false);
  const [blockModal, setBlockModal] = useState<any>(false);
  const [anonymizeModal, setAnonymizeModal] = useState<any>(false);
  const [user, setUser] = useState<null | any>(null);
  const [createUserModal, setCreateUserModal] = useState<any>(false);
  const [freeAccessModal, setFreeAccessModal] = useState<any>(false);

  const fetchAudioData = async (page: number, take: number) => {
    const resp = await FileAPI.getUserFileSession(
      "audio",
      Number(id),
      page,
      take,
      {}
    );
    return resp;
  };

  const fetchArticleData = async (page: number, take: number) => {
    const resp = await FileAPI.getUserFileSession(
      "article",
      Number(id),
      page,
      take,
      {}
    );
    return resp;
  };

  const { data: audioData } = usePagination<{
    data: any;
    meta: { itemsCount: number };
  }>(fetchAudioData, 1);

  const { data: articleData } = usePagination<{
    data: any;
    meta: { itemsCount: number };
  }>(fetchArticleData, 1);

  const fetchUser = async (loading = true) => {
    try {
      if (loading) setIsLoading(true);

      const { user } = await UserAPI.getUser(Number(id));

      setUser(user);
    } catch (e) {
      alert("Error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const handleDeleteModal = async () => {
    try {
      dispatch(toggleLoading(true));
      if (!deleteModal?.ID) return;

      await UserAPI.deleteUser(deleteModal.ID);
      toast("Deleted successfully.", {
        type: "success"
      });

      setDeleteModal(false);
      navigate(-1);
    } catch (e: any) {
      toast(e.response.data.message, {
        type: "error"
      });
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const handleBlockUnblockUser = async () => {
    try {
      dispatch(toggleLoading(true));
      if (!blockModal?.ID) return;

      await UserAPI.blockUnblockUser(blockModal.ID, !blockModal.ST);
      setUser({ ...user, ST: !user.ST });
      toast(`${!blockModal.ST ? "Activated" : "Deactivated"} successfully.`, {
        type: "success"
      });

      setBlockModal(false);
    } catch (e: any) {
      toast(e.response.data.message, {
        type: "error"
      });
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const handleEditUser = async (userData: any) => {
    try {
      dispatch(toggleLoading(true));

      if (userData.id) {
        // Edit user should be here
        await UserAPI.editUser(userData.id, {
          email: userData.email,
          firstName: userData.firstName
        });
        setUser({
          ...user,
          FN: userData.firstName,
          Email: userData.email
        });
        toast("Updated successfully.", {
          type: "success"
        });
      }

      setCreateUserModal(false);
    } catch (e: any) {
      toast(e.response.data.message, {
        type: "error"
      });
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const handleAnonymizeUser = async () => {
    try {
      dispatch(toggleLoading(true));
      if (!anonymizeModal?.ID) return;

      await UserAPI.anonimyzeUser(anonymizeModal.ID);
      await fetchUser(false);
      toast(`Anonymize successfully.`, {
        type: "success"
      });

      setAnonymizeModal(false);
    } catch (e: any) {
      toast(e.response.data.message, {
        type: "error"
      });
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const handleUpdateFreeAccess = async (date: null | Date) => {
    try {
      dispatch(toggleLoading(true));
      if (date === null || !freeAccessModal?.ID) return;

      await UserAPI.addFreeAcces(freeAccessModal?.ID, date);
      await fetchUser(false);
      toast(`Added successfully.`, {
        type: "success"
      });

      setFreeAccessModal(false);
    } catch (e: any) {
      toast(e.response.data.message, {
        type: "error"
      });
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const isEmailAnonymized = (email: string): boolean => {
    const pattern = /^([^@]+)@([^@]+)\.([a-zA-Z]{2,})-?(\d*)$/;
    const match = email.match(pattern);

    if (match) {
      const [, , , , id] = match;

      return !!id;
    }

    return false;
  };

  const renderTab = () => {
    switch (currentTab) {
      case 0:
        return <MainInfo user={user} />;
      case 1:
        return <Audio userId={Number(id)} />;
      case 2:
        return <Articles userId={Number(id)} />;
      default:
        return <MainInfo user={user} />;
    }
  };

  const tabs = useMemo(() => {
    return [
      { title: "Main Info" },
      {
        title: "Audio",
        count: audioData?.meta?.itemsCount ?? undefined
      },
      {
        title: "Articles",
        count: articleData?.meta?.itemsCount ?? undefined
      }
    ];
  }, [audioData, articleData]);

  if (isLoading)
    return (
      <div className={styles.loading}>
        <ClipLoader size={50} />
      </div>
    );

  if (!user) return <EmptyListTitle title="Something went wrong" />;

  return (
    <div>
      <LogsHeader
        title="User Profile"
        routeBack="/audio"
        actions={[
          <Button
            title="Edit"
            variant={VariantButton.Transparent}
            icon={<ICONS.Edit />}
            onClick={() => setCreateUserModal(user)}
          />,
          <Button
            title={user.ST ? "Block" : "Unblock"}
            variant={VariantButton.Transparent}
            icon={<ICONS.Block />}
            onClick={() => setBlockModal(user)}
          />,
          !isEmailAnonymized(user.Email) ? (
            <Button
              title="Anonymize"
              variant={VariantButton.Transparent}
              icon={<ICONS.Anonymize />}
              onClick={() => setAnonymizeModal(user)}
            />
          ) : null,
          <Button
            title="Delete"
            variant={VariantButton.RedTransparent}
            icon={<ICONS.Delete fill="red" />}
            onClick={() => setDeleteModal(user)}
          />,
          <Button
            title="Free Access"
            variant={VariantButton.GreenTransparent}
            icon={<ICONS.FreeAccess />}
            onClick={() => setFreeAccessModal(user)}
          />
        ]}
      />

      <div className="shadow_box">
        <h3 className={styles.wrapper_name}>{user.FN}</h3>

        <div className={styles.wrapper_tabs}>
          <Tabs tabs={tabs} currentIndex={currentTab} onChange={onChangeTab} />
        </div>

        {renderTab()}
      </div>

      <ConfirmModal
        isOpen={!!deleteModal}
        title="Delete User"
        description={deleteModal ? `Delete ${deleteModal.FN}` : undefined}
        leftBtnText="Cancel"
        rightBtnText="Delete"
        onClose={() => setDeleteModal(false)}
        onConfirm={handleDeleteModal}
      />

      <ConfirmModal
        isOpen={!!blockModal}
        title={`${blockModal?.ST ? "Block" : "Unblock"} User`}
        description={
          blockModal
            ? `${blockModal?.ST ? "Block" : "Unblock"} ${blockModal.FN}`
            : undefined
        }
        leftBtnText="Cancel"
        rightBtnText={blockModal?.ST ? "Block" : "Unblock"}
        onClose={() => setBlockModal(false)}
        onConfirm={handleBlockUnblockUser}
      />

      <ConfirmModal
        isOpen={!!anonymizeModal}
        title="Anonymize User"
        description={
          anonymizeModal ? `Anonymize ${anonymizeModal.FN}` : undefined
        }
        leftBtnText="Cancel"
        rightBtnText="Anonymize"
        onClose={() => setAnonymizeModal(false)}
        onConfirm={handleAnonymizeUser}
      />

      <FreeAccessModal
        payload={freeAccessModal}
        onClose={() => setFreeAccessModal(false)}
        onCreate={handleUpdateFreeAccess}
      />

      <AddUserModal
        payload={createUserModal}
        onClose={() => setCreateUserModal(false)}
        onCreateUpdateUser={handleEditUser}
      />
    </div>
  );
};

export default withRoleAuthorization([AdminRole.Admin])(UserLogs);
