import { FC, useRef, useState, useEffect, useMemo } from "react";
import styles from "./styles.module.scss";

import { Filter as FilterComponents } from "components";

import { useOutsideClick } from "hooks";

type Props = {
  filter: any;
  onChange: (value: any) => void;
  onClear: () => void;
};

const Filter: FC<Props> = ({ filter: initialFilter, onChange, onClear }) => {
  const [filter, setFilter] = useState<any>(initialFilter);
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpenFilter) {
      setFilter(initialFilter);
    }
  }, [isOpenFilter, initialFilter]);

  const divRef = useRef(null);
  useOutsideClick({
    ref: divRef,
    onClickOutside: () => setIsOpenFilter(false)
  });

  const handleApplyFilter = () => {
    onChange(filter);
    setIsOpenFilter(false);
  };

  const handleClearFilter = () => {
    onClear();
    setIsOpenFilter(false);
  };

  const disableApply = useMemo(() => {
    if (
      (filter?.SID?.from && !filter?.SID?.to) ||
      (filter?.SID?.to && !filter?.SID?.from)
    )
      return true;

    if (
      (filter?.LAD?.from && !filter?.LAD?.to) ||
      (filter?.LAD?.to && !filter?.LAD?.from)
    )
      return true;

    if (
      (filter?.LSD?.from && !filter?.LSD?.to) ||
      (filter?.LSD?.to && !filter?.LSD?.from)
    )
      return true;

    if (
      (filter?.LRD?.from && !filter?.LRD?.to) ||
      (filter?.LRD?.to && !filter?.LRD?.from)
    )
      return true;

    if (
      (filter?.FA?.from && !filter?.FA?.to) ||
      (filter?.FA?.to && !filter?.FA?.from)
    )
      return true;

    if (
      (filter?.SD?.from && !filter?.SD?.to) ||
      (filter?.SD?.to && !filter?.SD?.from)
    )
      return true;

    if (
      (filter?.PSD?.from && !filter?.PSD?.to) ||
      (filter?.PSD?.to && !filter?.PSD?.from)
    )
      return true;

    const isDisable = Object.keys(filter)
      .filter((key) => {
        return (
          filter[key] !== "page" &&
          filter[key] !== "search" &&
          filter[key] !== "sorting"
        );
      })
      .every((key) => {
        return !filter[key]?.from && !filter[key]?.to;
      });

    return isDisable;
  }, [filter]);

  return (
    <div ref={divRef} className={styles.wrapper}>
      <div
        className={styles.wrapper_icon}
        onClick={() => setIsOpenFilter(!isOpenFilter)}
      >
        <FilterComponents.Wrapper />
      </div>

      {isOpenFilter && (
        <div className={styles.wrapper_filter}>
          <FilterComponents.Container
            disableApply={disableApply}
            onApply={handleApplyFilter}
            onClear={handleClearFilter}
          >
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.DateRange
                value={filter.SID || null}
                title="Sign in Date"
                //@ts-ignore
                position={["Left", "Right"]}
                onChange={(dateRange) =>
                  setFilter({
                    ...filter,
                    SID: dateRange
                  })
                }
              />
            </div>
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.DateRange
                value={filter.LAD || null}
                title="Last Audio Date"
                //@ts-ignore
                position={["Left", "Right"]}
                onChange={(dateRange) =>
                  setFilter({
                    ...filter,
                    LAD: dateRange
                  })
                }
              />
            </div>
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.DateRange
                value={filter.LSD || null}
                title="Last Sleep audio Date"
                //@ts-ignore
                position={["Left", "Right"]}
                onChange={(dateRange) =>
                  setFilter({
                    ...filter,
                    LSD: dateRange
                  })
                }
              />
            </div>
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.DateRange
                value={filter.LRD || null}
                title="Last Reading Date"
                //@ts-ignore
                position={["Left", "Right"]}
                onChange={(dateRange) =>
                  setFilter({
                    ...filter,
                    LRD: dateRange
                  })
                }
              />
            </div>
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.DateRange
                value={filter.FA || null}
                title="Free Access"
                //@ts-ignore
                position={["Left", "Right"]}
                onChange={(dateRange) =>
                  setFilter({
                    ...filter,
                    FA: dateRange
                  })
                }
              />
            </div>
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.DateRange
                value={filter.SD || null}
                title="Active Subscription"
                //@ts-ignore
                position={["Left", "Right"]}
                onChange={(dateRange) =>
                  setFilter({
                    ...filter,
                    SD: dateRange
                  })
                }
              />
            </div>
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.DateRange
                value={filter.PSD || null}
                title="Program Start Date"
                //@ts-ignore
                position={["Left", "Right"]}
                onChange={(dateRange) =>
                  setFilter({
                    ...filter,
                    PSD: dateRange
                  })
                }
              />
            </div>
          </FilterComponents.Container>
        </div>
      )}
    </div>
  );
};

export default Filter;
