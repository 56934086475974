import { FC } from "react";
import styles from "./styles.module.scss";

import { Label, Error } from "components";

type Props = {
  value: string;
  label?: string;
  placeholder?: string;
  error?: string;
  maxLength?: number;
  onChange: (value: string) => void;
};

const Textarea: FC<Props> = ({
  value,
  placeholder = "",
  error,
  label,
  maxLength,
  onChange
}) => {
  const handleChange = (newVal: string) => {
    if (maxLength && newVal.trim().length > maxLength) return;
    onChange(newVal);
  };

  return (
    <div>
      {label && <Label text={label} />}

      <textarea
        value={value}
        placeholder={placeholder}
        className={styles.textarea}
        onChange={(e) => handleChange(e.target.value)}
      />

      {error && <Error message={error} />}
    </div>
  );
};

export default Textarea;
