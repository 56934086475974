import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, NavLink, useLocation, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { AudioPlayer } from "components";
import ProfileBlock from "./ProfileBlock";

import { useUser } from "hooks";

import { AdminRole } from "enums";
import { RootState } from "store";

const Main: FC = () => {
  const { user } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const { audio } = useSelector((store: RootState) => store.player);

  useEffect(() => {
    if (location.pathname === "/") {
      navigate(user?.role === AdminRole.Admin ? "/users" : "/banners");
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_menu}>
        <div className={styles.wrapper_menu_logo}>
          <p>EverCalm</p>
        </div>
        <nav className={styles.wrapper_menu_nav}>
          {user?.role === AdminRole.Admin && (
            <>
              <NavLink
                to="/users"
                className={({ isActive }) =>
                  `${styles.wrapper_menu_nav_item} ${
                    isActive && styles.wrapper_menu_nav_item_active
                  }`
                }>
                <div className={styles.wrapper_menu_nav_item_icon}>
                  <ICONS.nav.Users />
                </div>
                <p className={styles.wrapper_menu_nav_item_text}>Users</p>
              </NavLink>
              <NavLink
                to="/audio"
                className={({ isActive }) =>
                  `${styles.wrapper_menu_nav_item} ${
                    isActive && styles.wrapper_menu_nav_item_active
                  }`
                }>
                <div className={styles.wrapper_menu_nav_item_icon}>
                  <ICONS.nav.Audio />
                </div>
                <p className={styles.wrapper_menu_nav_item_text}>Audios</p>
              </NavLink>
              <NavLink
                to="/articles"
                className={({ isActive }) =>
                  `${styles.wrapper_menu_nav_item} ${
                    isActive && styles.wrapper_menu_nav_item_active
                  }`
                }>
                <div className={styles.wrapper_menu_nav_item_icon}>
                  <ICONS.nav.Articles />
                </div>
                <p className={styles.wrapper_menu_nav_item_text}>Articles</p>
              </NavLink>
            </>
          )}
          <NavLink
            to="/banners"
            className={({ isActive }) =>
              `${styles.wrapper_menu_nav_item} ${
                isActive && styles.wrapper_menu_nav_item_active
              }`
            }>
            <div className={styles.wrapper_menu_nav_item_icon}>
              <ICONS.nav.Banners />
            </div>
            <p className={styles.wrapper_menu_nav_item_text}>Banners</p>
          </NavLink>
          {user?.role === AdminRole.Admin && (
            <NavLink
              to="/staff"
              className={({ isActive }) =>
                `${styles.wrapper_menu_nav_item} ${
                  isActive && styles.wrapper_menu_nav_item_active
                }`
              }>
              <div className={styles.wrapper_menu_nav_item_icon}>
                <ICONS.nav.Staff />
              </div>
              <p className={styles.wrapper_menu_nav_item_text}>
                Staff Management
              </p>
            </NavLink>
          )}
          <NavLink
            to="/settings"
            className={({ isActive }) =>
              `${styles.wrapper_menu_nav_item} ${
                isActive && styles.wrapper_menu_nav_item_active
              }`
            }>
            <div className={styles.wrapper_menu_nav_item_icon}>
              <ICONS.nav.Settings />
            </div>
            <p className={styles.wrapper_menu_nav_item_text}>Settings</p>
          </NavLink>
        </nav>

        <ProfileBlock />
      </div>

      <div className={styles.wrapper_content}>
        <Outlet />
      </div>

      {audio && (
        <div className={styles.audio_wrapper}>
          <AudioPlayer />
        </div>
      )}
    </div>
  );
};

export default Main;
