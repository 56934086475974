import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { TokenService } from "utils";

import { fetchCurrentAdmin, setUser } from "store/slices/app";

import { RootState } from "store";
import { IAdmin } from "interfaces";

const useUser = () => {
  const dispatch = useDispatch<any>();
  const { fetchCurrentAdminStatus, user } = useSelector(
    (store: RootState) => store.app
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (fetchCurrentAdminStatus === "idle") {
      dispatch(fetchCurrentAdmin());
    }
  }, []);

  useEffect(() => {
    if (fetchCurrentAdminStatus === "rejected") {
      TokenService.clearTokens();
      navigate("/login");
    }
  }, [fetchCurrentAdminStatus]);

  const updateUser = (user: IAdmin | null) => {
    dispatch(setUser(user));
  };

  return {
    user,
    loading:
      fetchCurrentAdminStatus === "pending" ||
      fetchCurrentAdminStatus === "idle",
    updateUser
  };
};

export default useUser;
