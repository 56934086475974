import { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import styles from "../styles.module.scss";

import { AdminAPI } from "api";

import { Input, Button } from "components";

import { setUser, toggleLoading } from "store/slices/app";

import { RootState } from "store";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required(
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .min(
      8,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .max(
      30,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .matches(
      /[a-z]/,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .matches(
      /[A-Z]/,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .matches(
      /\d/,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    )
    .matches(
      /[~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]/,
      "Use 8 or more characters with a mix of lowercase letters, uppercase letters, numbers & symbols (at least one of each)"
    ),
  email: Yup.string()
    .required(`Required`)
    .max(80, "Max size 80")
    .matches(/([a-z0-9._]{3,80})@([a-z]{3,80})\.[a-z]{2,3}/, `Incorrect email`)
});

const ChangeEmail: FC = () => {
  const dispatch = useDispatch<any>();
  const { user } = useSelector((store: RootState) => store.app);

  const {
    values,
    errors,
    isValid,
    touched,
    setFieldTouched,
    handleSubmit,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      email: user?.email || "",
      password: ""
    },
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: handleChangeEmail
  });

  async function handleChangeEmail() {
    try {
      dispatch(toggleLoading(true));
      await AdminAPI.changeEmail(values.email, values.password);
      toast("Email updated successfully", {
        type: "success"
      });

      if (user) {
        dispatch(setUser({ ...user, email: values.email }));
      }

      resetForm();
    } catch (e: any) {
      if (e?.response?.data?.message) {
        toast(e.response.data.message, {
          type: "error"
        });
      }
      console.log(e);
    } finally {
      dispatch(toggleLoading(false));
    }
  }

  return (
    <div className={styles.wrapper_group}>
      <h3 className={styles.wrapper_group_title}>Email</h3>
      <div className={styles.wrapper_group_field}>
        <Input
          label="Email"
          value={values.email}
          error={touched.email && errors.email ? errors.email : undefined}
          onChange={(val) => {
            if (val.length > 30) return;

            setFieldTouched("email");
            setFieldValue("email", val);
          }}
        />
      </div>
      <div className={styles.wrapper_group_field}>
        <Input
          value={values.password}
          label="Password"
          isSucure
          error={
            touched.password && errors.password ? errors.password : undefined
          }
          onChange={(val) => {
            if (val.length > 30) return;

            setFieldTouched("password");
            setFieldValue("password", val);
          }}
        />
      </div>

      <div className={styles.wrapper_group_buttons}>
        <div className={styles.wrapper_group_buttons_item}>
          <Button
            title="Save Email"
            disable={!isValid}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default ChangeEmail;
